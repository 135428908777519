var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Row, Col, message, Radio, InputNumber, TreeSelect } from 'antd';
import { get, postJson } from '../../../../utils/axios';
import { inject, observer } from "mobx-react";
import { MENU } from '../../../../api/api';
import '../index.scss';
import UploadImg from '../../../../components/uploadFile/UploadImg';
var inputWidth = 350;
var initialScreenMenuOption = [
    {
        "menuId": "root",
        "menuName": "根菜单",
        children: []
    }
];
var menuOptionDefault = {
    OFFICIAL_WEBSITE: initialScreenMenuOption,
    SCREEN: initialScreenMenuOption,
    ADMIN_WEBSITE: initialScreenMenuOption
};
var EditModal = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, activeKey = props.activeKey, getData = props.getData, menuId = props.menuId, staticStore = props.staticStore;
    var menuTypes = staticStore.menuTypes;
    var isEdit = modalType === 'edit';
    var _a = useState(''), title = _a[0], setTitle = _a[1];
    var _b = useState(menuOptionDefault[activeKey]), menuOption = _b[0], setMenuOption = _b[1];
    var _c = useState(activeKey === 'SCREEN'), isDataScreen = _c[0], setIsDataScreen = _c[1];
    var _d = useState({}), curMenuType = _d[0], setCurMenuType = _d[1];
    var _e = useState({
        menuName: '',
        menuType: '',
        parentMenuId: 'root' || [],
        funUri: '',
        sort: -1,
        menuId: '',
        icon: '',
    }), formObj = _e[0], setFormObj = _e[1];
    var form = Form.useForm()[0];
    useEffect(function () {
        // if(activeKey === 'OFFICIAL_WEBSITE' || activeKey === 'ADMIN_WEBSITE'){
        // } 
        if (activeKey === 'SCREEN') {
            var newMenuTypes = Object.assign({}, menuTypes);
            // delete newMenuTypes.MENU
            delete newMenuTypes.OPERATION;
            setCurMenuType(newMenuTypes);
        }
        else {
            setCurMenuType(menuTypes);
        }
    }, [activeKey]);
    var handleOk = function () {
        form.validateFields().then(function () {
            var params = __assign(__assign({}, formObj), { module: activeKey, parentMenuId: formObj.parentMenuId === 'root' ? '' : formObj.parentMenuId });
            // if(activeKey === 'OFFICIAL_WEBSITE'){
            //   params.parentMenuId = params.parentMenuId === 'root' ? '' : params.parentMenuId
            // }else if(activeKey === 'SCREEN'){
            //   params.parentMenuId = 'root' ? '' : params.parentMenuId
            // }
            // console.log('Params',params)
            postJson(MENU.saveMenu, params).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    return;
                }
                message.success('保存成功');
                setTimeout(function () {
                    setIsShowModal(false);
                    getData();
                }, 1000);
            });
        })
            .catch(function (info) {
            console.log('表单校验失败', info);
            return;
        });
    };
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var onRadioChange = function (e) {
        if (e.target.value === 'CATALOGUE') {
            setMenuOption(initialScreenMenuOption);
            setFormObj(__assign(__assign({}, formObj), { parentMenuId: 'root', menuType: e.target.value }));
            form.setFieldsValue({
                parentMenuId: 'root'
            });
        }
        else {
            getMenuList(e.target.value === 'MENU' ? 'CATALOGUE' : e.target.value === 'OPERATION' ? 'MENU' : '');
            setFormObj(__assign(__assign({}, formObj), { menuType: e.target.value, parentMenuId: undefined }));
            form.setFieldsValue({
                parentMenuId: undefined
            });
        }
    };
    var handleMenuChange = function (value) {
        setFormObj(__assign(__assign({}, formObj), { parentMenuId: value }));
        // 修改原数据操作
    };
    var getMenuList = function (menuType) { return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        current: 1,
                        pageSize: 100,
                        module: activeKey,
                        menuType: menuType,
                        status: 1
                    };
                    return [4 /*yield*/, get(MENU.listRecord, params).then(function (res) {
                            var _a;
                            if (res.state !== 200) {
                                message.error(res.msg);
                                return;
                            }
                            var list = (_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.list;
                            setMenuOption(list);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getDetail = function () {
        get(MENU.selectByMenuId, { menuId: menuId }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, menuName, _c, menuType, _d, parentMenuId, _e, funUri, _f, sort, menuId, _g, icon;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _a = (res === null || res === void 0 ? void 0 : res.results) || {}, _b = _a.menuName, menuName = _b === void 0 ? '' : _b, _c = _a.menuType, menuType = _c === void 0 ? '' : _c, _d = _a.parentMenuId, parentMenuId = _d === void 0 ? undefined || 'root' : _d, _e = _a.funUri, funUri = _e === void 0 ? '' : _e, _f = _a.sort, sort = _f === void 0 ? -1 : _f, menuId = _a.menuId, _g = _a.icon, icon = _g === void 0 ? '' : _g;
                        if (!(menuType === 'CATALOGUE')) return [3 /*break*/, 1];
                        parentMenuId = parentMenuId || 'root',
                            setMenuOption(initialScreenMenuOption);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, getMenuList(menuType === 'MENU' ? 'CATALOGUE' : menuType === 'OPERATION' ? 'MENU' : '')];
                    case 2:
                        _h.sent();
                        _h.label = 3;
                    case 3:
                        console.log('parentMenuId', parentMenuId);
                        setFormObj({
                            menuName: menuName,
                            menuType: menuType,
                            // menuType:menuTypes[menuType].key,
                            parentMenuId: parentMenuId,
                            funUri: funUri,
                            sort: sort,
                            menuId: menuId,
                            icon: icon,
                        });
                        form.setFieldsValue({
                            menuName: menuName,
                            menuType: menuType,
                            // menuType:menuTypes[menuType].key,
                            parentMenuId: parentMenuId,
                            funUri: funUri,
                            sort: sort,
                            icon: icon
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    var getUploadUrl = function (url) {
        setFormObj(function (pre) { return (__assign(__assign({}, pre), { icon: url })); });
    };
    // useEffect(() => {
    //   console.log('formObj', formObj)
    // }, [formObj])
    useEffect(function () {
        (modalType === 'edit' || modalType === 'check') && getDetail();
        if (modalType === 'add' && activeKey === 'OFFICIAL_WEBSITE') {
            form.setFieldsValue({
                menuType: 'CATALOGUE',
                parentMenuId: 'root' || [],
            });
            setFormObj(__assign(__assign({}, formObj), { menuType: 'CATALOGUE', parentMenuId: 'root' || [] }));
        }
        else if (modalType === 'add') {
            form.setFieldsValue({
                menuType: 'CATALOGUE',
                parentMenuId: 'root' || [],
            });
            setFormObj(__assign(__assign({}, formObj), { menuType: 'CATALOGUE', parentMenuId: 'root' || [] }));
        }
    }, [modalType, activeKey]);
    useEffect(function () {
        switch (modalType) {
            case 'add':
                setTitle('新建菜单');
                break;
            case 'edit':
                setTitle('编辑菜单');
                break;
            case 'check':
                setTitle('查看菜单');
                break;
        }
    }, [modalType]);
    useEffect(function () {
        setIsDataScreen(activeKey === 'SCREEN');
    }, [activeKey]);
    return (React.createElement(Modal, { title: title, open: true, onOk: handleOk, maskClosable: false, onCancel: handleCancel, width: 750, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form },
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u83DC\u5355\u540D\u79F0\uFF1A", name: "menuName", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Input, { maxLength: 50, value: formObj.menuName, onChange: function (e) { setFormObj(__assign(__assign({}, formObj), { menuName: (e.target.value || '').trim() })); }, style: { width: inputWidth, marginRight: '20px' }, placeholder: '\u8BF7\u8F93\u5165' })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u83DC\u5355\u7C7B\u578B\uFF1A", name: "menuType", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Radio.Group, { onChange: onRadioChange, value: formObj.menuType, defaultValue: formObj.menuType, disabled: isEdit }, Object.keys(curMenuType).map(function (item) { var _a; return React.createElement(Radio, { value: item, key: item }, (_a = curMenuType[item]) === null || _a === void 0 ? void 0 : _a.label); }))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u4E0A\u7EA7\u83DC\u5355\uFF1A", name: "parentMenuId", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(TreeSelect, { style: { width: inputWidth }, value: formObj.parentMenuId, dropdownStyle: { maxHeight: 400, overflow: 'auto' }, placeholder: "\u8BF7\u9009\u62E9", allowClear: true, treeDefaultExpandAll: true, onChange: handleMenuChange, treeData: menuOption, disabled: formObj.menuType === 'CATALOGUE', fieldNames: { label: 'menuName', value: 'menuId', children: 'children' } })))),
            formObj.menuType === 'OPERATION' ?
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u64CD\u4F5C\u6743\u9650\u7801\uFF1A", name: "funUri", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                            React.createElement(Input, { maxLength: 50, value: formObj.funUri, onChange: function (e) { setFormObj(__assign(__assign({}, formObj), { funUri: e.target.value })); }, style: { width: inputWidth }, placeholder: '\u8BF7\u8F93\u5165' }))))
                : React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: '\u83DC\u5355URL\uFF1A', name: "funUri", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                            React.createElement(Input, { value: formObj.funUri, onChange: function (e) { setFormObj(__assign(__assign({}, formObj), { funUri: (e.target.value || '').trim() })); }, style: { width: inputWidth }, placeholder: '\u8BF7\u8F93\u5165' })))),
            (formObj.menuType === 'CATALOGUE' || formObj.menuType === 'MENU') && React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u6392\u5E8F\uFF1A", name: "sort", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                            React.createElement(InputNumber, { value: formObj.sort, min: 1, max: 10000, onChange: function (v) { setFormObj(__assign(__assign({}, formObj), { sort: v })); } })))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "icon\uFF1A", name: "icon" },
                            React.createElement(UploadImg, { sendUrl: function (url) { return getUploadUrl(url); }, url: formObj.icon }))))))));
}));
export default EditModal;
