var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import "../index.scss";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import UploadImg from "../../../../components/uploadFile/UploadImg";
import { get, postJson } from "../../../../utils/axios";
import { DATA_MANAGER, DATA_MENU_MANAGER } from "../../../../api/api";
import Illustrate from "./Illustrate";
import DataDemo from "./DataDemo";
import DataType from "./DataType";
import DataChooseModal from "./DataChooseModal";
import { Button, Col, Divider, Form, Input, Modal, Row, Switch, Tabs, TreeSelect, message, } from "antd";
import _ from "lodash";
var initialState = {
    bigDataName: "",
    dataName: "",
    catalogueId: "",
    top: "",
    intro: "",
    coverPicture: "",
};
var tabData = [
    {
        label: "数据说明",
        key: "illustrate",
    },
    {
        label: "数据项",
        key: "dataType",
    },
    {
        label: "示例数据",
        key: "dataDemo",
    },
];
var AddDataProductSub = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var colLayout = staticStore.colLayout, inputWidth = staticStore.inputWidth, maxLength = staticStore.maxLength;
    var routes = location.hash.split("/") || [];
    var isAdd = location.hash.indexOf("add") > -1;
    var isEdit = location.hash.indexOf("edit") > -1;
    var isCheck = location.hash.indexOf("check") > -1;
    var id = isAdd ? "" : routes[routes.length - 1];
    var _a = useState(initialState), formObj = _a[0], setFormObj = _a[1];
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var _c = useState(false), isShowChooseModal = _c[0], setIsShowChooseModal = _c[1];
    var _d = useState("illustrate"), dataPreActiveKey = _d[0], setDataPreActiveKey = _d[1];
    var _e = useState(""), choosedId = _e[0], setChoosedId = _e[1];
    var _f = useState({
        bigDataId: "",
        bigDataTypes: "",
    }), bigData = _f[0], setBigData = _f[1];
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var doSubmit = _.debounce(function () {
        form.validateFields().then(function (res) {
            var params = __assign(__assign(__assign(__assign({}, formObj), { top: formObj.top ? 1 : 0 }), bigData), { id: isAdd ? undefined : id });
            var url = isAdd ? DATA_MANAGER.save : DATA_MANAGER.update;
            postJson(url, params).then(function (res) {
                if (res.state === 200) {
                    message.success("\u4FDD\u5B58\u6210\u529F");
                    setTimeout(function () {
                        navigate(-1);
                    }, 1000);
                }
                else {
                    message.error("\u4FDD\u5B58\u5931\u8D25");
                }
            });
        });
    }, 1000);
    var goBack = function () {
        if (JSON.stringify(initialState) === JSON.stringify(formObj)) {
            navigate(-1);
            return;
        }
        Modal.confirm({
            title: "确认放弃当前操作并返回列表页？",
            onOk: function () {
                navigate(-1);
            },
        });
    };
    var onModalClose = function (item) {
        setIsShowChooseModal(false);
        if (item && item.id) {
            form.setFieldValue("bigDataName", item.dataName);
            form.setFieldValue("dataName", item.dataName);
            setBigData({
                bigDataId: item.id,
                bigDataTypes: item.dataType,
            });
            setFormObj(__assign(__assign({}, formObj), { bigDataName: item.dataName, dataName: item.dataName }));
            setChoosedId(item.id);
            setDataPreActiveKey('illustrate');
        }
    };
    var getOptions = function () {
        var params = {
            status: "",
        };
        get(DATA_MENU_MANAGER.getEnableTreeNode, params).then(function (res) {
            if (res) {
                var results = res === null || res === void 0 ? void 0 : res.results;
                // let opt = [
                //   {
                //     catalogueName: "全部",
                //     childNodeList: results,
                //     catalogueId: 0,
                //   },
                // ];
                setOptions(results);
            }
        });
    };
    var getDetail = function () {
        get("".concat(DATA_MANAGER.getById, "/").concat(id)).then(function (res) {
            var _a = res === null || res === void 0 ? void 0 : res.results, bigDataId = _a.bigDataId, bigDataTypes = _a.bigDataTypes, bigDataName = _a.bigDataName, dataName = _a.dataName, top = _a.top, intro = _a.intro, coverPicture = _a.coverPicture, createTime = _a.createTime, updateTime = _a.updateTime, operatorName = _a.operatorName, catalogueId = _a.catalogueId, catalogueName = _a.catalogueName;
            setBigData({
                bigDataId: bigDataId,
                bigDataTypes: bigDataTypes,
            });
            setFormObj({
                bigDataName: bigDataName,
                dataName: dataName,
                top: top ? 1 : 0,
                intro: intro,
                coverPicture: coverPicture,
                createTime: createTime,
                updateTime: updateTime,
                operatorName: operatorName,
                catalogueId: catalogueId,
            });
            form.setFieldsValue({
                bigDataName: bigDataName,
                dataName: dataName,
                top: top ? 1 : 0,
                intro: intro,
                coverPicture: coverPicture,
                createTime: createTime,
                updateTime: updateTime,
                operatorName: operatorName,
                catalogueId: catalogueId,
            });
            setChoosedId(bigDataId);
        });
    };
    useEffect(function () {
        getOptions();
    }, []);
    useEffect(function () {
        if (id && (isEdit || isCheck)) {
            getDetail();
        }
    }, [id]);
    return (React.createElement("div", { className: "add_data_product_sub" },
        React.createElement("div", { className: "add_data_product_sub_part1" },
            React.createElement("div", { className: "add_data_product_title" }, "\u57FA\u7840\u4FE1\u606F"),
            React.createElement(Divider, { className: "add_data_product_divider" }),
            React.createElement("div", { className: "add_data_product_sub_part1_form" },
                React.createElement(Form, { name: "basic", initialValues: { remember: true }, autoComplete: "off", layout: "vertical", validateTrigger: "onBlur", form: form },
                    React.createElement(Row, null,
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u9009\u62E9\u6570\u636E\uFF1A", name: "bigDataName", rules: [
                                    {
                                        required: true,
                                        message: "请选择",
                                        validateTrigger: "onChange",
                                    },
                                ] },
                                React.createElement(Input, { maxLength: maxLength, placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, value: formObj.bigDataName, disabled: isCheck, readOnly: true, onFocus: function () { return setIsShowChooseModal(true); }, onChange: function (e) {
                                        var _a;
                                        return setFormObj(__assign(__assign({}, formObj), { bigDataName: (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.trim() }));
                                    } }))),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u6570\u636E\u540D\u79F0\uFF1A", name: "dataName", rules: [{ required: true, message: "请输入" }] },
                                React.createElement(Input, { maxLength: maxLength, placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, value: formObj.dataName, disabled: isCheck, onChange: function (e) {
                                        var _a;
                                        return setFormObj(__assign(__assign({}, formObj), { dataName: (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.trim() }));
                                    } }))),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u76EE\u5F55\uFF1A", name: "catalogueId", rules: [{ required: true, message: "请选择" }] },
                                React.createElement(TreeSelect, { treeLine: true, style: { width: inputWidth }, treeData: options, disabled: isCheck, 
                                    // showSearch
                                    placeholder: "\u8BF7\u9009\u62E9", fieldNames: {
                                        label: "catalogueName",
                                        value: "catalogueId",
                                        children: "childNodeList",
                                    }, value: formObj.catalogueId, onChange: function (v) {
                                        setFormObj(function (pre) { return (__assign(__assign({}, pre), { catalogueId: v })); });
                                    } })))),
                    React.createElement(Row, null,
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u7F6E\u9876\uFF1A", name: "top" },
                                React.createElement(Switch, { checked: formObj.top, checkedChildren: "\u5F00", unCheckedChildren: "\u5173", disabled: isCheck, onChange: function (checked) {
                                        return setFormObj(__assign(__assign({}, formObj), { top: checked }));
                                    } }))),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u6570\u636E\u4ECB\u7ECD\uFF1A", name: "intro" },
                                React.createElement(TextArea, { rows: 4, maxLength: maxLength, placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, value: formObj.intro, disabled: isCheck, onChange: function (e) {
                                        var _a;
                                        return setFormObj(__assign(__assign({}, formObj), { intro: (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.trim() }));
                                    } }))),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u5C01\u9762\u56FE\uFF1A", name: "coverPicture" },
                                !isCheck && (React.createElement(UploadImg, { sendUrl: function (url) {
                                        return setFormObj(__assign(__assign({}, formObj), { coverPicture: url }));
                                    }, url: formObj.coverPicture })),
                                isCheck && formObj.coverPicture && (React.createElement("img", { src: formObj.coverPicture, className: "form_check-image" }))))),
                    !isAdd && (React.createElement(Row, null,
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4", name: "createTime" }, formObj.createTime)),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4", name: "updateTime" }, formObj.updateTime)),
                        React.createElement(Col, __assign({}, colLayout),
                            React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA", name: "operatorName" }, formObj.operatorName))))))),
        React.createElement("div", { className: "add_data_product_sub_part2" },
            React.createElement("div", { className: "add_data_product_title" }, "\u57FA\u7840\u4FE1\u606F"),
            React.createElement(Divider, { className: "add_data_product_divider" }),
            React.createElement("div", { className: "add_data_product_sub_part2_tabs" },
                React.createElement(Tabs, { activeKey: dataPreActiveKey, items: tabData, onChange: function (key) { return setDataPreActiveKey(key); } }),
                dataPreActiveKey === "illustrate" && React.createElement(Illustrate, { id: choosedId }),
                dataPreActiveKey === "dataType" && React.createElement(DataType, { id: choosedId }),
                dataPreActiveKey === "dataDemo" && React.createElement(DataDemo, { id: choosedId }))),
        React.createElement("div", { className: "add_data_product_sub_part3" },
            (isAdd || isEdit) && (React.createElement(Row, { justify: "end" },
                React.createElement(Col, { span: 2 },
                    React.createElement("div", { className: "btn" },
                        React.createElement(Button, { onClick: goBack, className: "mr20" }, "\u53D6\u6D88"),
                        React.createElement(Button, { onClick: doSubmit, type: "primary", htmlType: "submit" }, "\u63D0\u4EA4"))))),
            isCheck && (React.createElement(Row, { justify: "end" },
                React.createElement(Col, { span: 2 },
                    React.createElement("div", { className: "btn" },
                        React.createElement(Button, { onClick: function () { return navigate(-1); } }, "\u8FD4\u56DE")))))),
        isShowChooseModal && (React.createElement(DataChooseModal, { onClose: onModalClose, options: options }))));
}));
export default AddDataProductSub;
