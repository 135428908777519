var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postJson } from "../../../utils/axios";
import { FIRST } from "../../../api/api";
import UploadImg from "../../../components/uploadFile/UploadImg";
var IntroConfig = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var routes = location.hash.split("/") || [];
    var orgId = routes[routes.length - 1];
    var _a = useState({
        image: "",
    }), detailData = _a[0], setDetailData = _a[1];
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var navigateBack = function () {
        navigate('/datav/first');
    };
    var onSubmit = function () {
        form.validateFields().then(function (values) {
            console.log("values", values);
            var params = __assign(__assign({}, values), { orgId: orgId });
            postJson(FIRST.save, params).then(function (res) {
                if (res) {
                    message.success("保存成功");
                    navigateBack();
                }
            });
        });
    };
    var getDetails = function () {
        var params = {
            orgId: orgId,
        };
        get(FIRST.getDetailByOrgId, params).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                setDetailData(results);
                form.setFieldsValue(results);
            }
        });
    };
    var getUploadUrl = function (url) {
        setDetailData(function (pre) { return (__assign(__assign({}, pre), { image: url })); });
        form.setFieldValue("image", url);
    };
    useEffect(function () {
        getDetails();
    }, [orgId]);
    return (React.createElement("div", { className: "addOrEditPage" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 2 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Form.Item, { label: "\u6A21\u5757\u540D\u79F0\uFF1A", name: "name", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(Input, { style: { width: inputWidth }, maxLength: 4, placeholder: "\u8BF7\u8F93\u5165" })),
                React.createElement(Form.Item, { label: "\u7B80\u4ECB\uFF1A", name: "data", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(Input.TextArea, { style: { width: inputWidth }, 
                        // maxLength={5000}
                        rows: 4, placeholder: "\u8BF7\u8F93\u5165" })),
                React.createElement(Form.Item, { label: "\u56FE\u7247\uFF1A", name: "image", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(UploadImg, { sendUrl: function (url) { return getUploadUrl(url); }, url: detailData.image })))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: function () { return navigateBack(); } }, "\u53D6\u6D88"),
            React.createElement(Button, { type: "primary", onClick: function () { return onSubmit(); } }, "\u786E\u5B9A"))));
}));
export default IntroConfig;
