var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Input, Table, Pagination } from "antd";
import "./index.scss";
import { get } from "../../../utils/axios";
import { MESSAGE } from "../../../api/api";
var columns = [
    {
        title: "手机号",
        dataIndex: "account",
        ellipsis: true,
        key: "account",
    },
    {
        title: "发送时间",
        dataIndex: "sendTime",
        ellipsis: true,
        key: "sendTime",
    },
    {
        title: "短信内容",
        dataIndex: "message",
        ellipsis: true,
        width: 500,
        key: "message",
    },
];
export default function MessageList() {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(10), total = _b[0], setTotal = _b[1];
    var _c = useState(10), pageSize = _c[0], setPageSize = _c[1];
    var _d = useState(1), current = _d[0], setCurrent = _d[1];
    var _e = useState({}), searchParams = _e[0], setSearchParams = _e[1];
    var onChange = function (pageNumber) {
        setCurrent(pageNumber);
    };
    var onShowSizeChange = function (current, size) {
        setPageSize(size);
        setCurrent(current);
    };
    var onPressEnter = function (e) {
        var element = e.target;
        var value = element.value && element.value.replace(" ", "");
        setSearchParams(__assign(__assign({}, searchParams), { account: value.trim() }));
    };
    var onSearchChange = function (e) {
        if (e.target.value === "") {
            setSearchParams(__assign(__assign({}, searchParams), { search: "" }));
        }
    };
    var getData = function () {
        var params = __assign(__assign({}, searchParams), { pageSize: pageSize, current: current });
        get(MESSAGE.list, params).then(function (res) {
            var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination = _a.pagination;
            var current = pagination.current, pageSize = pagination.pageSize, total = pagination.total;
            setPageSize(pageSize);
            setCurrent(current);
            setTotal(total);
            setData(list);
        });
    };
    useEffect(function () {
        getData();
    }, [current, pageSize, searchParams]);
    // useEffect(()=>{
    //   console.log('111')
    // })
    return (React.createElement("div", { className: "message-manager" },
        React.createElement("div", { className: "message-cont" },
            React.createElement("div", { className: "search-btn" },
                React.createElement("div", { className: "btn" },
                    React.createElement("div", { className: "search" },
                        React.createElement("div", null, "\u624B\u673A\u53F7\uFF1A"),
                        React.createElement(Input, { size: "small", placeholder: "\u6309\u56DE\u8F66\u641C\u7D22", onPressEnter: onPressEnter, onChange: function (e) {
                                onSearchChange(e);
                            }, allowClear: true })))),
            React.createElement("div", { className: "cont-table mt20" },
                React.createElement(Table, { columns: columns, dataSource: data, pagination: false, rowKey: "id", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
                React.createElement("div", { className: "cont-pagination mt20" },
                    React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange }))))));
}
