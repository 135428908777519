var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Button, DatePicker, Select, Divider, Radio, Modal, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { validatorTel, validatorEmail } from '../../../utils/lib';
import { get, postForm, postJson } from '../../../utils/axios';
import { COMMON, ROLE, USER } from '../../../api/api';
import './index.scss';
import { inject, observer } from 'mobx-react';
var md5 = require('md5');
var RangePicker = DatePicker.RangePicker;
var TextArea = Input.TextArea;
var colLayout = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 12 },
    xxl: { span: 8 }
};
var inputWidth = 350;
var maxLength = 50;
var initialState = {
    phone: '',
    email: '',
    password: '',
    userName: '',
    intentionType: 'NONE',
    intentionDescription: '',
    source: 0,
    roleId: [],
    userId: '',
    status: 0,
    createName: '',
    createTime: '',
    updateName: '',
    updateTime: ''
};
// const userTypeList = [
//   { label: '官网散户', value: '1' },
//   { label: '驾驶舱用户', value: '2' },
// ]
var AddUser = inject('staticStore')(observer(function (props) {
    var staticStore = props.staticStore;
    var sourceList = staticStore.sourceList;
    var routes = location.hash.split('/') || [];
    var isAdd = location.hash.indexOf('add') > -1;
    var isEdit = location.hash.indexOf('edit') > -1;
    var isCheck = location.hash.indexOf('check') > -1;
    var id = isAdd ? '' : routes[routes.length - 1];
    var _a = useState(undefined), isRightPhone = _a[0], setIsRightPhone = _a[1];
    var _b = useState(undefined), isRightEmail = _b[0], setIsRightEmail = _b[1];
    var _c = useState([]), roleOption = _c[0], setRoleOption = _c[1];
    var _d = useState([]), userTypeList = _d[0], setUserTypeList = _d[1];
    var _e = useState(initialState), formObj = _e[0], setFormObj = _e[1];
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var checkEmail = function (e) {
        var isRight = validatorEmail(e.target.value);
        setIsRightEmail(isRight);
    };
    if (isAdd) {
        sourceList = sourceList.slice(0, 1);
    }
    console.log('sourceList', sourceList);
    var validatorPassWord = function (rule, value, callback) {
        if (isAdd) {
            if (value === undefined) {
                return Promise.reject('请检查输入是否正确！');
            }
            if (value.length > 30 || value.length < 8) {
                return Promise.reject('请输入8-30位长度的密码！');
            }
            try {
                // let mark = true
                var psdType = 0;
                // if(value.match(/[a-z]+/)) psdType++;
                // if(value.match(/[A-Z]+/)) psdType++;
                // if(value.match(/[0-9]+/)) psdType++;
                // if(/(?=.*[^a-z0-9A-Z])/.test(value)) psdType++;
                var mark = /\d/.test(value) && /[A-Z]/.test(value) && /[a-z]/.test(value) && !/\s/.test(value) && /(?=.*[^a-z0-9A-Z])/.test(value);
                // if(psdType < 4){
                //   mark = false
                // }
                if (value.length < 8 || value.length > 30) {
                    mark = false;
                }
                // let reg = /^[\da-zA-Z-=+_,.@]{8,30}$/
                // let res = reg.test(value)
                if (mark) {
                    return Promise.resolve();
                }
                else {
                    return Promise.reject('密码未满足要求，密码要包含：大写字母+小写字母+特殊符号+数字四种组合，请重新输入！');
                }
            }
            catch (err) {
                callback(err);
            }
        }
        else {
            return Promise.resolve();
        }
    };
    var validatorPhone = function (rule, value, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var isRight, result_1, params, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isAdd) return [3 /*break*/, 7];
                    isRight = validatorTel(value);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    if (!isRight) return [3 /*break*/, 3];
                    result_1 = false;
                    params = {
                        account: value
                    };
                    return [4 /*yield*/, postJson("".concat(USER.phone), params).then(function (res) {
                            var _a;
                            if (res.state !== 200) {
                                message.warning(res.msg);
                                setIsRightPhone(false);
                            }
                            if ((_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.expression) {
                                result_1 = true;
                                if (!formObj.userName) {
                                    form.setFieldsValue({
                                        userName: "\u7528\u6237".concat(value),
                                    });
                                    setFormObj(__assign(__assign({}, formObj), { userName: "\u7528\u6237".concat(value) }));
                                }
                                setIsRightPhone(true);
                            }
                            else {
                                result_1 = false;
                                setIsRightPhone(false);
                            }
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, result_1 ? Promise.resolve() : Promise.reject('该手机号已成功注册，请更换手机号！')];
                case 3:
                    setIsRightPhone(false);
                    return [2 /*return*/, Promise.reject('请检查输入是否正确！')];
                case 4: return [3 /*break*/, 6];
                case 5:
                    err_1 = _a.sent();
                    callback(err_1);
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7: return [2 /*return*/, Promise.resolve()];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var validatorFormEmail = function (rule, value, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var isRight, result_2, params, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isAdd) return [3 /*break*/, 7];
                    isRight = validatorEmail(value);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    if (!isRight) return [3 /*break*/, 3];
                    result_2 = false;
                    params = {
                        email: value
                    };
                    return [4 /*yield*/, postJson("".concat(USER.email), params).then(function (res) {
                            var _a, _b;
                            if (res.state !== 200) {
                                message.warning(res.msg);
                                setIsRightEmail(false);
                            }
                            result_2 = (_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.expression;
                            setIsRightEmail((_b = res === null || res === void 0 ? void 0 : res.results) === null || _b === void 0 ? void 0 : _b.expression);
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, result_2 ? Promise.resolve() : Promise.reject('该邮箱已成功注册，请更换邮箱！')];
                case 3:
                    setIsRightEmail(false);
                    return [2 /*return*/, Promise.reject('请检查输入是否正确！')];
                case 4: return [3 /*break*/, 6];
                case 5:
                    err_2 = _a.sent();
                    callback(err_2);
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7: return [2 /*return*/, Promise.resolve()];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var onFinish = function (values) {
        console.log('Success:', values);
        console.log('formObj:', formObj);
        var _a = formObj.roleId, roleId = _a === void 0 ? [] : _a;
        var roleInfos = roleId.map(function (item) {
            return {
                roleId: item,
                validityStart: '2022-12-12  01:00:00',
                validityEnd: '2222-12-12  01:00:00',
            };
        });
        // let roleInfos = 
        var params = __assign(__assign({}, formObj), { roleInfos: roleInfos, 
            // accountType:'1',
            // JSON.stringify(formObj.accountType),
            userId: id || '', validityStart: '2022-12-12  01:00:00', validityEnd: '2222-12-12  01:00:00', password: md5(formObj.password), status: isAdd ? 1 : formObj.status });
        delete params.roleId;
        isAdd ? null : delete params.password;
        console.log('params', params);
        postJson(USER.save, params).then(function (res) {
            if (res.state === 200) {
                message.success("\u4FDD\u5B58\u6210\u529F");
                setTimeout(function () {
                    navigate('/system/userManager');
                }, 1000);
            }
            else {
                message.error("\u4FDD\u5B58\u5931\u8D25");
            }
        });
    };
    var onFinishFailed = function (errorInfo) {
        console.log('Failed:', errorInfo);
        var values = errorInfo.values;
        var _a = values || {}, phone = _a.phone, email = _a.email;
        var isRightP = validatorTel(phone);
        setIsRightPhone(isRightP);
        var isRightE = validatorEmail(email);
        setIsRightEmail(isRightE);
    };
    var onChange = function (dates, dateStrings) {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        }
        else {
            console.log('Clear');
        }
    };
    var handleChange = function (value) {
        console.log("selected ".concat(value));
        setFormObj(function (pre) { return (__assign(__assign({}, pre), { roleId: value })); });
    };
    var goBack = function () {
        if (JSON.stringify(initialState) === JSON.stringify(formObj)) {
            navigate('/system/userManager');
            return;
        }
        Modal.confirm({
            title: '确认放弃当前操作并返回列表页？',
            onOk: function () {
                navigate('/system/userManager');
            }
        });
        // setA({...a,b:33})
    };
    var getRoles = function () {
        var params = {
            current: 1,
            pageSize: 100
        };
        get(ROLE.optionList, params).then(function (res) {
            var list = res.results.list;
            setRoleOption(list);
            if (isEdit || isCheck) {
                getDetail();
            }
            // (isEdit || isCheck) && getDetail()
        });
    };
    var getDetail = function () {
        postForm(USER.getUser, { userId: id }).then(function (res) {
            var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, _b = _a.phone, phone = _b === void 0 ? '' : _b, _c = _a.email, email = _c === void 0 ? '' : _c, _d = _a.password, password = _d === void 0 ? '' : _d, _e = _a.userName, userName = _e === void 0 ? '' : _e, _f = _a.intentionType, intentionType = _f === void 0 ? '' : _f, _g = _a.intentionDescription, intentionDescription = _g === void 0 ? '' : _g, _h = _a.accountType, accountType = _h === void 0 ? '' : _h, _j = _a.source, source = _j === void 0 ? 0 : _j, _k = _a.roleInfos, roleInfos = _k === void 0 ? [] : _k, _l = _a.accountNo, accountNo = _l === void 0 ? '' : _l, userId = _a.userId, status = _a.status, _m = _a.createName, createName = _m === void 0 ? '' : _m, _o = _a.createTime, createTime = _o === void 0 ? '' : _o, _p = _a.updateName, updateName = _p === void 0 ? '' : _p, _q = _a.updateTime, updateTime = _q === void 0 ? '' : _q;
            setFormObj({
                phone: phone || accountNo,
                email: email,
                password: password,
                userName: userName,
                intentionType: intentionType,
                intentionDescription: intentionDescription,
                accountType: accountType,
                source: source,
                roleId: roleInfos.map(function (item) { return item.roleId; }),
                userId: userId,
                status: status,
                createName: createName,
                createTime: createTime,
                updateName: updateName,
                updateTime: updateTime
            });
            form.setFieldsValue({
                phone: phone || accountNo,
                email: email,
                password: password,
                userName: userName,
                intentionType: intentionType,
                intentionDescription: intentionDescription,
                accountType: accountType,
                source: source,
                roleId: roleInfos.map(function (item) { return item.roleId; }),
                status: status
            });
        });
    };
    var getUserTypes = function () {
        get(COMMON.getList, { type: 1 }).then(function (res) {
            if (res) {
                setUserTypeList(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({
                    label: item.name,
                    value: item.key,
                }); }));
            }
        });
    };
    useEffect(function () {
        getRoles();
        getUserTypes();
    }, []);
    useEffect(function () {
        form.setFieldsValue({
            intentionType: 'NONE',
            roleId: [],
            userId: '',
        });
    }, []);
    return (React.createElement("div", { className: 'add-user' },
        React.createElement("h3", null, "\u57FA\u672C\u4FE1\u606F"),
        React.createElement(Form, { name: "basic", initialValues: { remember: true }, autoComplete: "off", layout: "vertical", onFinish: onFinish, onFinishFailed: onFinishFailed, validateTrigger: "onBlur", form: form },
            !isAdd && React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u7528\u6237ID", name: "userId" }, formObj.userId)),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u7528\u6237\u72B6\u6001", name: "status" },
                        React.createElement(Radio.Group, { value: formObj.status, defaultValue: formObj.status, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { status: e.target.value })); }, disabled: isCheck },
                            React.createElement(Radio, { value: 1 }, "\u542F\u7528"),
                            React.createElement(Radio, { value: 0 }, "\u505C\u7528"))))),
            React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u624B\u673A\u53F7", name: "phone", validateStatus: isRightPhone === undefined ? null : isRightPhone ? 'success' : 'error', 
                        // rules={[{ required: true, message: '请检查输入是否正确！', pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-9])\d{8}$/ }]}
                        rules: [{ required: true, validator: validatorPhone }] },
                        React.createElement(Input, { suffix: isRightPhone === undefined ? React.createElement("span", null) : isRightPhone ? React.createElement(CheckCircleOutlined, { style: { color: 'green' } }) : React.createElement(CloseCircleOutlined, { style: { color: 'red' } }), maxLength: 11, style: { width: inputWidth }, 
                            // onBlur={(e: any) => checkPhone(e)}
                            value: formObj.phone, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { phone: e.target.value })); }, placeholder: "\u8BF7\u8F93\u5165", autoComplete: "off", disabled: isCheck || isEdit }))),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u90AE\u7BB1", name: "email", validateStatus: isRightEmail === undefined ? null : isRightEmail ? 'success' : 'error', 
                        // rules={[{ required: true, message: '请检查输入是否正确！', pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ }]}
                        rules: [{ required: true, validator: validatorFormEmail }] },
                        React.createElement(Input, { suffix: isRightEmail === undefined ? React.createElement("span", null) : isRightEmail ? React.createElement(CheckCircleOutlined, { style: { color: 'green' } }) : React.createElement(CloseCircleOutlined, { style: { color: 'red' } }), maxLength: maxLength, placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, value: formObj.email, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { email: e.target.value })); }, onBlur: function (e) { return checkEmail(e); }, disabled: isCheck }))),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u5BC6\u7801", name: "password", rules: [{ required: true, validator: validatorPassWord }] },
                        React.createElement(Input.Password, { style: { width: inputWidth }, disabled: isCheck || isEdit, value: formObj.password, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { password: e.target.value })); }, autoComplete: "new-password", placeholder: "\u8BF7\u8F93\u5165" })))),
            React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u7528\u6237\u540D", name: "userName", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Input, { maxLength: maxLength, style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", disabled: isCheck, value: formObj.userName, autoComplete: "off", onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { userName: e.target.value })); } }))),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u89D2\u8272", name: "roleId", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Select, { mode: "multiple", allowClear: true, style: { width: inputWidth }, placeholder: "\u8BF7\u9009\u62E9", onChange: handleChange, options: roleOption, disabled: isCheck, value: formObj.roleId, 
                            // value={formObj.roleId}
                            fieldNames: { label: 'roleName', value: 'roleId' } })))),
            React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u610F\u5411", name: "intentionType", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Radio.Group, { value: formObj.intentionType, defaultValue: formObj.intentionType, disabled: isCheck, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { intentionType: e.target.value })); } },
                            React.createElement(Radio, { value: 'NONE' }, "\u6682\u65E0"),
                            React.createElement(Radio, { value: 'YES' }, "\u6709\u610F\u5411")))),
                formObj.intentionType === 'YES' && React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u610F\u5411\u63CF\u8FF0", name: "intentionDescription", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(TextArea, { rows: 4, placeholder: "\u8BF7\u7B80\u5355\u63CF\u8FF0\u4F60\u7684\u9700\u6C42", maxLength: 1000, style: { width: inputWidth }, disabled: isCheck, value: formObj.intentionDescription, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { intentionDescription: e.target.value })); } })))),
            !isAdd && React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4", name: "createTime" }, formObj.createTime)),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4", name: "updateTime" }, formObj.updateTime)),
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA", name: "updateName" }, formObj.updateName || formObj.createName))),
            React.createElement(Divider, null),
            React.createElement("h3", null, "\u7EC4\u7EC7\u4FE1\u606F"),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u7528\u6237\u7C7B\u578B", name: "accountType", rules: [{ required: true, message: '请选择' }] },
                        React.createElement(Radio.Group, { options: userTypeList, onChange: function (e) { return setFormObj(__assign(__assign({}, formObj), { accountType: e.target.value })); }, disabled: isCheck })))),
            React.createElement(Row, null,
                React.createElement(Col, __assign({}, colLayout),
                    React.createElement(Form.Item, { label: "\u6765\u6E90", name: "source", rules: [{ required: true, message: '请检查输入是否正确！' }] },
                        React.createElement(Select, { allowClear: true, style: { width: inputWidth }, placeholder: "\u8BF7\u9009\u62E9", value: formObj.source, onChange: function (v) { return setFormObj(__assign(__assign({}, formObj), { source: v })); }, options: sourceList, disabled: isCheck || isEdit })))),
            (isAdd || isEdit) && React.createElement(Row, null,
                React.createElement(Col, { span: 4 },
                    React.createElement("div", { className: 'btn' },
                        React.createElement(Button, { type: "primary", htmlType: "submit", className: 'mr20' }, "\u63D0\u4EA4"),
                        React.createElement(Button, { onClick: goBack }, "\u53D6\u6D88")))),
            isCheck && React.createElement(Row, null,
                React.createElement(Col, { span: 4 },
                    React.createElement("div", { className: 'btn' },
                        React.createElement(Button, { onClick: function () { return navigate('/system/userManager'); } }, "\u8FD4\u56DE")))))));
}));
export default AddUser;
