import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import './index.scss';
var breadcrumb = function (props) {
    var menus = props.menus;
    var _a = useState([]), labelPath = _a[0], setLabelPath = _a[1];
    var _b = useState([]), path = _b[0], setPath = _b[1];
    var keyPathHash = location.hash || '';
    // const configMenu = 
    // let menus1:any = [...adminMenus,...configMenu ].slice()
    // let menus1 = menus
    // console.log('menus1',menus1)
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var labelPath1 = [];
        var path1 = [];
        var keyPath = (_a = keyPathHash === null || keyPathHash === void 0 ? void 0 : keyPathHash.split('/')) === null || _a === void 0 ? void 0 : _a.slice(1);
        for (var index in menus) {
            if (menus[index].key === keyPath[0]) {
                labelPath1.push(menus[index].label);
                path1.push(menus[index].key);
                var children1 = menus[index].children || [];
                // if(!children1) return
                for (var index2 in children1) {
                    if (((_b = children1[index2]) === null || _b === void 0 ? void 0 : _b.key) === keyPath[1]) {
                        labelPath1.push((_c = children1[index2]) === null || _c === void 0 ? void 0 : _c.label);
                        path1.push((_d = children1[index2]) === null || _d === void 0 ? void 0 : _d.key);
                        var children2 = ((_e = children1[index2]) === null || _e === void 0 ? void 0 : _e.children) || [];
                        // if(!children2) return
                        for (var index3 in children2) {
                            if (((_f = children2[index3]) === null || _f === void 0 ? void 0 : _f.key) === keyPath[2]) {
                                labelPath1.push((_g = children2[index3]) === null || _g === void 0 ? void 0 : _g.label);
                                path1.push((_h = children2[index3]) === null || _h === void 0 ? void 0 : _h.key);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
        }
        setLabelPath(labelPath1);
        setPath(path1);
    }, [keyPathHash, menus]);
    return (React.createElement(Breadcrumb, { className: 'bread-crumb' }, labelPath.map(function (item, index) {
        return React.createElement(Breadcrumb.Item, { key: index }, (index === 0 || index === labelPath.length - 1) ? item : React.createElement("a", { href: "/#/".concat(path.slice(0, index + 1).join('/')) }, item));
    })));
};
export default breadcrumb;
