var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Cascader, Input, Modal, Pagination, Table } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { postJson } from "../../../../utils/axios";
import { PRIMEINDUSTRY } from "../../../../api/api";
var filter = function (inputValue, path) {
    return path.some(function (option) {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
            -1;
    });
};
var AreaSearch = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, roleId = props.roleId, staticStore = props.staticStore, searchValue = props.searchValue, sendChooseItem = props.sendChooseItem;
    var inputMaxLength = staticStore.inputMaxLength, tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(defaultPagination), pagination = _c[0], setPagination = _c[1];
    var _d = useState({
        areaCode: [],
        productName: "",
    }), search = _d[0], setSearch = _d[1];
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var getData = function (cur, size) {
        var areaCode = search.areaCode, productName = search.productName;
        var params = {
            current: cur,
            pageSize: size,
            productName: productName,
            cityName: areaCode ? areaCode[1] : '',
            countyName: "",
            flag: 1,
            provinceName: areaCode ? areaCode[0] : '',
            search: "",
        };
        console.log("params", params);
        postJson(PRIMEINDUSTRY.getList, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination({
                    total: pagination_1.total,
                    current: pagination_1.current,
                    pageSize: pagination_1.pageSize,
                });
            }
        });
    };
    var columns = [
        {
            title: "产地",
            dataIndex: "placeOfOrigin",
            key: "placeOfOrigin",
        },
        {
            title: "产品名称",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "产品编号",
            dataIndex: "productNumber",
            key: "productNumber",
        },
        {
            title: "证书持有者",
            dataIndex: "certificateHolders",
            key: "certificateHolders",
        },
        {
            title: "登记年份",
            dataIndex: "registrationYear",
            key: "registrationYear",
            width: 120,
        },
        {
            title: "详情链接",
            dataIndex: "detailsLink",
            key: "detailsLink",
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 80,
            render: function (v, r) { return (React.createElement("a", { href: "javascript:;", onClick: function () { return onChoose(r); } }, "\u9009\u62E9")); },
        },
    ];
    var getAreaList = function () {
        var params = {
            "cityName": "",
            "provinceName": ""
        };
        postJson(PRIMEINDUSTRY.getProvinceCity, params).then(function (res) {
            if (res) {
                var list = (res === null || res === void 0 ? void 0 : res.results) || [];
                list = list.map(function (item) {
                    var _a;
                    var cityList = (_a = item === null || item === void 0 ? void 0 : item.cityList) === null || _a === void 0 ? void 0 : _a.map(function (item2) { return ({
                        label: item2.cityName,
                        value: item2.cityName,
                    }); });
                    return {
                        label: item.provinceName,
                        value: item.provinceName,
                        children: cityList,
                    };
                });
                setAreaList(list);
            }
        });
    };
    var onChoose = function (item) {
        sendChooseItem(item);
        setIsShowModal(false);
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
    };
    useEffect(function () {
        getAreaList();
    }, []);
    useEffect(function () {
        setPagination(defaultPagination);
        getData(1, 10);
    }, [search.areaCode]);
    return (React.createElement(Modal, { title: "\u533A\u57DF\u4EA7\u4E1A\u67E5\u8BE2", open: true, maskClosable: false, footer: false, width: 1300, onCancel: handleCancel, wrapClassName: "edit-modal" },
        React.createElement("div", { className: "prime_industry_area_search" },
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u5730\u57DF\uFF1A"),
                React.createElement(Cascader, { options: areaList, style: { width: tableSearchWidth }, placeholder: "\u8BF7\u9009\u62E9", showSearch: { filter: filter }, allowClear: true, value: search.areaCode, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { areaCode: v })); });
                    } })),
            React.createElement("div", null,
                React.createElement("span", null, "\u4EA7\u54C1\u540D\u79F0\uFF1A"),
                React.createElement(Input, { value: search.productName, style: { width: tableSearchWidth }, maxLength: inputMaxLength, placeholder: "\u8BF7\u8F93\u5165", allowClear: true, onChange: function (e) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { productName: e.target.value })); });
                    }, onPressEnter: function () {
                        setPagination(defaultPagination);
                        getData(1, 10);
                    } }))),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content", y: 500 }, pagination: false, rowKey: "reportId" }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, onChange: onChange, showSizeChanger: true, current: pagination.current, onShowSizeChange: onShowSizeChange })))));
}));
export default AreaSearch;
