import { Checkbox, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { SITEMANAGER } from "../../../../api/api";
import { postJsonFromES } from "../../../../utils/axios";
import { debounce } from "lodash";
export default function NewSiteModal(props) {
    var isShowModal = props.isShowModal, list = props.list, setIsShowModal = props.setIsShowModal, getData = props.getData;
    var _a = useState([]), checkList = _a[0], setCheckList = _a[1];
    var handleOk = debounce(function () {
        var dataList = [];
        list.forEach(function (item, index) {
            if (checkList.indexOf(item.url) > -1) {
                dataList.push({
                    domainUrl: item.domain,
                    id: null,
                    siteName: item.siteName,
                    status: "",
                    url: item.url,
                });
            }
        });
        var params = {
            dataList: dataList,
        };
        postJsonFromES(SITEMANAGER.add, params).then(function (res) {
            if (res) {
                // message.success("保存成功");
                setIsShowModal(false);
                getData && getData("clear");
            }
        });
        console.log("dataList", dataList);
    }, 250, { maxWait: 2000 });
    var onChange = function (checkedValues) {
        console.log("checked = ", checkedValues);
        setCheckList(checkedValues);
    };
    return (
    // <div></div>
    React.createElement(Modal, { title: "\u65B0\u589E\u7AD9\u70B9\u9ED1\u540D\u5355", open: isShowModal, onOk: handleOk, onCancel: function () { return setIsShowModal(false); }, className: "new-site-modal" },
        React.createElement("div", { className: "modal-tip mb20" }, "\u52A0\u5165\u9ED1\u540D\u5355\u540E\u8BE5\u7AD9\u70B9\u5C06\u505C\u6B62\u6570\u636E\u722C\u53D6\uFF0C\u5E76\u5220\u9664\u8BE5\u7AD9\u70B9\u7684\u6240\u6709\u5386\u53F2\u6570\u636E\uFF0C\u662F\u5426\u7ACB\u5373\u6267\u884C\uFF1F"),
        React.createElement(Checkbox.Group, { style: { width: "100%" }, onChange: onChange },
            React.createElement("div", { className: "checkbox-list" }, (list || []).map(function (item, index) { return (React.createElement(Row, { key: index, className: "mt10" },
                React.createElement(Col, { span: 24 },
                    React.createElement(Checkbox, { value: item.url },
                        item.siteName,
                        "\uFF1A",
                        React.createElement("a", { className: "link-url ellipsis-text", target: "_blank", href: item.url }, item.url))))); })))));
}
