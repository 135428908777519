var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Col, Input, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { SITEMANAGER } from "../../../../api/api";
import { postJsonFromES } from "../../../../utils/axios";
export default function EditSiteModal(props) {
    var isShowEditModal = props.isShowEditModal, _a = props.editData, editData = _a === void 0 ? {} : _a, setIsShowEditModal = props.setIsShowEditModal, getData = props.getData;
    console.log("editData", editData);
    var _b = useState(editData.url), url = _b[0], setUrl = _b[1];
    var handleOk = function () {
        var params = __assign(__assign({}, editData), { url: url });
        postJsonFromES(SITEMANAGER.update, params).then(function (res) {
            if (res.status === 200) {
                message.success('保存成功');
                setIsShowEditModal(false);
                getData && getData('clear');
            }
            else {
                message.error(res.msg);
            }
        });
    };
    return (React.createElement(Modal, { title: "\u7F16\u8F91\u7AD9\u70B9\u9ED1\u540D\u5355", open: isShowEditModal, onOk: handleOk, onCancel: function () { return setIsShowEditModal(false); }, className: "edit-site-modal" },
        React.createElement(Row, null,
            React.createElement(Col, { span: 4 }, "\u7AD9\u70B9\u540D\u79F0\uFF1A"),
            React.createElement(Col, null, editData.siteName)),
        React.createElement(Row, { className: "mt20" },
            React.createElement(Col, { span: 4, flex: "right" },
                React.createElement("div", { className: "edit-site-modal-url mr10 mt5" }, "URL\uFF1A")),
            React.createElement(Col, null,
                React.createElement(Input, { value: url, onChange: function (e) {
                        setUrl(e.target.value);
                    }, style: { width: "300px" }, maxLength: 200 })))));
}
