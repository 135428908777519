var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message } from "antd";
import { isArray } from "lodash";
var getBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () { return resolve(reader.result); };
        reader.onerror = function (error) { return reject(error); };
    });
};
export default function UploadImg(props) {
    var _this = this;
    var action = props.action, url = props.url, sendUrl = props.sendUrl, _a = props.maxLength, maxLength = _a === void 0 ? 1 : _a, data = props.data;
    var _b = useState(false), previewOpen = _b[0], setPreviewOpen = _b[1];
    var _c = useState(""), previewImage = _c[0], setPreviewImage = _c[1];
    var _d = useState(""), previewTitle = _d[0], setPreviewTitle = _d[1];
    var _e = useState(), fileUrl = _e[0], setFileUrl = _e[1];
    var _f = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    ]), fileList = _f[0], setFileList = _f[1];
    var handleCancel = function () { return setPreviewOpen(false); };
    var handlePreview = function (file) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(!file.url && !file.preview)) return [3 /*break*/, 2];
                    _a = file;
                    return [4 /*yield*/, getBase64(file.originFileObj)];
                case 1:
                    _a.preview = _b.sent();
                    _b.label = 2;
                case 2:
                    setPreviewImage(file.url || file.preview);
                    setPreviewOpen(true);
                    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (_a) {
        var _b, _c;
        var newFileList = _a.fileList, event = _a.event, file = _a.file;
        console.log("newFileList", newFileList);
        console.log("file", file);
        if (file.status === "done") {
            if (((_b = file === null || file === void 0 ? void 0 : file.response) === null || _b === void 0 ? void 0 : _b.state) !== 200) {
                setFileList([]);
                return message.error((_c = file === null || file === void 0 ? void 0 : file.response) === null || _c === void 0 ? void 0 : _c.msg);
            }
            else {
                message.success("".concat(file.name, " \u6587\u4EF6\u4E0A\u4F20\u6210\u529F"));
            }
        }
        else if (file.status === "error") {
            message.error("".concat(file.name, " \u6587\u4EF6\u4E0A\u4F20\u5931\u8D25"));
        }
        else if (file.status === "removed") {
            message.success("".concat(file.name, " \u6587\u4EF6\u79FB\u9664\u6210\u529F"));
        }
        setFileList(newFileList);
        handleSendUrl(newFileList);
    };
    var handleSendUrl = function (newFileList) {
        var _a, _b;
        var returnUrl;
        if (maxLength === 1) {
            var item = newFileList.length > 0 && newFileList[0];
            returnUrl = ((_b = (_a = item === null || item === void 0 ? void 0 : item.response) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.url) || '';
        }
        else {
            returnUrl = newFileList.map(function (item) {
                var _a, _b;
                return ((_b = (_a = item === null || item === void 0 ? void 0 : item.response) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.url) || '';
            });
        }
        console.log('returnUrl', returnUrl);
        setFileUrl(returnUrl);
        sendUrl(returnUrl);
    };
    var beforeUpload = function (file) {
        var isPNG = file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/gif" ||
            file.type === "image/png" ||
            file.type === "image/bmp" ||
            file.type === "image/tiff" ||
            file.type === "image/webp" ||
            file.type === "image/apng";
        if (!isPNG) {
            message.error("上传文件格式不在白名单内，请重试");
        }
        return isPNG || Upload.LIST_IGNORE; // 如果需要阻止列表展现，可以通过返回 Upload.LIST_IGNORE 实现
    };
    var uploadButton = (React.createElement("div", null,
        React.createElement(PlusOutlined, null),
        React.createElement("div", { style: { marginTop: 8 } }, "\u4E0A\u4F20")));
    var upLoadProps = {
        accept: "image/*",
        action: action ||
            "/apiInterface/interface/hydra-opinion-platform/api/v1/common/hydraFile/uploadFile",
        headers: {
            "platform-token": sessionStorage.getItem("platform-token"),
        },
        listType: "picture-card",
        fileList: fileList,
        data: data,
        onPreview: handlePreview,
        beforeUpload: beforeUpload,
        onChange: handleChange,
    };
    useEffect(function () {
        console.log("url", url);
        if (JSON.stringify(url) === JSON.stringify(fileUrl))
            return;
        var newUrls = [];
        if (typeof url === 'string' && url !== '') {
            newUrls = [
                {
                    url: url,
                    uid: 1,
                    name: 1,
                    status: "done",
                }
            ];
        }
        else if (isArray(url)) {
            var urlsCopy = url.slice();
            urlsCopy.forEach(function (item, index) {
                if (item === "" || item === undefined || item === null)
                    return;
                if (typeof item === "string") {
                    newUrls.push({
                        url: item,
                        uid: index,
                        name: index,
                        status: "done",
                    });
                }
            });
        }
        console.log("newUrls", newUrls);
        setFileList(newUrls);
    }, [url]);
    return (React.createElement("div", { style: { height: '112px' } },
        React.createElement(Upload, __assign({}, upLoadProps), fileList.length >= maxLength ? null : uploadButton),
        React.createElement(Modal, { open: previewOpen, title: previewTitle, footer: null, onCancel: handleCancel },
            React.createElement("img", { alt: "example", style: { width: "100%" }, src: previewImage }))));
}
