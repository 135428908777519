import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { REPORT } from "../../../../api/api";
import { get } from "../../../../utils/axios";
export default function PreviewModal(props) {
    var setIsShowPreview = props.setIsShowPreview, id = props.id;
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    var _b = useState(""), url = _b[0], setUrl = _b[1];
    var _c = useState(false), isLoaded = _c[0], setIsLoaded = _c[1];
    var handleCancel = function () {
        setIsShowPreview(false);
    };
    var getData = function () {
        get("".concat(REPORT.checkReport, "/").concat(id)).then(function (res) {
            var _a;
            var _b = (res === null || res === void 0 ? void 0 : res.results) || {}, title = _b.title, fileSimpleDTOList = _b.fileSimpleDTOList;
            setTitle(title);
            setUrl((_a = fileSimpleDTOList[0]) === null || _a === void 0 ? void 0 : _a.resourceUrl);
        });
    };
    useEffect(function () {
        getData();
    }, [id]);
    return (React.createElement(Modal, { width: 750, footer: false, open: true, onCancel: handleCancel, title: title, bodyStyle: { height: "700px" } },
        React.createElement("iframe", { frameBorder: "0", src: "".concat(url, "#toolbar=0"), onLoad: function () { return setIsLoaded(true); }, 
            // src={`http://opinion-oss.jgwcjm.com/yuqing%2F3001%2F202301121827231791613482766202003457?e=1673522843&token=bRjsZ9KyHlh438VtBU4YHszEApHMwEgN9QVwDoYB:q9bnhn1pYUBuNs1khfHxJ5hQvXQ=#toolbar=0`}
            style: { width: "100%", height: "100%", display: "".concat(isLoaded ? 'block' : 'none') } })));
}
