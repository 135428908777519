var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Row, Col, Checkbox, Tabs, message, Select, } from "antd";
import { get, postForm, postJson } from "../../../../utils/axios";
import { MENU, ROLE, AREA_MANGE, COMMON } from "../../../../api/api";
import TreeSelect from "../../../../components/treeSelect";
import "../index.scss";
import { inject, observer } from "mobx-react";
var TextArea = Input.TextArea;
var TabPane = Tabs.TabPane;
var loginName = "admin";
var EditModal = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, roleId = props.roleId, getData = props.getData, staticStore = props.staticStore;
    var inputWidth = staticStore.inputWidth;
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    var _b = useState(false), isDisabledForm = _b[0], setIsDisabledForm = _b[1];
    var _c = useState([]), choosedKeys = _c[0], setChoosedKeys = _c[1];
    var _d = useState(false), isAdd = _d[0], setIsAdd = _d[1];
    var _e = useState(false), isCheck = _e[0], setIsCheck = _e[1];
    var _f = useState([]), menuOption = _f[0], setMenuOption = _f[1];
    var _g = useState([]), areaList = _g[0], setAreaList = _g[1];
    var _h = useState([]), roleTypeList = _h[0], setRoleTypeList = _h[1];
    var _j = useState({
        description: "",
        groupId: "",
        roleName: "",
        menuIds: [],
        isDefault: false,
        roleId: "",
        updateName: "",
        updateTime: "",
        createName: "",
        createTime: "",
        orgId: null,
    }), formObj = _j[0], setFormObj = _j[1];
    var form = Form.useForm()[0];
    var handleOk = function () {
        form
            .validateFields()
            .then(function (values) {
            console.log("values", values);
            var params = __assign({}, formObj);
            console.log("params", params);
            postJson(ROLE.saveRoleMenu, params).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    setIsShowModal(false);
                    getData();
                }, 1000);
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            return;
        });
    };
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var onDefaultRoleChange = function (e) {
        if (e.target.checked) {
            postForm(ROLE.checkDefaultRole, { excludeRoleId: roleId || "" }).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    setFormObj(function (pre) { return (__assign(__assign({}, pre), { isDefault: false })); });
                    return;
                }
                setFormObj(function (pre) { return (__assign(__assign({}, pre), { isDefault: e.target.checked })); });
            });
        }
        else {
            setFormObj(function (pre) { return (__assign(__assign({}, pre), { isDefault: false })); });
        }
    };
    var tabChange = function (activeKey) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('tabChange');
            setFormObj(function (pre) { return (__assign(__assign({}, pre), { groupId: activeKey, menuIds: [], orgId: '' })); });
            setMenuOption([]);
            form.setFieldValue('orgId', null);
            switch (activeKey) {
                case 'SCREEN':
                    getAreaList();
                    break;
                case 'OFFICIAL_WEBSITE':
                    getMenuList([], 'OFFICIAL_WEBSITE'); // 官网
                    break;
                case 'ADMIN_WEBSITE':
                    getMenuList([], 'ADMIN_WEBSITE'); // 管理后台
                    break;
            }
            return [2 /*return*/];
        });
    }); };
    var getRoleTypes = function () {
        get(COMMON.getList, { type: 5 }).then(function (res) {
            var _a;
            if (res) {
                var firstKey_1 = ((res === null || res === void 0 ? void 0 : res.results) || []).length > 0 ? (_a = res === null || res === void 0 ? void 0 : res.results[0]) === null || _a === void 0 ? void 0 : _a.key : '';
                console.log('firstKey', firstKey_1);
                setFormObj(function (pre) { return (__assign(__assign({}, pre), { groupId: firstKey_1 })); });
                console.log('isDisabledForm', isDisabledForm);
                console.log('isCheck', isCheck);
                setRoleTypeList(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({
                    label: item.name,
                    key: item.key,
                    disabled: modalType !== 'add',
                }); }));
            }
        });
    };
    var getMenuList = function (menuIds, type, orgId) {
        if (type === 'SCREEN' && !orgId) {
            setMenuOption([]);
            return;
        }
        postForm(MENU.getMenuTreeByModule, {
            module: type,
            status: 1,
            orgId: orgId
        }).then(function (res) {
            var results = res.results;
            setMenuOption(results);
            // let parentMenuIds: string[] = [];
            // for (let item of results) {
            //   if (item.children && item.children.length > 0) {
            //     parentMenuIds.push(item.menuId);
            //     for (let item2 of item.children) {
            //       if (item2.children.length > 0) {
            //         parentMenuIds.push(item2.menuId);
            //       }
            //     }
            //   }
            // }
            var menuIdsCopy = menuIds.slice();
            // parentMenuIds.forEach((item: string) => {
            //   let index = menuIdsCopy.indexOf(item);
            //   if (index > -1) {
            //     menuIdsCopy.splice(index, 1);
            //   }
            // });
            // console.log("menuIdsCopy", menuIdsCopy);
            setChoosedKeys(menuIdsCopy);
        });
    };
    var getDetail = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAreaList()];
                case 1:
                    _a.sent();
                    get("".concat(ROLE.getDetail, "/").concat(roleId), { roleId: roleId }).then(function (res) {
                        var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                        setFormObj(results);
                        form.setFieldsValue(results);
                        switch (results === null || results === void 0 ? void 0 : results.groupId) {
                            case "SCREEN":
                                getMenuList(results.menuIds, 'SCREEN', results.orgId); // 驾驶舱
                                break;
                            case "OFFICIAL_WEBSITE":
                                getMenuList(results.menuIds, 'OFFICIAL_WEBSITE'); // 官网
                                break;
                            case 'ADMIN_WEBSITE':
                                getMenuList(results.menuIds, 'ADMIN_WEBSITE'); // 管理后台
                                break;
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var onOrgIdChange = function (value) {
        setMenuOption([]);
        setFormObj(function (pre) { return (__assign(__assign({}, pre), { orgId: value })); });
        getMenuList([], 'SCREEN', value);
        form.setFieldValue('orgId', value);
    };
    var getAreaList = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get(AREA_MANGE.getOrgList, { moduleEnum: "SCREEN" }).then(function (res) {
                        if (res) {
                            setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
                        }
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var sendCheckedKeys = function (v) {
        form.setFieldValue("menuIds", v);
        v.forEach(function (item, index) {
            if (item === undefined) {
                v.splice(index, 1);
            }
        });
        setFormObj(function (pre) { return (__assign(__assign({}, pre), { menuIds: v })); });
    };
    useEffect(function () {
        switch (modalType) {
            case "add":
                setTitle("新建角色");
                setIsAdd(true);
                getMenuList([], 'ADMIN_WEBSITE'); // 管理后台
                break;
            case "edit":
                setTitle("编辑角色");
                getDetail();
                break;
            case "check":
                setTitle("查看角色");
                setIsDisabledForm(true);
                setIsCheck(true);
                getDetail();
                break;
        }
        getRoleTypes();
    }, [modalType]);
    return (React.createElement(Modal, { title: title, open: true, maskClosable: false, footer: false, width: 750, onCancel: handleCancel, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form },
            React.createElement(Form.Item, { label: "\u521B\u5EFA\u4EBA\uFF1A", name: "create" },
                React.createElement("div", null, loginName)),
            React.createElement(Form.Item, { label: "\u89D2\u8272\u7C7B\u578B\uFF1A", name: "groupId" },
                React.createElement(Tabs, { activeKey: formObj.groupId, onChange: tabChange, items: roleTypeList, style: { width: inputWidth, marginBottom: '-20px' } })),
            React.createElement(Row, null,
                React.createElement(Col, { span: 20, push: 1 },
                    React.createElement(Form.Item, { label: "\u89D2\u8272\u540D\u79F0\uFF1A", name: "roleName", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement("div", { className: "role-name" },
                            React.createElement(Input, { value: formObj.roleName, maxLength: 50, style: { width: inputWidth, marginRight: "20px" }, placeholder: "\u8BF7\u8F93\u5165", disabled: isDisabledForm, onChange: function (e) {
                                    setFormObj(function (pre) { return (__assign(__assign({}, pre), { roleName: e.target.value })); });
                                } })))),
                formObj.groupId === "OFFICIAL_WEBSITE" && (React.createElement(Col, { pull: 1, style: { marginTop: "5px" } },
                    React.createElement(Checkbox, { onChange: onDefaultRoleChange, checked: formObj.isDefault, disabled: isDisabledForm }, "\u9ED8\u8BA4\u89D2\u8272")))),
            React.createElement(Form.Item, { label: "\u89D2\u8272\u63CF\u8FF0\uFF1A", name: "description" },
                React.createElement(TextArea, { value: formObj.description, onChange: function (e) {
                        setFormObj(function (pre) { return (__assign(__assign({}, pre), { description: e.target.value })); });
                    }, rows: 4, placeholder: "\u6CA1\u4ED8\u94B1\u7684\u7528\u6237", disabled: isDisabledForm, maxLength: 200, style: { width: inputWidth } })),
            formObj.groupId === "SCREEN" && (React.createElement(Form.Item, { label: "\u5730\u57DF\uFF1A", name: "orgId", rules: [{ required: true, message: "请重新选择！" }] },
                React.createElement(Select, { value: formObj.orgId, placeholder: "\u8BF7\u9009\u62E9", style: { width: inputWidth }, allowClear: true, showSearch: true, options: areaList, disabled: isDisabledForm, onChange: onOrgIdChange, fieldNames: {
                        label: "regionName",
                        value: "orgId",
                    }, filterOption: function (input, option) {
                        var _a;
                        return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    } }))),
            React.createElement(Form.Item, { label: "\u5E94\u7528\u6743\u9650\u914D\u7F6E\uFF1A", name: "menuIds", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                React.createElement(TreeSelect, { menuOption: menuOption, choosedKeys: choosedKeys, activityKey: '', disabled: isDisabledForm, sendCheckedKeys: sendCheckedKeys, checkStrictly: true, isCheckParent: false }))),
        !isCheck && (React.createElement("div", { className: "modal-footer-btn" },
            React.createElement("div", { className: "footer-btn de-active mr10", onClick: handleCancel }, "\u53D6\u6D88"),
            React.createElement("div", { className: "footer-btn active", onClick: handleOk }, "\u786E\u5B9A")))));
}));
export default EditModal;
