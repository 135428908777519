var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Input, Table, Pagination, Popconfirm, message } from "antd";
import EditModal from "./components/EditModal";
import { COMMON, ROLE } from "../../../api/api";
import { get, postForm } from "../../../utils/axios";
import "./index.scss";
import { inject, observer } from "mobx-react";
var RoleManager = inject('staticStore')(observer(function (props) {
    var staticStore = props.staticStore;
    // const {roleType} = staticStore
    var _a = useState({}), searchParams = _a[0], setSearchParams = _a[1];
    var _b = useState([]), roleList = _b[0], setRoleList = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(10), total = _d[0], setTotal = _d[1];
    var _e = useState(10), pageSize = _e[0], setPageSize = _e[1];
    var _f = useState(1), current = _f[0], setCurrent = _f[1];
    var _g = useState(false), isShowModal = _g[0], setIsShowModal = _g[1];
    var _h = useState(""), modalType = _h[0], setModalType = _h[1];
    var _j = useState(""), roleId = _j[0], setId = _j[1];
    var _k = useState([]), userTypeList = _k[0], setUserTypeList = _k[1];
    var columns = [
        {
            title: '角色',
            dataIndex: 'roleName',
            key: 'roleName',
            ellipsis: true,
            render: function (value) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'describe' }, value))); }
        },
        {
            title: '角色类型',
            dataIndex: 'groupId',
            key: 'groupId',
            ellipsis: true,
            render: function (value) { var _a; return (_a = userTypeList.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.text; },
            onFilter: function (value, record) { return record.groupId.indexOf(value) === 0; },
            filters: userTypeList
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            render: function (value) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'describe', title: value }, value))); }
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            sorter: function (a, b) {
                return new Date(a.createTime).getTime() - new Date(b.createTime).getTime();
            },
            ellipsis: true,
        },
        {
            title: "修改时间",
            dataIndex: "updateTime",
            key: "updateTime",
            sorter: function (a, b) {
                return new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime();
            },
            ellipsis: true,
        },
        {
            title: "操作",
            key: "roleId",
            dataIndex: "roleId",
            fixed: "right",
            width: 160,
            render: function (value, record) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { className: "mr14", onClick: function () { return gotoNew("check", value); } }, "\u67E5\u770B"),
                React.createElement("a", { className: "mr14", onClick: function () { return gotoNew("edit", value); } }, "\u7F16\u8F91"),
                !record.deleted ? (React.createElement(Popconfirm, { title: "\u786E\u5B9A\u8981\u5220\u5417\uFF1F\u786E\u5B9A\u5417\uFF1F", onConfirm: function () { return deleteData(value); }, okText: "\u662F", cancelText: "\u5426" },
                    React.createElement("a", { className: "delete" }, "\u5220\u9664"))) : (React.createElement("span", { title: "\u505C\u7528\u540E\u53EF\u5220\u9664", className: "disabled-delete" }, "\u5220\u9664")))); },
        },
    ];
    var gotoNew = function (type, roleId) {
        setId(roleId);
        setModalType(type);
        setIsShowModal(true);
    };
    var deleteData = function (value) {
        console.log("value", value);
        var params = {
            roleId: value,
        };
        postForm(ROLE.del, params).then(function (res) {
            if (res.state === 200) {
                message.success("删除成功");
                getData();
            }
        });
    };
    var onChange = function (pageNumber) {
        setCurrent(pageNumber);
    };
    var onShowSizeChange = function (current, size) {
        setPageSize(size);
        setCurrent(current);
    };
    var onPressEnter = function (e) {
        var element = e.target;
        var value = element.value && element.value.replace(" ", "");
        setSearchParams(__assign(__assign({}, searchParams), { search: value.trim() }));
    };
    var getData = function () {
        var params = __assign({ current: current, pageSize: pageSize }, searchParams);
        get(ROLE.list, params).then(function (res) {
            var _a = res === null || res === void 0 ? void 0 : res.results, list = _a.list, pagination = _a.pagination;
            var current = pagination.current, pageSize = pagination.pageSize, total = pagination.total;
            setPageSize(pageSize);
            setCurrent(current);
            setTotal(total);
            setData(list);
        });
    };
    var onSearchChange = function (e) {
        if (e.target.value === "") {
            setSearchParams(__assign(__assign({}, searchParams), { search: "" }));
        }
    };
    var getUserTypes = function () {
        get(COMMON.getList, { type: 5 }).then(function (res) {
            if (res) {
                // setActiveKey((res?.results || []).length>0 ? res?.results[0]?.key : '')
                setUserTypeList(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({
                    text: item.name,
                    value: item.key,
                }); }));
            }
        });
    };
    useEffect(function () {
        getUserTypes();
    }, []);
    useEffect(function () {
        getData();
    }, [current, pageSize, searchParams]);
    return (React.createElement("div", { className: "role-manager" },
        React.createElement("div", { className: "role-cont" },
            React.createElement("div", { className: "search-btn" },
                React.createElement("div", { className: "btn" },
                    React.createElement("div", { className: "btn-new mr50", onClick: function () { return gotoNew("add"); } }, "+ \u65B0\u5EFA"),
                    React.createElement("div", { className: "search" },
                        React.createElement("div", null, "\u89D2\u8272\uFF1A"),
                        React.createElement(Input, { size: "small", placeholder: "\u6309\u56DE\u8F66\u641C\u7D22", onPressEnter: onPressEnter, onChange: function (e) {
                                onSearchChange(e);
                            }, allowClear: true })))),
            React.createElement("div", { className: 'cont-table mt20' },
                React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: 1300 }, pagination: false, rowKey: 'roleId', rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
                React.createElement("div", { className: 'cont-pagination mt20' },
                    React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange })))),
        isShowModal && (React.createElement(EditModal, { modalType: modalType, roleId: roleId, setIsShowModal: setIsShowModal, getData: getData }))));
}));
export default RoleManager;
