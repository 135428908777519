var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Select, Input, Table, Pagination, Popconfirm, Tooltip, message } from 'antd';
import { useNavigate } from "react-router-dom";
import UserTypeCount from './components/UserTypeCount';
import './index.scss';
import { get, postForm } from '../../../utils/axios';
import { USER, ROLE, COMMON } from '../../../api/api';
import { inject, observer } from 'mobx-react';
var UserManager = inject('staticStore')(observer(function (props) {
    var staticStore = props.staticStore;
    var sourceList = staticStore.sourceList, getSourceItem = staticStore.getSourceItem;
    var _a = useState([]), roleList = _a[0], setRoleList = _a[1];
    var _b = useState([]), statusList = _b[0], setStatusList = _b[1];
    var _c = useState({}), searchParams = _c[0], setSearchParams = _c[1];
    var _d = useState([]), data = _d[0], setData = _d[1];
    var _e = useState(10), total = _e[0], setTotal = _e[1];
    var _f = useState(10), pageSize = _f[0], setPageSize = _f[1];
    var _g = useState(1), current = _g[0], setCurrent = _g[1];
    var _h = useState([]), userTypeList = _h[0], setUserTypeList = _h[1];
    var _j = useState([]), accountTypeList = _j[0], setAccountTypeList = _j[1];
    var navigate = useNavigate();
    var handleChange = function (value, type) {
        var _a;
        setSearchParams(__assign(__assign({}, searchParams), (_a = {}, _a[type] = value, _a)));
    };
    var columns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            key: 'userName',
            ellipsis: true,
        },
        {
            title: '角色',
            dataIndex: 'role',
            key: 'role',
            ellipsis: true,
            render: function (value, record) { return React.createElement(React.Fragment, null, record === null || record === void 0 ? void 0 : record.roleList.map(function (item) { return item.roleName; }).join(',')); }
        },
        {
            title: '用户类型',
            dataIndex: 'accountType',
            key: 'accountType',
            ellipsis: true,
            filters: userTypeList,
            render: function (value, record) {
                var _a;
                return React.createElement(React.Fragment, null, (_a = userTypeList.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.text);
            }
        },
        { title: '来源', dataIndex: 'source', key: 'source', ellipsis: true, render: function (v) { return React.createElement(React.Fragment, null, getSourceItem(v)); } },
        { title: '手机号', dataIndex: 'phone', ellipsis: true, key: 'phone' },
        { title: '邮箱', dataIndex: 'email', ellipsis: true, key: ' email' },
        {
            title: '状态', dataIndex: 'status', ellipsis: true, key: 'status', render: function (value) { return React.createElement(React.Fragment, null, !value ? React.createElement("div", { className: 'status-cont' },
                React.createElement("span", { className: 'point stop' }),
                React.createElement("span", { className: 'stop-text' }, "\u505C\u7528"))
                : React.createElement("div", { className: 'status-cont' },
                    React.createElement("span", { className: 'point start' }),
                    React.createElement("span", { className: 'open-text' }, "\u542F\u7528"))); }
        },
        {
            title: '意向', dataIndex: 'intentionType', ellipsis: true, key: 'intentionType', render: function (value, record) { return React.createElement(React.Fragment, null, value === 'NONE' ? React.createElement("span", { className: 'tend-no-tend' }, "\u6682\u65E0") : React.createElement(Tooltip, { title: record.intentionDescription },
                React.createElement("span", { className: 'tend-tend' }, "\u6709\u610F\u5411"))); }
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            sorter: function (a, b) { return new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime(); },
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'userId',
            dataIndex: 'userId',
            fixed: 'right',
            width: 200,
            render: function (value, record) { return React.createElement(React.Fragment, null,
                React.createElement("a", { className: 'mr14', onClick: function () { return checkData(value); } }, "\u67E5\u770B"),
                React.createElement("a", { className: 'mr14', onClick: function () { return gotoNew('edit', value); } }, "\u7F16\u8F91"),
                React.createElement(Popconfirm, { title: "\u786E\u8BA4".concat(!record.status ? '启用' : '停用', "\u8BE5\u7528\u6237"), onConfirm: function () { return startStop(value, record.status); }, okText: "\u662F", cancelText: "\u5426" },
                    React.createElement("a", { className: 'mr14' }, !record.status ? '启用' : '停用')),
                !record.status ?
                    React.createElement(Popconfirm, { title: '\u786E\u5B9A\u8981\u5220\u5417\uFF1F\u786E\u5B9A\u5417\uFF1F', onConfirm: function () { return deleteData(value); }, okText: "\u662F", cancelText: "\u5426" },
                        React.createElement("a", { className: 'delete' }, "\u5220\u9664"))
                    : React.createElement("span", { title: "\u505C\u7528\u540E\u53EF\u5220\u9664", className: 'disabled-delete' }, "\u5220\u9664")); },
        },
    ];
    var checkData = function (value) {
        navigate("/system/userManager/check/".concat(value));
    };
    var deleteData = function (value) {
        postForm(USER.del, { userId: value }).then(function (res) {
            if (res.state === 200) {
                message.success("\u5220\u9664\u6210\u529F");
                getData();
            }
        });
    };
    var startStop = function (value, status) {
        console.log('value', value);
        postForm(USER.operate, { userId: value }).then(function (res) {
            if (res.state === 200) {
                message.success("".concat(status ? '停用' : '启用', "\u6210\u529F"));
                getData();
            }
        });
    };
    var onPressEnter = function (e) {
        var element = e.target;
        var value = element.value && element.value.replace(' ', '');
        setSearchParams(function (preState) { return (__assign(__assign({}, preState), { search: value })); });
    };
    var getData = function () {
        var params = __assign(__assign({}, searchParams), { pageSize: pageSize, current: current });
        var url = accountTypeList && accountTypeList.length > 0 ? "".concat(USER.list, "?") : "".concat(USER.list);
        accountTypeList && accountTypeList.forEach(function (item, index) {
            if (index === accountTypeList.length - 1) {
                url = "".concat(url, "accountType=").concat(item);
            }
            else {
                url = "".concat(url, "accountType=").concat(item, "&");
            }
        });
        get(url, params).then(function (res) {
            if (res.state === 200) {
                var _a = res === null || res === void 0 ? void 0 : res.results, _b = _a.list, list = _b === void 0 ? [] : _b, _c = _a.pagination, pagination = _c === void 0 ? {} : _c;
                var current_1 = pagination.current, pageSize_1 = pagination.pageSize, total_1 = pagination.total;
                setPageSize(pageSize_1);
                setCurrent(current_1);
                setTotal(total_1);
                setData(list);
            }
        });
    };
    var onChange = function (pageNumber) {
        setCurrent(pageNumber);
    };
    var onShowSizeChange = function (current, size) {
        setPageSize(size);
        setCurrent(current);
    };
    var gotoNew = function (type, id) {
        switch (type) {
            case 'add':
                navigate('/system/userManager/add');
                break;
            case 'edit':
                navigate("/system/userManager/edit/".concat(id));
                break;
        }
    };
    var onSearchChange = function (e) {
        console.log('e.target.value', e.target.value);
        if (e.target.value === '') {
            setSearchParams(__assign(__assign({}, searchParams), { search: '' }));
        }
    };
    var getRoles = function () {
        var params = {
            current: 1,
            pageSize: 100
        };
        get(ROLE.optionList, params).then(function (res) {
            var list = (res === null || res === void 0 ? void 0 : res.results).list;
            setRoleList(list);
        });
    };
    var getUserType = function () {
        get(COMMON.getList, { type: 1 }).then(function (res) {
            if (res) {
                setUserTypeList(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({ text: item.name, value: item.key }); }));
            }
        });
    };
    var handleTableChange = function (pagination, filters) {
        setAccountTypeList(filters.accountType);
    };
    useEffect(function () {
        var statusList = [
            { value: 0, label: '停用' },
            { value: 1, label: '启用' },
        ];
        setStatusList(statusList);
    }, []);
    useEffect(function () {
        getData();
    }, [searchParams, pageSize, current, accountTypeList]);
    useEffect(function () {
        getRoles();
        getUserType();
    }, []);
    useEffect(function () {
        setCurrent(Number(sessionStorage.getItem('current')) || 1);
    }, [sessionStorage.getItem('current')]);
    var getTags = function (tags) {
        console.log(tags);
    };
    var validateInput = function (value) {
        return /^[a-zA-Z0-9]+$/.test(value);
    };
    return (React.createElement("div", { className: 'user-manager' },
        React.createElement("div", { className: "role-count" },
            React.createElement(UserTypeCount, null)),
        React.createElement("div", { className: 'user-cont' },
            React.createElement("div", { className: 'search-btn' },
                React.createElement("div", { className: 'btn' },
                    React.createElement("div", { className: 'btn-new mr50', onClick: function () { return gotoNew('add'); } }, "+ \u65B0\u5EFA"),
                    React.createElement("div", { className: ' mr50' },
                        "\u89D2\u8272\uFF1A",
                        React.createElement(Select, { placeholder: '\u8BF7\u9009\u62E9', style: { width: 120 }, onChange: function (value) { return handleChange(value, 'roleId'); }, options: roleList, allowClear: true, fieldNames: { label: 'roleName', value: 'roleId' } })),
                    React.createElement("div", { className: ' mr50' },
                        "\u6765\u6E90\uFF1A",
                        React.createElement(Select, { placeholder: '\u8BF7\u9009\u62E9', style: { width: 120 }, onChange: function (value) { return handleChange(value, 'source'); }, options: sourceList, allowClear: true })),
                    React.createElement("div", null,
                        "\u72B6\u6001\uFF1A",
                        React.createElement(Select, { placeholder: '\u8BF7\u9009\u62E9', style: { width: 120 }, onChange: function (value) { return handleChange(value, 'status'); }, options: statusList, allowClear: true }))),
                React.createElement("div", { className: 'search' },
                    React.createElement("div", null, "\u641C\u7D22\uFF1A"),
                    React.createElement(Input, { size: "small", placeholder: "\u8BF7\u8F93\u5165\u7528\u6237\u540D\u6216\u8054\u7CFB\u65B9\u5F0F", onPressEnter: onPressEnter, allowClear: true, onChange: function (e) { onSearchChange(e); } }))),
            React.createElement("div", { className: 'cont-table mt20' },
                React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: 1300 }, pagination: false, rowKey: 'userId', rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; }, onChange: handleTableChange }),
                React.createElement("div", { className: 'cont-pagination mt20' },
                    React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange }))))));
}));
export default UserManager;
