var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Input, Table, Pagination, Popconfirm, Select, message, } from "antd";
import React, { useEffect, useState } from "react";
import EditModal from "./components/EditModal";
import PreviewModal from "./components/PreviewModal";
import { REPORT } from "../../../api/api";
import { get, post } from "../../../utils/axios";
import "./index.scss";
import { inject, observer } from "mobx-react";
var permission = {
    PROBATION: "试读",
    PURCHASE: "购买",
};
var reportType = {
    PRODUCTION_REPORT: "产研报告",
    INTELLIGENT_REPORT: "智能报告",
};
var reportStatus = {
    PUT_AWAY: "已上架",
    WAIT_PUT_AWAY: "待上架",
};
var ReportManager = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength;
    var _a = useState({}), searchParams = _a[0], setSearchParams = _a[1];
    var _b = useState([]), reportTypeList = _b[0], setreportTypeList = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(10), total = _d[0], setTotal = _d[1];
    var _e = useState(10), pageSize = _e[0], setPageSize = _e[1];
    var _f = useState(1), current = _f[0], setCurrent = _f[1];
    var _g = useState(false), isShowModal = _g[0], setIsShowModal = _g[1];
    var _h = useState(""), modalType = _h[0], setModalType = _h[1];
    var _j = useState(""), reportId = _j[0], setReportId = _j[1];
    var _k = useState(false), isShowPreview = _k[0], setIsShowPreview = _k[1];
    var _l = useState(), previewId = _l[0], setPreviewId = _l[1];
    var columns = [
        {
            title: "报告名称",
            dataIndex: "title",
            key: "title",
            className: "reportName",
            ellipsis: true,
        },
        {
            title: "报告类型",
            dataIndex: "type",
            key: "type",
            ellipsis: true,
            render: function (value) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "describe", title: reportType[value] }, reportType[value]))); },
        },
        {
            title: "标签",
            dataIndex: "tagList",
            key: "tagList",
            align: "center",
            ellipsis: true,
            render: function (value) {
                var tags = value.slice(0, 3);
                var rederDiv = tags.map(function (item, index) {
                    return (React.createElement("div", { className: "tag-div", title: item, key: index }, item));
                });
                return rederDiv;
            },
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            render: function (value) { return (React.createElement(React.Fragment, null, reportStatus[value] === "已上架" ? (React.createElement("div", { className: "activeStatus" }, "\u5DF2\u4E0A\u67B6")) : (React.createElement("div", { className: "inactiveStatus" }, "\u5F85\u4E0A\u67B6")))); },
        },
        {
            title: "权限",
            dataIndex: "authority",
            key: "authority",
            ellipsis: true,
            render: function (value) {
                // const obj1:any = new Map([
                //   ['PROBATION','试读'],
                //   ['PURCHASE','购买']
                // ])
                // console.log(obj1[0],'ASDASDADSA');
                return React.createElement("div", { className: "describe" }, permission[value]);
            },
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            sorter: function (a, b) {
                return new Date(a.createTime).getTime() - new Date(b.createTime).getTime();
            },
            ellipsis: true,
        },
        {
            title: "操作",
            key: "handle",
            dataIndex: "handle",
            fixed: "right",
            ellipsis: true,
            width: 200,
            render: function (value, record) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { className: "mr14", onClick: function () { return gotoNew("check", record.id); } }, "\u67E5\u770B"),
                reportStatus[record.status] === "待上架" ? (React.createElement("a", { className: "mr14", onClick: function () { return onlineReport(record.id); } }, "\u4E0A\u67B6")) : (React.createElement(Popconfirm, { title: "\u6267\u884C\u4E0B\u67B6\u64CD\u4F5C\u540E\uFF0C\u8BE5\u6570\u636E\u5C06\u5728\u7528\u6237\u9875\u9762\u5220\u9664\uFF0C\u662F\u5426\u7EE7\u7EED", onConfirm: function () { return offlineReport(record.id); }, okText: "\u662F", cancelText: "\u5426" },
                    React.createElement("a", { className: "mr14" }, "\u4E0B\u67B6"))),
                React.createElement("a", { className: "mr14 ".concat(reportStatus[record.status] === "待上架" ? "" : "disabled-edit"), onClick: function () { return gotoNew("edit", record.id); } }, "\u7F16\u8F91"),
                React.createElement("a", { className: "mr14", onClick: function () { return gotoPreview(record.id); } }, "\u9884\u89C8"),
                reportStatus[record.status] === "待上架" ? (React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u786E\u8BA4\u5220\u9664\uFF1F", onConfirm: function () { return deleteData(record.id); }, okText: "\u662F", cancelText: "\u5426" },
                    React.createElement("a", { className: "delete" }, "\u5220\u9664"))) : (React.createElement("span", { title: "\u505C\u7528\u540E\u53EF\u5220\u9664", className: "disabled-delete" }, "\u5220\u9664")))); },
        },
    ];
    var onChange = function (pageNumber) {
        setCurrent(pageNumber);
    };
    var onShowSizeChange = function (current, size) {
        setPageSize(size);
        setCurrent(current);
    };
    var gotoNew = function (type, roleId) {
        setReportId(roleId);
        setModalType(type);
        setIsShowModal(true);
    };
    var gotoCheck = function (type, id) {
        console.log(id, "22222211111");
        setReportId(id);
        setModalType(type);
        setIsShowModal(true);
    };
    var gotoPreview = function (id) {
        setIsShowPreview(true);
        setPreviewId(id);
    };
    var onlineReport = function (value) {
        get("".concat(REPORT.onlineReport, "/").concat(value)).then(function (res) {
            if (res.state === 200) {
                message.success("上架成功");
                getData();
            }
        });
    };
    var offlineReport = function (value) {
        get("".concat(REPORT.offlineReport, "/").concat(value)).then(function (res) {
            if (res.state === 200) {
                message.success("下架成功");
                getData();
            }
        });
    };
    var handleChange = function (value, type) {
        var _a;
        if (type === "type") {
            if (value === "产研报告") {
                value = "PRODUCTION_REPORT";
            }
            else if (value === "智能报告") {
                value = "INTELLIGENT_REPORT";
            }
            else {
                value = null;
            }
        }
        else if (type === "authority") {
            if (value === "试读") {
                value = "PROBATION";
            }
            else if (value === "购买") {
                value = "PURCHASE";
            }
            else {
                value = null;
            }
        }
        setSearchParams(__assign(__assign({}, searchParams), (_a = {}, _a[type] = value, _a)));
    };
    var onPressEnter = function (e) {
        var element = e.target;
        var value = element.value && element.value.replace(" ", "");
        setSearchParams(function (preState) { return (__assign(__assign({}, preState), { search: value })); });
    };
    var onSearchChange = function (e) {
        console.log("e.target.value", e.target.value);
        if (e.target.value === "") {
            setSearchParams(__assign(__assign({}, searchParams), { search: "" }));
        }
    };
    var deleteData = function (value) {
        console.log(value, "sdsadsa1333333");
        get("".concat(REPORT.deleteReport, "/").concat(value)).then(function (res) {
            if (res.state === 200) {
                message.success("删除成功");
                getData();
            }
        });
    };
    var getData = function () {
        var params = __assign(__assign({}, searchParams), { current: current, 
            // flag: 1,
            pageSize: pageSize });
        post(REPORT.pagenationReport, params).then(function (res) {
            if (res.state === 200) {
                var _a = res === null || res === void 0 ? void 0 : res.results, _b = _a.list, list = _b === void 0 ? [] : _b, _c = _a.pagination, pagination = _c === void 0 ? {} : _c;
                var current_1 = pagination.current, pageSize_1 = pagination.pageSize, total_1 = pagination.total;
                setPageSize(pageSize_1);
                setCurrent(current_1);
                setTotal(total_1);
                setData(list);
            }
        });
    };
    useEffect(function () {
        // setSearchParams([
        //   current: current,
        //   deleted: true,
        //   flag: 1,
        //   pageSize: 10,
        // ])
        getData();
    }, [current, searchParams, pageSize]);
    return (React.createElement("div", { className: "report-manager" },
        React.createElement("div", { className: "report-cont" },
            React.createElement("div", { className: "search-btn" },
                React.createElement("div", { className: "btn" },
                    React.createElement("div", { className: "btn-new mr50", onClick: function () { return gotoNew("add"); } }, "+ \u65B0\u5EFA"),
                    React.createElement("div", { className: " mr50" },
                        "\u62A5\u544A\u7C7B\u578B\uFF1A",
                        React.createElement(Select, { defaultValue: "\u5168\u90E8", style: { width: 120 }, onChange: function (value) { return handleChange(value, "type"); }, options: [
                                { value: "产研报告", label: "产研报告" },
                                { value: "智能报告", label: "智能报告" },
                                { value: "全部", label: "全部" },
                            ] })),
                    React.createElement("div", { className: " mr50" },
                        "\u6743\u9650\uFF1A",
                        React.createElement(Select, { defaultValue: "\u5168\u90E8", style: { width: 120 }, onChange: function (value) { return handleChange(value, "authority"); }, options: [
                                { value: "试读", label: "试读" },
                                { value: "购买", label: "购买" },
                                { value: "全部", label: "全部" },
                            ], allowClear: false })),
                    React.createElement("div", { className: "search" },
                        React.createElement("div", null, "\u641C\u7D22\uFF1A"),
                        React.createElement(Input, { size: "small", placeholder: "\u8F93\u5165\u6807\u9898\uFF0C\u6807\u7B7E", onPressEnter: onPressEnter, allowClear: true, maxLength: inputMaxLength, onChange: function (e) {
                                onSearchChange(e);
                            } })))),
            React.createElement("div", { className: "cont-table mt20" },
                React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
                React.createElement("div", { className: "cont-pagination mt20" },
                    React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange })))),
        isShowModal && (React.createElement(EditModal, { modalType: modalType, reportId: reportId, setIsShowModal: setIsShowModal, getData: getData })),
        isShowPreview && (React.createElement(PreviewModal, { setIsShowPreview: setIsShowPreview, id: previewId }))));
}));
export default ReportManager;
