var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Pagination, Popconfirm, Select, Table, message, } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postForm, postJson } from "../../../utils/axios";
import { AREA_MANGE, SMARTREPORT } from "../../../api/api";
import { getOperatorName } from "../../../utils/lib";
var PrimeIndustry = inject("staticStore", "appStore")(observer(function (props) {
    var staticStore = props.staticStore, appStore = props.appStore;
    var tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination, inputMaxLength = staticStore.inputMaxLength;
    var currentPath = appStore.currentPath;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(""), id = _c[0], setId = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var _e = useState({
        orgId: null,
    }), search = _e[0], setSearch = _e[1];
    var navigate = useNavigate();
    var columns = [
        {
            title: "地域",
            dataIndex: "regionAlias",
            key: "regionAlias",
            ellipsis: true,
        },
        {
            title: "报告名称",
            dataIndex: "title",
            key: "title",
            ellipsis: true,
        },
        {
            title: "报告文档",
            dataIndex: "originalFileName",
            key: "originalFileName",
            ellipsis: true,
            render: function (value, record) { return React.createElement("a", { onClick: function () { return viewPdf(record); } }, value); }
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 180,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                getOperatorName("edit") && (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoAddPage("edit", r.id); } }, getOperatorName("edit", "name"))),
                getOperatorName("update") && (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return updateOrDown("update", r.id); } }, getOperatorName("update", "name"))),
                getOperatorName("down") && (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return updateOrDown("down", r.id); } }, getOperatorName("down", "name"))),
                React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u8BF7\u786E\u8BA4\u540E\u5220\u9664\uFF01", onConfirm: function () { return deleteData(r.reportId); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" }, getOperatorName("delete") && (React.createElement("a", { href: "javascript:;", className: "mr14 table_delete" }, getOperatorName("delete", "name")))))); },
        },
    ];
    var deleteData = function (reportId) {
        var params = {
            reportId: reportId,
        };
        get("".concat(SMARTREPORT.deleteById, "/").concat(reportId), params).then(function (res) {
            var _a, _b;
            if ((_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.success) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
            else {
                message.error((_b = res === null || res === void 0 ? void 0 : res.results) === null || _b === void 0 ? void 0 : _b.message);
            }
        });
    };
    var viewPdf = function (record) {
        window.open(record.resourceUrl, '_blank');
    };
    var updateOrDown = function (type, id) {
        var params = {
            id: id,
        };
        var url = type === 'down' ? SMARTREPORT.downWayById : SMARTREPORT.putAwayById;
        var messageStr = type === 'down' ? '下架成功' : '上架成功';
        postForm("".concat(url, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success(messageStr);
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var getData = function (cur, size) {
        // const params: any = {
        //   current: cur,
        //   pageSize: size,
        //   ...search,
        //   flag: 1,
        //   search: "",
        //   // status: "",
        // };
        // console.log("params", params);
        // postJson(PRIMEINDUSTRY.list, params).then((res: any) => {
        //   if (res) {
        //     const { list, pagination } = res?.results || {};
        //     setData(list);
        //     setPagination(pagination);
        //   } else {
        //     setData([]);
        //     setPagination(defaultPagination);
        //   }
        // });
        // -------------------新
        var params = __assign(__assign({ current: cur, pageSize: size }, search), { flag: 1, search: "" });
        console.log("params", params);
        postJson(SMARTREPORT.pageSearch, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var gotoAddPage = function (type, id) {
        switch (type) {
            case "add":
                navigate("".concat(currentPath, "/add"));
                break;
            case "edit":
                navigate("".concat(currentPath, "/edit/").concat(id));
                break;
        }
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: "SCREEN" }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.orgId]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement("div", { className: "table_list" },
        React.createElement("div", { className: "table_list_header" },
            getOperatorName("add") && (React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return gotoAddPage("add"); } }, getOperatorName("add", "name"))),
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u5730\u57DF\uFF1A"),
                React.createElement(Select, { value: search.orgId, style: { width: tableSearchWidth }, allowClear: true, showSearch: true, options: areaList, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { orgId: v })); });
                    }, placeholder: "\u8BF7\u9009\u62E9", filterOption: function (input, option) {
                        var _a;
                        return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }, fieldNames: {
                        label: "regionName",
                        value: "orgId",
                    } }))),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) {
                    return index % 2 ? "old_line" : "even_line";
                } }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, current: pagination.current, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } })))));
}));
export default PrimeIndustry;
