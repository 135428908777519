var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Form, message, DatePicker, Select, Switch, } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postJson } from "../../../utils/axios";
import { AREA_MANGE, USER, COMMON } from "../../../api/api";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
var AreaMangeFreeLogin = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var routes = location.hash.split('/') || [];
    var orgId = routes[routes.length - 1];
    var _a = useState(''), freeLoginAddress = _a[0], setFreeLoginAddress = _a[1];
    var _b = useState([]), userList = _b[0], setUserList = _b[1];
    var _c = useState({
        status: false
    }), detailData = _c[0], setDetailData = _c[1];
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var navigateBack = function () {
        navigate('/regionConfig/areaMange');
    };
    var getDetails = function () {
        var params = {
            orgId: orgId
        };
        get("".concat(COMMON.getFreeAddressByOrgId, "/").concat(orgId)).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                setDetailData(results);
                form.setFieldsValue(__assign(__assign({}, results), { expiresDate: moment(results.expiresDate) }));
                setFreeLoginAddress((results === null || results === void 0 ? void 0 : results.freeAddress) || '');
            }
        });
        // -------------mock
        // let results =  {
        //   "id": 54,
        //   "sysId": "9ee63a9a1e154e6dbc43864e25f2a7ac",
        //   "userId": "e01f6f0f887c48c1b7a3153dbf90501c",
        //   "freeAddress": "https://snz22x-admin.kf315.net/#/free/ONCu0loq",
        //   "whiteList": "",
        //   "expiresDate": '2024-02-24',
        //   "status": 1,
        //   "deleted": 0
        // }
        // setDetailData(results)
        // form.setFieldsValue({
        //   ...results,
        //   expiresDate:moment(results.expiresDate)
        // })
        // setFreeLoginAddress(results?.freeAddress || '')
    };
    var getUsersList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        orgId: orgId
                    };
                    return [4 /*yield*/, get(USER.getUserInfoByOrgId, params).then(function (res) {
                            if (res) {
                                var list = (res === null || res === void 0 ? void 0 : res.results) || [];
                                setUserList(list);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getFreeLoginAddress = function (status) {
        if (!detailData.userId || !detailData.expiresDate) {
            return;
        }
        var params = __assign(__assign({}, detailData), { expiresDate: "".concat(detailData.expiresDate, " 00:00:00"), status: status, orgId: orgId });
        delete params.bbb;
        console.log('params', params);
        postJson(COMMON.generateDataScreenFreeLoginUrl, params).then(function (res) {
            var _a;
            if (res) {
                message.success('免密链接生成成功');
                setFreeLoginAddress(((_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.freeAddress) || '');
            }
        });
    };
    var changeStatus = function () {
        get("".concat(AREA_MANGE.enableAndDisable, "/").concat(orgId)).then(function (res) {
        });
    };
    useEffect(function () {
        var asyncFunc = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getUsersList()];
                    case 1:
                        _a.sent();
                        getDetails();
                        return [2 /*return*/];
                }
            });
        }); };
        asyncFunc();
    }, []);
    return (React.createElement("div", { className: "addOrEditPage" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 2 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Form.Item, { label: "\u514D\u767B\u94FE\u63A5\uFF1A", name: "status" },
                    React.createElement(Switch, { onChange: function (checked) { setDetailData(function (pre) { return (__assign(__assign({}, pre), { status: checked })); }); changeStatus(); }, checked: detailData.status })),
                React.createElement(Form.Item, { label: "\u514D\u767B\u6388\u6743\u7528\u6237\uFF1A", name: "userId" },
                    React.createElement(Select
                    // mode="multiple"
                    , { 
                        // mode="multiple"
                        showSearch: true, placeholder: "\u8BF7\u7ED1\u5B9A\u7528\u6237", optionFilterProp: "children", value: detailData.userId, 
                        // onChange={onSelectChange}
                        onChange: function (value, options) { return setDetailData(function (pre) { return (__assign(__assign({}, pre), { userId: value })); }); }, 
                        // onSearch={onSelectSearch}
                        style: { width: inputWidth }, filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.userName) !== null && _a !== void 0 ? _a : '').toLowerCase().includes(input.toLowerCase()); }, disabled: !detailData.status, options: userList, fieldNames: {
                            label: 'userName',
                            value: 'userId',
                        } })),
                React.createElement(Form.Item, { label: "\u6709\u6548\u671F\u81F3\uFF1A", name: "expiresDate" },
                    React.createElement(DatePicker, { value: moment(detailData.expiresDate || '', 'YYYY-MM-DD'), disabled: !detailData.status, onChange: function (date, dateString) { return setDetailData(function (pre) { return (__assign(__assign({}, pre), { expiresDate: dateString })); }); }, style: { width: inputWidth } })),
                React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return getFreeLoginAddress(1); }, disabled: (!detailData.status) || (!detailData.userId) || (!detailData.expiresDate) }, "\u751F\u6210\u514D\u767B\u5730\u5740"),
                React.createElement(Form.Item, { label: " ", name: "bbb", colon: false }, detailData.status ? React.createElement(React.Fragment, null,
                    React.createElement("span", null, freeLoginAddress || ''),
                    React.createElement(CopyToClipboard, { text: freeLoginAddress || '', onCopy: function () { return message.success('复制成功'); } },
                        React.createElement("a", { style: { marginLeft: 20 } }, "\u590D\u5236\u94FE\u63A5"))) : null))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: function () { return navigateBack(); } }, "\u5173\u95ED"))));
}));
export default AreaMangeFreeLogin;
