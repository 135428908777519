import React, { useEffect, useState } from 'react';
import { Table, Tabs, Popconfirm, Pagination, message } from 'antd';
import { inject, observer } from "mobx-react";
import EditModal from './components/EditModal';
import { get, postForm } from '../../../utils/axios';
import { COMMON, MENU } from '../../../api/api';
import defaultIcon from '../../../styles/images/menu/defaultIcon.png';
import './index.scss';
var MenuManager = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var menuTypes = staticStore.menuTypes;
    var _a = useState(false), isShowModal = _a[0], setIsShowModal = _a[1];
    var _b = useState(''), modalType = _b[0], setModalType = _b[1];
    var _c = useState(''), activeKey = _c[0], setActiveKey = _c[1];
    var _d = useState(10), total = _d[0], setTotal = _d[1];
    var _e = useState(10), pageSize = _e[0], setPageSize = _e[1];
    var _f = useState(1), current = _f[0], setCurrent = _f[1];
    var _g = useState(''), menuId = _g[0], setMenuId = _g[1];
    var _h = useState([]), tabData = _h[0], setTabData = _h[1];
    var _j = useState([]), data = _j[0], setData = _j[1];
    var columns = [
        {
            title: '菜单名称',
            dataIndex: 'menuName',
            key: 'menuName',
            ellipsis: true,
        },
        {
            title: 'icon',
            dataIndex: 'icon',
            key: 'icon',
            ellipsis: true,
            render: function (v, record) { return record.menuType !== 2 ? React.createElement("img", { className: 'table-icon', src: v || defaultIcon }) : null; }
        },
        { title: '菜单URL', dataIndex: 'funUri', ellipsis: true, key: 'funUri' },
        {
            title: '类型',
            dataIndex: 'menuType',
            key: 'menuType',
            ellipsis: true,
            render: function (v) {
                var _a;
                return React.createElement(React.Fragment, null, (_a = menuTypes[v]) === null || _a === void 0 ? void 0 : _a.label);
            }
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
            sorter: function (a, b) { return a.sort - b.sort; },
            ellipsis: true,
            render: function (v) {
                return React.createElement(React.Fragment, null, v === -1 ? '-' : v);
            }
        },
        {
            title: '状态', dataIndex: 'status', key: 'status', ellipsis: true, render: function (value) { return React.createElement(React.Fragment, null, !value ? React.createElement("div", { className: 'status-cont' },
                React.createElement("span", { className: 'point stop' }),
                React.createElement("span", { className: 'stop-text' }, "\u505C\u7528"))
                : React.createElement("div", { className: 'status-cont' },
                    React.createElement("span", { className: 'point start' }),
                    React.createElement("span", { className: 'open-text' }, "\u542F\u7528"))); }, filters: [
                {
                    text: '启用',
                    value: 1,
                },
                {
                    text: '停用',
                    value: 0,
                },
            ],
            onFilter: function (value, record) { console.log(record); return record.status === Number(value); },
        },
        {
            title: '操作',
            key: 'menuId',
            dataIndex: 'menuId',
            fixed: 'right',
            width: 160,
            render: function (value, record) { return React.createElement(React.Fragment, null,
                React.createElement("a", { className: 'mr14', onClick: function () { return gotoNew('edit', value); } }, "\u7F16\u8F91"),
                React.createElement(Popconfirm, { title: "\u786E\u8BA4".concat(!record.status ? '启用' : '停用', "\u8BE5\u83DC\u5355"), onConfirm: function () { return startStop(value, record.status); }, okText: "\u662F", cancelText: "\u5426" },
                    React.createElement("a", { className: 'mr14' }, !record.status ? '启用' : '停用')),
                !record.status ?
                    React.createElement(Popconfirm, { title: '\u786E\u5B9A\u8981\u5220\u5417\uFF1F\u786E\u5B9A\u5417\uFF1F', onConfirm: function () { return deleteData(value); }, okText: "\u662F", cancelText: "\u5426" },
                        React.createElement("a", { className: 'delete' }, "\u5220\u9664"))
                    : React.createElement("span", { title: "\u505C\u7528\u540E\u53EF\u5220\u9664", className: 'disabled-delete' }, "\u5220\u9664")); },
        },
    ];
    var deleteData = function (value) {
        console.log('value', value);
        var params = {
            menuId: value
        };
        postForm(MENU.del, params).then(function (res) {
            if (res.state === 200) {
                message.success('删除成功');
                getData();
            }
        });
    };
    var startStop = function (menuId, status) {
        var params = {
            menuId: menuId
        };
        postForm(MENU.operate, params).then(function (res) {
            if (res.state === 200) {
                message.success("".concat(!status ? '启用' : '停用', "\u6210\u529F"));
                getData();
            }
        });
    };
    var gotoNew = function (type, menuId) {
        setMenuId(menuId);
        setModalType(type);
        setIsShowModal(true);
    };
    var onTabChange = function (key) {
        clearData();
        setActiveKey(key);
    };
    var onChange = function (pageNumber) {
        console.log('Page: ', pageNumber);
        setCurrent(pageNumber);
    };
    var onShowSizeChange = function (current, size) {
        setPageSize(size);
        setCurrent(current);
    };
    var clearData = function () {
        setPageSize(10);
        setCurrent(1);
        setTotal(0);
        setData([]);
    };
    var getData = function () {
        var params = {
            current: current,
            pageSize: pageSize,
            module: activeKey
        };
        get(MENU.list, params).then(function (res) {
            var _a = res === null || res === void 0 ? void 0 : res.results, list = _a.list, pagination = _a.pagination;
            var current = pagination.current, pageSize = pagination.pageSize, total = pagination.total;
            list.forEach(function (item) {
                filterEmptyChild(item);
            });
            setPageSize(pageSize);
            setCurrent(current);
            setTotal(total);
            setData(list);
        });
    };
    var filterEmptyChild = function (item) {
        if (item.children.length === 0) {
            delete item.children;
            return;
        }
        else {
            item.children.forEach(function (item1) {
                filterEmptyChild(item1);
            });
        }
    };
    var getMenuTypes = function () {
        get(COMMON.getList, { type: 5 }).then(function (res) {
            var _a;
            if (res) {
                var firstKey = ((res === null || res === void 0 ? void 0 : res.results) || []).length > 0 ? (_a = res === null || res === void 0 ? void 0 : res.results[0]) === null || _a === void 0 ? void 0 : _a.key : '';
                console.log('firstKey', firstKey);
                setActiveKey(firstKey);
                setTabData(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({
                    label: item.name,
                    key: item.key,
                }); }));
            }
        });
    };
    useEffect(function () {
        if (activeKey) {
            getData();
        }
    }, [current, pageSize, activeKey]);
    useEffect(function () {
        getMenuTypes();
    }, []);
    return (React.createElement("div", { className: 'menu-manager' },
        React.createElement("div", { className: 'menu-types' },
            React.createElement(Tabs, { activeKey: activeKey, 
                // type="card"
                items: tabData, onChange: onTabChange })),
        React.createElement("div", { className: 'btn-new mt20 mb20', onClick: function () { return gotoNew('add'); } }, "+ \u65B0\u5EFA"),
        isShowModal && React.createElement(EditModal, { modalType: modalType, activeKey: activeKey, getData: getData, setIsShowModal: setIsShowModal, menuId: menuId }),
        React.createElement(Table, { columns: columns, dataSource: data, pagination: false, rowKey: 'menuId', rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
        !!total && React.createElement("div", { className: 'cont-pagination mt20' },
            React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, showSizeChanger: true, onChange: onChange, onShowSizeChange: onShowSizeChange }))));
}));
export default MenuManager;
