import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
export default function TreeSelect(props) {
    var activityKey = props.activityKey, disabled = props.disabled, sendCheckedKeys = props.sendCheckedKeys, choosedKeys = props.choosedKeys, menuOption = props.menuOption, checkStrictly = props.checkStrictly, _a = props.isCheckParent, isCheckParent = _a === void 0 ? true : _a;
    var _b = useState([]), expandedKeys = _b[0], setExpandedKeys = _b[1];
    var _c = useState(choosedKeys), checkedKeys = _c[0], setCheckedKeys = _c[1];
    var _d = useState([]), selectedKeys = _d[0], setSelectedKeys = _d[1];
    var _e = useState(true), autoExpandParent = _e[0], setAutoExpandParent = _e[1];
    var _f = useState([]), treeData = _f[0], setTreeData = _f[1];
    // const [menuOption, setMenuOption] = useState([])
    var onExpand = function (expandedKeysValue) {
        console.log('onExpand', expandedKeysValue);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    var onCheck = function (checkedKeysValue, e) {
        var parentMenuIds;
        var checkedKeys;
        if (isCheckParent) {
            parentMenuIds = e.checkedNodes.map(function (item) { return item.parentMenuId; });
            checkedKeys = Array.from(new Set(checkedKeysValue.concat(parentMenuIds)));
        }
        else {
            checkedKeys = Array.from(new Set(checkedKeysValue === null || checkedKeysValue === void 0 ? void 0 : checkedKeysValue.checked));
        }
        checkedKeys.forEach(function (item, index) {
            if (item === '') {
                checkedKeys.splice(index, 1);
            }
        });
        setCheckedKeys(checkedKeysValue);
        sendCheckedKeys(checkedKeys);
    };
    var onSelect = function (checkedKeysValue, e) {
        var _a;
        console.log('onCheck2', checkedKeysValue);
        console.log('e2', e);
        var parentMenuId = (_a = e.checkedNodes[0]) === null || _a === void 0 ? void 0 : _a.parentMenuId;
        console.log('parentMenuId', parentMenuId);
        setCheckedKeys(checkedKeysValue);
        sendCheckedKeys(checkedKeysValue);
    };
    useEffect(function () {
        setCheckedKeys(choosedKeys);
    }, [choosedKeys]);
    // useEffect(() => {
    //   getMenuList()
    // }, [])
    return (React.createElement(Tree, { selectable: false, checkable: true, onExpand: onExpand, expandedKeys: expandedKeys, autoExpandParent: autoExpandParent, onCheck: onCheck, 
        // onSelect={onSelect}
        checkedKeys: checkedKeys, treeData: menuOption, disabled: disabled, checkStrictly: checkStrictly, fieldNames: { title: 'menuName', key: 'menuId', children: 'children' } }));
}
