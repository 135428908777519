/**
 * sendTags()：返回选择的tag
 * validateInput()：校验输入的内容是否符合业务要求
 * isCheck:<boolean>: 是否只查看
 * value:string[]: 标签值
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PlusOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
var MyTag = function (props) {
    var sendTags = props.sendTags, validateInput = props.validateInput, isCheck = props.isCheck, value = props.value;
    var _a = useState(value), tags = _a[0], setTags = _a[1];
    var _b = useState(false), inputVisible = _b[0], setInputVisible = _b[1];
    var _c = useState(""), inputValue = _c[0], setInputValue = _c[1];
    var inputRef = useRef(null);
    var _d = useState(true), isFirstShow = _d[0], setIsFirstShow = _d[1];
    useEffect(function () {
        var _a;
        if (inputVisible) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    var handleClose = function (removedTag) {
        var newTags = tags.filter(function (tag) { return tag !== removedTag; });
        console.log(newTags);
        setTags(newTags);
    };
    var showInput = function () {
        setInputVisible(true);
    };
    var handleInputChange = function (e) {
        console.log("e.target.value", e.target.value);
        var validateRes = validateInput(e.target.value);
        console.log("validateRes", validateRes);
        // validateRes && 
        setInputValue(e.target.value);
    };
    var handleInputConfirm = function () {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags(__spreadArray(__spreadArray([], tags, true), [inputValue], false));
        }
        setInputVisible(false);
        setInputValue("");
    };
    var forMap = function (tag) {
        var tagElem = (React.createElement(Tag, { closable: true, onClose: function (e) {
                e.preventDefault();
                handleClose(tag);
            } }, tag));
        return (React.createElement("span", { key: tag, style: { display: "inline-block" } }, isCheck ? React.createElement(Tag, null, tag) : tagElem));
    };
    // const checkTagsTotal= ()=>{
    //   if(tags.length>5) return Promise.reject('sssssss')
    // }
    var tagChild = tags.map(forMap);
    useEffect(function () {
        if (!isFirstShow) {
            sendTags(tags);
        }
        else {
            setIsFirstShow(false);
        }
        // checkTagsTotal()
    }, [tags]);
    useEffect(function () {
        setTags(value);
    }, [value]);
    return (React.createElement("div", { className: "my-tag" },
        React.createElement("div", null,
            React.createElement(TweenOneGroup, { enter: {
                    scale: 0.8,
                    opacity: 0,
                    type: "from",
                    duration: 100,
                }, onEnd: function (e) {
                    if (e.type === "appear" || e.type === "enter") {
                        e.target.style = "display: inline-block";
                    }
                }, leave: { opacity: 0, width: 0, scale: 0, duration: 200 }, appear: false }, tagChild)),
        !isCheck && inputVisible && (React.createElement(Input, { ref: inputRef, type: "text", size: "small", style: { width: 100 }, value: inputValue, onChange: handleInputChange, onBlur: handleInputConfirm, onPressEnter: handleInputConfirm, maxLength: 10, autoFocus: true })),
        tags.length < 5 && !isCheck && !inputVisible && (React.createElement(Tag, { onClick: showInput, className: "site-tag-plus" },
            React.createElement(PlusOutlined, null),
            " \u65B0\u6807\u7B7E")),
        tags.length >= 5 && '标签最多可添加5个'));
};
export default MyTag;
