var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Form, Input, Select, message, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postJson } from "../../../utils/axios";
import { AREA_MANGE, SMARTREPORT } from "../../../api/api";
import UploadImg from '../../../components/uploadFile/UploadImg';
import UploadFile from "../../../components/uploadFile/UploadFile";
import moment from "moment";
var typeList = [
    {
        label: '互联网分析报告',
        value: 'INTERNET_ANALYSIS_REPORT'
    },
    {
        label: '互联网报告',
        value: 'INTERNET_REPORT'
    },
    {
        label: '农业产业分析报告',
        value: 'AGRICULTURAL_INDUSTRY_REPORT'
    },
];
var AddOrEditPage = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var _a = useState([]), provinces = _a[0], setProvince = _a[1];
    var isEdit = location.hash.indexOf("edit") > -1;
    var routes = location.hash.split('/') || [];
    var id = routes[routes.length - 1];
    var _b = useState({}), detailData = _b[0], setDetailData = _b[1];
    var _c = useState([]), areaList = _c[0], setAreaList = _c[1];
    var navigateBack = function () {
        navigate('/datav/smartReport');
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: "SCREEN" }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    var getDetail = function () {
        // const params={
        //   id
        // }
        get("".concat(SMARTREPORT.getDetailById, "/").concat(id)).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                // console.log('1111111',{
                //   ...results,
                //   publishTime:moment(results.publishTime || new Date()),
                //   tags:results.tagList.join(',')
                // })
                setDetailData(__assign(__assign({}, results), { tags: results.tagList && results.tagList.join(','), resource: [{ fileId: results.resourceId, fileName: results.originalFileName, url: results.resourceUrl, done: true }] }));
                form.setFieldsValue(__assign(__assign({}, results), { publishTime: moment(results.publishTime || new Date()), tags: results.tagList && results.tagList.join(',') }));
            }
        });
    };
    var getUploadUrl = function (url) {
        setDetailData(function (pre) { return (__assign(__assign({}, pre), { coverImage: url })); });
        form.setFieldValue('coverImage', url);
    };
    var getFileList = function (data) {
        setDetailData(function (pre) {
            return __assign(__assign({}, pre), { resource: data });
        });
        form.setFieldsValue({
            resource: data,
        });
    };
    var onSubmit = function () {
        form.validateFields().then(function (values) {
            console.log('values', values);
            var params = __assign(__assign(__assign({}, detailData), values), { 
                // reportId:(values?.resource && values?.resource[0] || {}).uid,
                resourceUrl: ((values === null || values === void 0 ? void 0 : values.resource) && (values === null || values === void 0 ? void 0 : values.resource[0]) || {}).url, resourceId: ((values === null || values === void 0 ? void 0 : values.resource) && (values === null || values === void 0 ? void 0 : values.resource[0]) || {}).uid, originalFileName: ((values === null || values === void 0 ? void 0 : values.resource) && (values === null || values === void 0 ? void 0 : values.resource[0]) || {}).name, publishTime: "".concat(detailData.publishTime, " 00:00:00"), tags: values.tags ? values.tags.replace('，', ',') : '' });
            delete params.resource;
            postJson(SMARTREPORT.save, params).then(function (res) {
                var _a, _b;
                if (!((_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.success)) {
                    message.error(((_b = res === null || res === void 0 ? void 0 : res.results) === null || _b === void 0 ? void 0 : _b.message) || '');
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    navigateBack();
                }, 1000);
            });
        });
    };
    useEffect(function () {
        getAreaList();
        isEdit && getDetail();
    }, []);
    return (React.createElement("div", { className: "addOrEditPage" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 2 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Form.Item, { label: "\u5730\u57DF\uFF1A", name: "orgId", rules: [{ required: true, message: "请检查选择是否正确！" }] },
                    React.createElement(Select, { style: { width: inputWidth }, allowClear: true, showSearch: true, options: areaList, placeholder: "\u8BF7\u9009\u62E9", filterOption: function (input, option) {
                            var _a;
                            return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }, fieldNames: {
                            label: "regionName",
                            value: "orgId",
                        } })),
                React.createElement(Form.Item, { label: "\u62A5\u544A\u540D\u79F0\uFF1A", name: "title", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })),
                React.createElement(Form.Item, { label: "\u62A5\u544A\u5C01\u9762\uFF1A", name: "coverImage", rules: [{ required: true, message: "请检查上传是否正确" }] },
                    React.createElement(UploadImg, { sendUrl: function (url) { return getUploadUrl(url); }, url: detailData.coverImage, action: "/apiInterface/interface/hydra-opinion-platform/api/v1/manage/intelligentReport/uploadFile", data: { fileType: "COVER" } })),
                React.createElement(Form.Item, { label: "\u62A5\u544A\u6587\u6863\uFF1A", name: "resource", rules: [{ required: true, message: "请检查上传是否正确" }] },
                    React.createElement(UploadFile, { config: { maxCount: 1 }, fileList: (detailData === null || detailData === void 0 ? void 0 : detailData.resource) || [], sendData: getFileList, action: "/apiInterface/interface/hydra-opinion-platform/api/v1/manage/intelligentReport/uploadFile", data: { fileType: "ATTACHMENT" }, fileType: 'pdf' })),
                React.createElement(Form.Item, { label: "\u62A5\u544A\u65E5\u671F\uFF1A", name: "publishTime", rules: [{ required: true, message: "请检查选择是否正确！" }] },
                    React.createElement(DatePicker, { style: { width: inputWidth }, onChange: function (date, dateString) { return setDetailData(function (pre) { return (__assign(__assign({}, pre), { publishTime: dateString })); }); } })),
                React.createElement(Form.Item, { label: "\u6807\u7B7E\uFF1A", name: "tags" },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u591A\u4E2A\u6807\u7B7E\u4F7F\u7528\uFF0C\u8FDB\u884C\u533A\u5206", maxLength: inputMaxLength })))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: function () { return navigateBack(); } }, "\u53D6\u6D88"),
            React.createElement(Button, { type: "primary", onClick: function () { return onSubmit(); } }, "\u786E\u5B9A"))));
}));
export default AddOrEditPage;
