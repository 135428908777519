var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Table, message, } from "antd";
import { inject, observer } from "mobx-react";
import { get } from "../../../utils/axios";
import { AREA_MANGE, FIRST } from "../../../api/api";
import IndustryDataConfigAddModal from "./components/IndustryDataConfigAddModal";
var IndustryDataConfig = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination, inputMaxLength = staticStore.inputMaxLength;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(false), isShowModal = _c[0], setIsShowModal = _c[1];
    var _d = useState(""), modalType = _d[0], setModalType = _d[1];
    var _e = useState(""), id = _e[0], setId = _e[1];
    var _f = useState(defaultPagination), pagination = _f[0], setPagination = _f[1];
    var _g = useState({
        orgId: null,
        industryName: "",
    }), search = _g[0], setSearch = _g[1];
    var routes = location.hash.split("/") || [];
    var orgId = routes[routes.length - 1];
    var columns = [
        {
            title: "年份",
            dataIndex: "date",
            key: "date",
            ellipsis: true,
        },
        {
            title: "数据",
            dataIndex: "data",
            key: "data",
            ellipsis: true,
            render: function (v, r) { return React.createElement("div", null, v.map(function (item) { return "".concat(item.name, "\uFF1A").concat(item.value).concat(item.uint); }).join('，')); }
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 300,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoAddEdit('edit', r.id); } }, "\u7F16\u8F91"),
                React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoDelete(r.id); } }, "\u5220\u9664"))); },
        },
    ];
    var gotoAddEdit = function (type, id) {
        setId(id || '');
        setModalType(type);
        setIsShowModal(true);
    };
    var gotoDelete = function (id) {
        var params = {
            id: id,
        };
        get("".concat(FIRST.deleted), params).then(function (res) {
            if (res) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var getData = function (cur, size) {
        var params = {
            current: cur,
            pageSize: size,
            orgId: orgId
        };
        console.log("params", params);
        get(FIRST.getIndustryDetail, params).then(function (res) {
            if (res) {
                setData((res === null || res === void 0 ? void 0 : res.results) || []);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: 'SCREEN' }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.orgId]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement("div", { className: "table_list" },
        React.createElement("div", { className: "table_list_header" },
            React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return gotoAddEdit("add"); } }, "\u6DFB\u52A0")),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } })),
        isShowModal && (React.createElement(IndustryDataConfigAddModal, { modalType: modalType, id: id, setIsShowModal: setIsShowModal, getData: getData, orgId: orgId }))));
}));
export default IndustryDataConfig;
