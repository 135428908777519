var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Tabs, message, DatePicker, InputNumber, } from "antd";
import { get, postJson } from "../../../../utils/axios";
import { FIRST } from "../../../../api/api";
import "../index.scss";
import { inject, observer } from "mobx-react";
import moment from "moment";
var TextArea = Input.TextArea;
var TabPane = Tabs.TabPane;
var loginName = "admin";
var IndustryDataConfigAddModal = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, id = props.id, getData = props.getData, staticStore = props.staticStore, orgId = props.orgId;
    var inputWidth = staticStore.inputWidth;
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    var _b = useState(false), isAdd = _b[0], setIsAdd = _b[1];
    var _c = useState({}), formObj = _c[0], setFormObj = _c[1];
    var form = Form.useForm()[0];
    var handleOk = function () {
        form
            .validateFields()
            .then(function (values) {
            console.log("values", values);
            var data = [];
            var _loop_1 = function (i) {
                var margin = '', value = '', uint = '';
                Object.keys(values).forEach(function (item) {
                    if (item.indexOf("".concat(i)) > -1) {
                        margin = '0';
                        value = values["value".concat(i)];
                        uint = '元';
                    }
                });
                data[i - 1] = {
                    margin: margin,
                    value: value,
                    uint: uint,
                    name: "\u7B2C".concat(i === 1 ? '一' : i === 2 ? '二' : '三', "\u4EA7\u4E1A")
                };
            };
            for (var i = 1; i < 4; i++) {
                _loop_1(i);
            }
            console.log('data', data);
            var params = {
                data: data,
                date: formObj.date,
                id: id,
                orgId: orgId
            };
            console.log("params", params);
            postJson(FIRST.addIndustry, params).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    setIsShowModal(false);
                    getData();
                }, 1000);
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            return;
        });
    };
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var getDetail = function () {
        var params = {
            id: id
        };
        get("".concat(FIRST.getDetail), params).then(function (res) {
            if (res) {
                var data_1 = (res === null || res === void 0 ? void 0 : res.results).data;
                data_1 = data_1 && JSON.parse(data_1);
                form.setFieldValue('date', moment(data_1.date || new Date(), 'YYYY'));
                setFormObj(function (pre) { return (__assign(__assign({}, pre), { date: data_1.date })); });
                var dataDeep = data_1.data;
                for (var i = 1; i < 4; i++) {
                    // let margin,uint,value=''
                    // form.setFieldValue(`margin${i}`, dataDeep[i-1].margin)
                    // form.setFieldValue(`uint${i}`, dataDeep[i-1].uint)
                    form.setFieldValue("value".concat(i), dataDeep[i - 1].value);
                }
            }
        });
    };
    useEffect(function () {
        switch (modalType) {
            case "add":
                setTitle("添加");
                setIsAdd(true);
                break;
            case "edit":
                setTitle("编辑");
                getDetail();
                break;
            case "check":
                setTitle("查看");
                break;
        }
    }, [modalType]);
    return (React.createElement(Modal, { title: title, open: true, maskClosable: false, footer: false, width: 750, onCancel: handleCancel, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form },
            React.createElement(Form.Item, { label: "\u5E74\u4EFD", name: "date", rules: [{ required: true, message: '请选择' }] },
                React.createElement(DatePicker, { onChange: function (date, dateString) { return setFormObj(function (pre) { return (__assign(__assign({}, pre), { date: dateString })); }); }, picker: "year", style: { width: inputWidth } })),
            React.createElement("h3", null, "\u7B2C\u4E00\u4EA7\u4E1A"),
            React.createElement(Form.Item, { label: "\u91D1\u989D", name: "value1", rules: [{ required: true, message: '请输入' }] },
                React.createElement(InputNumber, { controls: false, precision: 2, style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", formatter: function (value) { return "".concat(value, "\u5143").replace(/\B(?=(\d{3})+(?!\d))/g, ','); } })),
            React.createElement("h3", null, "\u7B2C\u4E8C\u4EA7\u4E1A"),
            React.createElement(Form.Item, { label: "\u91D1\u989D", name: "value2", rules: [{ required: true, message: '请输入' }] },
                React.createElement(InputNumber, { controls: false, precision: 2, style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", formatter: function (value) { return "".concat(value, "\u5143").replace(/\B(?=(\d{3})+(?!\d))/g, ','); } })),
            React.createElement("h3", null, "\u7B2C\u4E09\u4EA7\u4E1A"),
            React.createElement(Form.Item, { label: "\u91D1\u989D", name: "value3", rules: [{ required: true, message: '请输入' }] },
                React.createElement(InputNumber, { controls: false, precision: 2, style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", formatter: function (value) { return "".concat(value, "\u5143").replace(/\B(?=(\d{3})+(?!\d))/g, ','); } }))),
        React.createElement("div", { className: "modal-footer-btn" },
            React.createElement("div", { className: "footer-btn de-active mr10", onClick: handleCancel }, "\u53D6\u6D88"),
            React.createElement("div", { className: "footer-btn active", onClick: handleOk }, "\u786E\u5B9A"))));
}));
export default IndustryDataConfigAddModal;
