var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Input, Pagination, Popconfirm, Select, Table, message } from "antd";
import AddEditModal from "./components/AddEditModal";
import { get, postJson } from "../../../utils/axios";
import { LEADER, AREA_MANGE } from "../../../api/api";
import { inject, observer } from "mobx-react";
import { getOperatorName } from "../../../utils/lib";
var LeaderConfig = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination, inputMaxLength = staticStore.inputMaxLength;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), leaderList = _b[0], setLeaderList = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var _e = useState(false), isShowModal = _e[0], setIsShowModal = _e[1];
    var _f = useState(""), modalType = _f[0], setModalType = _f[1];
    var _g = useState(""), id = _g[0], setId = _g[1];
    var _h = useState({
        orgId: null,
        leaderName: "",
    }), search = _h[0], setSearch = _h[1];
    var columns = [
        {
            title: "地域",
            dataIndex: "orgRegion",
            key: "orgRegion",
            ellipsis: true,
        },
        {
            title: "领导人",
            dataIndex: "leaderDTO",
            key: "leaderDTO",
            ellipsis: true,
            render: function (v) { return v.name; },
        },
        {
            title: "职务",
            dataIndex: "postAbbreviation",
            key: "postAbbreviation",
            ellipsis: true,
            render: function (v, r) { return r.leaderDTO.postAbbreviation; },
        },
        {
            title: "头像",
            dataIndex: "headPicture",
            key: "headPicture",
            ellipsis: true,
            render: function (v, r) { return (React.createElement("img", { src: r.leaderDTO.headPicture, className: "leader_config_table_img" })); },
        },
        {
            title: "顺序",
            dataIndex: "sequence",
            key: "sequence",
            ellipsis: true,
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            render: function (v, r) {
                return v === "IN_USE" ? (React.createElement("span", { className: "light_status" }, "\u4F7F\u7528\u4E2D")) : (React.createElement("span", null, "\u5DF2\u505C\u7528"));
            },
        },
        {
            title: "操作",
            dataIndex: "ggg",
            key: "ggg",
            fixed: "right",
            width: 200,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                getOperatorName('check') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return onAddOrEdit("check", r.id); } }, getOperatorName('check', 'name')),
                r.status === "IN_USE" ? (
                // <a onClick={() => OnOrOff("off",r.id)} href="javascript:;" className="mr14">
                //   下架
                // </a>
                getOperatorName('down') && React.createElement("a", { onClick: function () { return OnOrOff("off", r.id); }, href: "javascript:;", className: "mr14" }, getOperatorName('down', 'name'))) : (
                // <a onClick={() => OnOrOff("on",r.id)} href="javascript:;" className="mr14">
                //   上架
                // </a>
                getOperatorName('up') && React.createElement("a", { onClick: function () { return OnOrOff("on", r.id); }, href: "javascript:;", className: "mr14" }, getOperatorName('up', 'name'))),
                r.status === "IN_USE" ? (
                // <span className="mr14 table_dark_option">编辑</span>
                getOperatorName('edit') && React.createElement("span", { className: "mr14 table_dark_option" }, getOperatorName('edit', 'name'))) : (
                // <a href="javascript:;" className="mr14" onClick={() => onAddOrEdit("edit",r.id)}>
                //   编辑
                // </a>
                getOperatorName('edit') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return onAddOrEdit("edit", r.id); } }, getOperatorName('edit', 'name'))),
                r.status === "IN_USE" ? (
                // <span className="mr14 table_dark_option">删除</span>
                getOperatorName('delete') && React.createElement("span", { className: "mr14 table_dark_option" }, getOperatorName('delete', 'name'))) : (React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u786E\u8BA4\u5220\u9664\uFF1F", onConfirm: function () { return deleteData(r.id); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" }, getOperatorName('delete') && React.createElement("a", { href: "javascript:;", className: "mr14 table_delete" }, getOperatorName('delete', 'name')))))); },
        },
    ];
    var deleteData = function (id) {
        var params = {
            id: id,
        };
        get("".concat(LEADER.deleteById, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var getData = function (cur, size) {
        var params = __assign({ current: cur, flag: 1, pageSize: size, search: "" }, search);
        // {
        //   current: cur,
        //   pageSize: size,
        //   flag: 1,
        //   search: "",
        // };
        console.log("params", params);
        postJson(LEADER.pageSearch, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var OnOrOff = function (type, id) {
        console.log("type", type);
        var params = {
            id: id,
        };
        var url = type === 'on' ? "".concat(LEADER.upShelvesById, "/").concat(id) : "".concat(LEADER.downShelvesById, "/").concat(id);
        get(url, params).then(function (res) {
            if (res) {
                message.success("".concat(type === "on" ? "上架" : "下架", "\u6210\u529F"));
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var onAddOrEdit = function (type, id) {
        setIsShowModal(true);
        setModalType(type);
        setId(id);
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: 'SCREEN' }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.orgId]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement("div", { className: "leader_config" },
        React.createElement("div", { className: "leader_config_header" },
            getOperatorName('add') && React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return onAddOrEdit("add"); } }, getOperatorName('add', 'name')),
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u5730\u57DF\uFF1A"),
                React.createElement(Select, { value: search.orgId, placeholder: "\u8BF7\u9009\u62E9", style: { width: tableSearchWidth }, allowClear: true, showSearch: true, options: areaList, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { orgId: v })); });
                    }, 
                    // onSearch={onSearch}
                    filterOption: function (input, option) {
                        var _a;
                        return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }, fieldNames: {
                        label: 'regionName',
                        value: 'orgId',
                    } })),
            React.createElement("div", null,
                React.createElement("span", null, "\u9886\u5BFC\u4EBA\uFF1A"),
                React.createElement(Input, { value: search.leaderName, placeholder: "\u8BF7\u8F93\u5165", style: { width: tableSearchWidth }, onChange: function (e) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { leaderName: e.target.value })); });
                    }, maxLength: inputMaxLength, allowClear: true, onPressEnter: function () {
                        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
                        getData(1, 10);
                    } }))),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content", }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, current: pagination.current, total: pagination.total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } }))),
        isShowModal && (React.createElement(AddEditModal, { modalType: modalType, id: id, setIsShowModal: setIsShowModal, getData: function () { return getData(1, 10); } }))));
}));
export default LeaderConfig;
