var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Form, Input, InputNumber, message, TreeSelect } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postJson } from "../../../utils/axios";
import { AREA_MANGE, COMMON } from "../../../api/api";
import { validatorRegionalAlias, replaceAll, validatorUrl } from '../../../utils/lib';
var AddOrEditPage = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var _a = useState([]), provinces = _a[0], setProvince = _a[1];
    var isEdit = location.hash.indexOf("edit") > -1;
    var routes = location.hash.split('/') || [];
    var orgId = routes[routes.length - 1];
    var _b = useState({}), detailData = _b[0], setDetailData = _b[1];
    var onSubmit = function () {
        form.validateFields().then(function (values) {
            console.log('values', values);
            var params = __assign(__assign({}, detailData), values);
            console.log('params', params);
            var url = AREA_MANGE.add;
            if (params.orgId) {
                url = AREA_MANGE.update;
            }
            postJson(url, params).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    navigateBack();
                }, 1000);
            });
        });
    };
    var validateRegionName = function (rule, value, callback) {
        if (value === undefined) {
            return Promise.reject('请检查输入是否正确！');
        }
        try {
            var mark = validatorRegionalAlias(value);
            if (mark) {
                return Promise.resolve();
            }
            else {
                return Promise.reject('别名未满足要求，请输入中英文或数字');
            }
        }
        catch (err) {
            callback(err);
        }
    };
    var validateUrl = function (rule, value, callback) {
        if (value === undefined) {
            return Promise.reject('请检查输入是否正确！');
        }
        try {
            var mark = validatorUrl(value);
            if (mark) {
                return Promise.resolve();
            }
            else {
                return Promise.reject('别名未满足要求，请输入中英文或数字');
            }
        }
        catch (err) {
            callback(err);
        }
    };
    var getProvince = function () {
        var params = {
            administrativeType: 'COUNTY'
        };
        get(COMMON.ProvinceCity, params).then(function (res) {
            var _a;
            if (res) {
                var nodeList = ((_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.nodeList) || [];
                var province = formatData(nodeList);
                setProvince(province);
                // let nodeListStr = JSON.stringify(nodeList)
                // nodeListStr = replaceAll(nodeListStr, '000000', '')
                // nodeListStr = replaceAll(nodeListStr, '000000', '')
                // setProvince(JSON.parse(nodeListStr))
            }
        });
    };
    var formatData = function (list) {
        return list.map(function (item) {
            if (item.childCodeNameNodeList) {
                return ({
                    id: replaceAll(item.administrativeCode, '000000', ''),
                    pId: item.parentAdministrativeCode ? replaceAll(item.parentAdministrativeCode, '000000', '') : null,
                    value: replaceAll(item.administrativeCode, '000000', ''),
                    title: item.administrativeName,
                    children: formatData(item.childCodeNameNodeList)
                });
            }
            else {
                return ({
                    id: replaceAll(item.administrativeCode, '000000', ''),
                    pId: item.parentAdministrativeCode ? replaceAll(item.parentAdministrativeCode, '000000', '') : null,
                    value: replaceAll(item.administrativeCode, '000000', ''),
                    title: item.administrativeName,
                });
            }
        });
    };
    var getDetail = function () {
        var params = {
            orgId: orgId
        };
        get(AREA_MANGE.get, params).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                var regionLevel = void 0;
                if (!results.regionLevel) {
                    if (results.regionCode.substring(2) === '0000') {
                        regionLevel = 1;
                    }
                    else if (results.regionCode.substring(4) === '00') {
                        regionLevel = 2;
                    }
                    else {
                        regionLevel = 3;
                    }
                }
                else {
                    regionLevel = results.regionLevel;
                }
                setDetailData(__assign(__assign({}, results), { regionLevel: regionLevel }));
                form.setFieldsValue(results);
            }
        });
    };
    var onTreeChange = function (value, label, extra) {
        var regionLevel;
        if (value.substring(2) === '0000') {
            regionLevel = 1;
        }
        else if (value.substring(4) === '00') {
            regionLevel = 2;
        }
        else {
            regionLevel = 3;
        }
        if (label && label[0]) {
            setDetailData(function (pre) { return (__assign(__assign({}, pre), { regionName: label[0], regionLevel: regionLevel })); });
        }
        else {
            setDetailData(function (pre) { return (__assign(__assign({}, pre), { regionName: '', regionLevel: regionLevel })); });
        }
    };
    var navigateBack = function () {
        navigate('/regionConfig/areaMange');
    };
    useEffect(function () {
        getProvince();
        isEdit && getDetail();
    }, []);
    return (React.createElement("div", { className: "addOrEditPage" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 3 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Form.Item, { label: "\u884C\u653F\u533A\u5212\uFF1A", name: "regionCode", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(TreeSelect, { style: { width: inputWidth }, allowClear: true, treeData: provinces, placeholder: "\u8BF7\u9009\u62E9", showCheckedStrategy: "SHOW_ALL", fieldNames: {
                            label: "title",
                            value: "value",
                            children: 'children'
                        }, onChange: function (value, label, extra) { return onTreeChange(value, label, extra); } })),
                React.createElement(Form.Item, { label: "\u5730\u533A\u522B\u540D\uFF1A", name: "regionAlias", rules: [{ required: true, validator: validateRegionName, message: "请检查输入是否正确！" }] },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165\u5730\u533A\u522B\u540D", maxLength: 10 })),
                React.createElement(Form.Item, { label: "\u57DF\u540D\uFF1A", name: "url", rules: [{ required: true, validator: validateUrl, message: "请检查输入是否正确！" }] },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })),
                React.createElement(Form.Item, { label: "\u7ECF\u5EA6\uFF1A", name: "longitude", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(InputNumber, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })),
                React.createElement(Form.Item, { label: "\u7EAC\u5EA6\uFF1A", name: "latitude", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                    React.createElement(InputNumber, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })),
                React.createElement("h2", null, "\u77E5\u8BC6\u56FE\u8C31\u914D\u7F6E"),
                React.createElement(Form.Item, { label: "\u56FE\u7A7A\u95F4\uFF1A", name: "nebulaSpace" },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })),
                React.createElement(Form.Item, { label: "\u56FE\u7A7A\u95F4\u8FB9\u5173\u7CFB\uFF1A", name: "nebulaEdges" },
                    React.createElement(Input, { style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", maxLength: inputMaxLength })))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: navigateBack }, "\u53D6\u6D88"),
            React.createElement(Button, { type: "primary", onClick: function () { return onSubmit(); } }, "\u786E\u5B9A"))));
}));
export default AddOrEditPage;
