import React, { useEffect, useState } from 'react';
import { getFromES } from '../../../../utils/axios';
import { SITEMANAGER } from '../../../../api/api';
import { formatterNumber } from '../../../../utils/lib';
export default function SiteCount() {
    var _a = useState({
        totalSiteNumber: '',
        todayNumber: '',
        blackNumber: '',
    }), data = _a[0], setData = _a[1];
    var getSiteNumber = function () {
        getFromES(SITEMANAGER.siteNumber).then(function (res) {
            console.log('res', res);
            if (res === null || res === void 0 ? void 0 : res.data) {
                setData((res === null || res === void 0 ? void 0 : res.data) || {});
            }
        });
    };
    useEffect(function () {
        getSiteNumber();
    }, []);
    return (React.createElement("div", { className: 'site-count-cont' },
        React.createElement("div", { className: 'site-count-item' },
            React.createElement("div", { className: 'site-count-item-label' }, "\u7AD9\u70B9\u603B\u6570"),
            React.createElement("div", { className: 'site-count-item-value' },
                formatterNumber(data.totalSiteNumber),
                "\u4E2A")),
        React.createElement("div", { className: 'site-count-item' },
            React.createElement("div", { className: 'site-count-item-label' }, "\u4ECA\u65E5\u65B0\u589E"),
            React.createElement("div", { className: 'site-count-item-value' },
                formatterNumber(data.todayNumber),
                "\u4E2A")),
        React.createElement("div", { className: 'site-count-item' },
            React.createElement("div", { className: 'site-count-item-label' }, "\u9ED1\u540D\u5355\u6570"),
            React.createElement("div", { className: 'site-count-item-value' },
                formatterNumber(data.blackNumber),
                "\u4E2A"))));
}
