// React V17 及以前版本
// import React from 'react'
// import ReactDOM from 'react-dom'
// import App from './App'
// ReactDOM.render(<App />, document.getElementById('root'))
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import App from './App';
import stores from './store/index.js';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'; // 配置语言
import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client'; //更新后的写法
import { LoginRouter } from './routers/router';
import { HashRouter } from "react-router-dom";
var container = document.getElementById('root');
var root = createRoot(container);
root.render(React.createElement(HashRouter, null,
    React.createElement(Provider, __assign({}, stores),
        React.createElement(ConfigProvider, { locale: zhCN },
            React.createElement(App, null),
            React.createElement(LoginRouter, null)))));
// 用hashRouter包裹组件，让子组件可以使用 useNavigate
console.log('%c 版本号：%s', 'color:green;font-size:16px', require('../package.json').version);
