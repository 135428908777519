import React, { useEffect, useState } from "react";
import "./index.scss";
import SiteCount from "./components/SiteCount";
import { Input, message, Pagination, Popconfirm, Table, Modal, } from "antd";
import { getFromES, postJsonFromES } from "../../../utils/axios";
import { SITEMANAGER } from "../../../api/api";
import { inject, observer } from "mobx-react";
import NewSiteModal from "./components/NewSiteModal";
import EditSiteModal from "./components/EditSiteModal";
var Search = Input.Search;
var SiteManager = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(null), status = _b[0], setStatus = _b[1];
    var _c = useState(10), pageSize = _c[0], setPageSize = _c[1];
    var _d = useState(10), total = _d[0], setTotal = _d[1];
    var _e = useState(1), current = _e[0], setCurrent = _e[1];
    var _f = useState(undefined), searchValue = _f[0], setSearchValue = _f[1];
    var _g = useState(false), isShowModal = _g[0], setIsShowModal = _g[1];
    var _h = useState(false), isShowEditModal = _h[0], setIsShowEditModal = _h[1];
    var _j = useState(false), editData = _j[0], setEditData = _j[1];
    var _k = useState([]), urlList = _k[0], setUrlList = _k[1];
    var columns = [
        {
            title: "站点名称",
            dataIndex: "siteName",
            key: "siteName",
            ellipsis: true,
        },
        {
            title: "URL",
            dataIndex: "url",
            key: "url",
            ellipsis: true,
            render: function (value) { return (React.createElement("a", { className: "link-url ellipsis-text", target: "_blank", href: value, title: value }, value)); },
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            filterMultiple: false,
            ellipsis: true,
            filters: [
                {
                    text: "已完成",
                    value: 2,
                },
                {
                    text: "等待中",
                    value: 0,
                },
                {
                    text: "失败",
                    value: 3,
                },
                {
                    text: "执行中",
                    value: 1,
                },
                {
                    text: "全部",
                    value: "",
                },
            ],
            render: function (value) {
                var siteDiv = {};
                switch (value) {
                    case 2:
                        siteDiv = (React.createElement("div", { className: "status-cont" },
                            React.createElement("span", { className: "point start" }),
                            React.createElement("span", null, "\u5DF2\u5B8C\u6210")));
                        break;
                    case 0:
                        siteDiv = (React.createElement("div", { className: "status-cont" },
                            React.createElement("span", { className: "point stop" }),
                            React.createElement("span", null, "\u7B49\u5F85\u4E2D")));
                        break;
                    case 3:
                        siteDiv = (React.createElement("div", { className: "status-cont" },
                            React.createElement("span", { className: "point error" }),
                            React.createElement("span", null, "\u5931\u8D25")));
                        break;
                    case 1:
                        siteDiv = (React.createElement("div", { className: "status-cont" },
                            React.createElement("span", { className: "point running" }),
                            React.createElement("span", null, "\u6267\u884C\u4E2D")));
                        break;
                }
                return siteDiv;
            },
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            sorter: function (a, b) {
                return new Date(a.createTime).getTime() - new Date(b.createTime).getTime();
            },
            ellipsis: true,
        },
        {
            title: "修改时间",
            dataIndex: "updateTime",
            key: "updateTime",
            sorter: function (a, b) {
                return new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime();
            },
            ellipsis: true,
        },
        {
            title: "操作",
            key: "userId",
            dataIndex: "userId",
            fixed: "right",
            width: 200,
            render: function (value, record) { return (React.createElement(React.Fragment, null,
                String(record.status) === "3" ? (React.createElement("a", { className: "mr14", onClick: function () { return onEdit(record); } }, "\u7F16\u8F91")) : (React.createElement("span", { className: "mr14 disabled-edit" }, "\u7F16\u8F91")),
                String(record.status) === "3" || String(record.status) === "2" ? (React.createElement(Popconfirm, { title: "\u786E\u5B9A\u8981\u5220\u5417\uFF1F\u786E\u5B9A\u5417\uFF1F", okText: "\u662F", cancelText: "\u5426", onConfirm: function () { return onDelete(record); } },
                    React.createElement("a", { className: "mr14 delete" }, "\u5220\u9664"))) : (React.createElement("span", { className: "mr14 disabled-edit" }, "\u5220\u9664")),
                String(record.status) === "3" ? (React.createElement("a", { className: "mr14", onClick: function () { return doTry(record); } }, "\u91CD\u8BD5")) : (React.createElement("span", { className: "mr14 disabled-edit" }, "\u91CD\u8BD5")))); },
        },
    ];
    var onEdit = function (item) {
        setEditData(item);
        setIsShowEditModal(true);
    };
    var onDelete = function (item) {
        var params = {
            id: item === null || item === void 0 ? void 0 : item.id,
        };
        getFromES(SITEMANAGER.delete, params).then(function (res) {
            if (res.status === 200) {
                message.success("删除成功");
                getData();
            }
            else {
                message.error(res.msg || "删除失败");
            }
        });
    };
    var doTry = function (item) {
        var params = {
            dataList: [item],
        };
        postJsonFromES(SITEMANAGER.add, params).then(function (res) {
            if (res) {
                message.success("保存成功");
                getData();
            }
        });
    };
    var onChange = function (page, pageSize) {
        setPageSize(pageSize);
        setCurrent(page);
    };
    var onTableChange = function (pagination, filters) {
        if (filters.status === null) {
            setStatus("");
        }
        setStatus(filters === null || filters === void 0 ? void 0 : filters.status[0]);
    };
    var getData = function (search) {
        if (search === void 0) { search = ""; }
        search === "clear" && setSearchValue("");
        var params = {
            current: current,
            pageSize: pageSize,
            searchWord: search === "clear" ? "" : searchValue,
            sortFiled: 0,
            sortType: "",
            status: status === "" ? null : status,
        };
        postJsonFromES(SITEMANAGER.blacklist, params).then(function (res) {
            var _a, _b;
            setData((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.list);
            var total = ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.pagination).total;
            setTotal(total);
        });
    };
    var onNewBtn = function () {
        if (!searchValue) {
            message.warning("请输入正确的内容");
            return;
        }
        var params = {
            word: searchValue,
        };
        getFromES(SITEMANAGER.exist, params).then(function (res) {
            if (res.status === 500) {
                Modal.confirm({
                    content: "该站点已存在，是否立即查看",
                    okText: "查看",
                    cancelText: "取消",
                    onOk: function () { return onSearch(); },
                    onCancel: function () { },
                });
            }
            else if (res.status === 200) {
                if ((res === null || res === void 0 ? void 0 : res.data) && (res === null || res === void 0 ? void 0 : res.data.length)) {
                    setIsShowModal(true);
                    setUrlList(res === null || res === void 0 ? void 0 : res.data);
                }
                else {
                    message.warning('暂无数据');
                }
            }
        });
    };
    var onSearch = function () {
        getData();
    };
    useEffect(function () {
        getData();
    }, [status]);
    useEffect(function () {
        if (searchValue === "") {
            getData("clear");
        }
    }, [searchValue]);
    return (React.createElement("div", { className: "site-manager" },
        React.createElement("div", { className: "site-count" },
            React.createElement(SiteCount, null)),
        React.createElement("div", { className: "user-cont" },
            React.createElement("div", { className: "search-btn" },
                React.createElement("div", { className: "search-text" }, "\u9ED1\u540D\u5355\u5217\u8868"),
                React.createElement("div", { className: "search-input" },
                    React.createElement(Search, { placeholder: "\u7AD9\u70B9\u540D\u79F0\u6216url\u6A21\u7CCA\u5339\u914D", value: searchValue, onChange: function (e) {
                            return setSearchValue(e.target.value && e.target.value.trim());
                        }, maxLength: inputMaxLength, allowClear: true, size: "large", style: { width: 600 }, onSearch: onSearch }),
                    React.createElement("div", { className: "btn-new ml24", onClick: function () { return onNewBtn(); } }, "\u65B0\u589E"))),
            React.createElement("div", { className: "cont-table mt20" },
                React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: 1300 }, pagination: false, rowKey: "userId", onChange: onTableChange, rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
                React.createElement("div", { className: "cont-pagination mt20" },
                    React.createElement(Pagination, { showQuickJumper: true, pageSize: pageSize, total: total, onChange: onChange, showSizeChanger: true })))),
        isShowModal && (React.createElement(NewSiteModal, { isShowModal: isShowModal, setIsShowModal: setIsShowModal, list: urlList, getData: getData })),
        isShowEditModal && (React.createElement(EditSiteModal, { isShowEditModal: isShowEditModal, setIsShowEditModal: setIsShowEditModal, editData: editData, getData: getData }))));
}));
export default SiteManager;
