var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Row, Col, message, Select, Button, InputNumber, Checkbox, } from "antd";
import { get, postForm, postJson } from "../../../../utils/axios";
import { AREA_MANGE, LEADER, TOOLS } from "../../../../api/api";
import "../index.scss";
import { inject, observer } from "mobx-react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LoadingOutlined, CheckCircleOutlined, ReloadOutlined, } from "@ant-design/icons";
import _ from "lodash";
var formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
var formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};
var AddEditModal = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, id = props.id, getData = props.getData, staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    var _b = useState(false), isAdd = _b[0], setIsAdd = _b[1];
    var _c = useState(false), isCheck = _c[0], setIsCheck = _c[1];
    var _d = useState(false), isEdit = _d[0], setIsEdit = _d[1];
    var _e = useState(false), isDisabledForm = _e[0], setIsDisabledForm = _e[1];
    var _f = useState(["", "", ""]), imagesStatus = _f[0], setImagesStatus = _f[1];
    var _g = useState([]), areaList = _g[0], setAreaList = _g[1];
    var _h = useState([]), leaderList = _h[0], setLeaderList = _h[1];
    var _j = useState(""), leaderId = _j[0], setLeaderId = _j[1];
    var _k = useState({
        leaderAtlasDTOList: [],
    }), leaderInfo = _k[0], setLeaderInfo = _k[1];
    var _l = useState([]), picList = _l[0], setPicList = _l[1];
    var _m = useState(""), headPicturePre = _m[0], setHeadPicturePre = _m[1];
    var _o = useState([]), leaderListPre = _o[0], setLeaderListPre = _o[1];
    var _p = useState(""), selectedLeaderName = _p[0], setSelectedLeaderName = _p[1];
    var _q = useState(false), isBriefIntroduction = _q[0], setIsBriefIntroduction = _q[1];
    var _r = useState({}), operation = _r[0], setOperation = _r[1];
    var form = Form.useForm()[0];
    var handleOk = function () {
        form
            .validateFields()
            .then(function (values) {
            console.log("values", values);
            var orgId = values.orgId, sequence = values.sequence, headPicture = values.headPicture, leaderAtlasDTOList = values.leaderAtlasDTOList, name = values.name, postAbbreviation = values.postAbbreviation, isBriefIntroduction = values.isBriefIntroduction, briefIntroduction = values.briefIntroduction;
            var leaderSaveRequest = {
                headPicture: headPicture === null || headPicture === void 0 ? void 0 : headPicture.trim(),
                info: "",
                leaderId: leaderId,
                name: selectedLeaderName ? selectedLeaderName === null || selectedLeaderName === void 0 ? void 0 : selectedLeaderName.trim() : name === null || name === void 0 ? void 0 : name.trim(),
                operator: "",
                operatorName: "",
                postAbbreviation: postAbbreviation === null || postAbbreviation === void 0 ? void 0 : postAbbreviation.trim(),
                leaderAtlasSaveRequestList: picList.map(function (item) {
                    var _a;
                    return __assign({ fileId: item.fileId, fileUrl: (_a = item.url) === null || _a === void 0 ? void 0 : _a.trim(), leaderId: leaderId }, item);
                }),
                briefIntroduction: briefIntroduction,
            };
            var params = {
                orgId: orgId,
                sequence: sequence,
                leaderSaveRequest: leaderSaveRequest,
                isBriefIntroduction: isBriefIntroduction,
            };
            var url = LEADER.save;
            if (isEdit) {
                params.id = id;
                url = LEADER.update;
            }
            console.log("params", params);
            postJson(url, params).then(function (res) {
                if (res.state !== 200) {
                    message.error(res.msg);
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    setIsShowModal(false);
                    getData();
                }, 1000);
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            return;
        });
    };
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var checkedBox = function (checkedValue) {
        var _a, _b;
        setIsBriefIntroduction((_a = checkedValue === null || checkedValue === void 0 ? void 0 : checkedValue.target) === null || _a === void 0 ? void 0 : _a.checked);
        (!((_b = checkedValue === null || checkedValue === void 0 ? void 0 : checkedValue.target) === null || _b === void 0 ? void 0 : _b.checked)) && form.setFieldValue('briefIntroduction', '');
    };
    var getDetail = function () {
        console.log("getDetail");
        get("".concat(LEADER.getDetailById, "/").concat(id), { id: id }).then(function (res) {
            var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, orgId = _a.orgId, leaderDetailDTO = _a.leaderDetailDTO, sequence = _a.sequence, isBriefIntroduction = _a.isBriefIntroduction;
            var _b = leaderDetailDTO || {}, name = _b.name, postAbbreviation = _b.postAbbreviation, headPicture = _b.headPicture, leaderAtlasDTOList = _b.leaderAtlasDTOList, leaderId = _b.leaderId, createTime = _b.createTime, operatorName = _b.operatorName, updateTime = _b.updateTime, briefIntroduction = _b.briefIntroduction;
            setLeaderId(leaderId);
            setHeadPicturePre(headPicture);
            setPicList(leaderAtlasDTOList.map(function (item) { return (__assign(__assign({}, item), { url: item.fileUrl })); }));
            var a = leaderAtlasDTOList.map(function (item) { return item.fileUrl; });
            setLeaderListPre(a);
            form.setFieldsValue({
                orgId: orgId,
                name: name,
                postAbbreviation: postAbbreviation,
                headPicture: headPicture,
                sequence: sequence,
                leaderAtlasDTOList: a,
                isBriefIntroduction: isBriefIntroduction,
                briefIntroduction: briefIntroduction
            });
            // setIsBriefIntroduction(true)
            setIsBriefIntroduction(isBriefIntroduction);
            setImagesStatus(["suc"].concat(a.map(function () { return "suc"; })));
            setOperation({
                createTime: createTime,
                operatorName: operatorName,
                updateTime: updateTime,
            });
        });
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: 'SCREEN' }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    // 搜索领导人
    var handleSearch = _.debounce(function (newValue) {
        console.log("handleSearch");
        if (newValue) {
            var params = {
                name: newValue,
            };
            get(LEADER.getSimpleInfoByLikeName, params).then(function (res) {
                if (res) {
                    if ((res === null || res === void 0 ? void 0 : res.results) && (res === null || res === void 0 ? void 0 : res.results.length) > 0) {
                        setLeaderList(((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) { return ({
                            label: item.name,
                            value: item.leaderId,
                        }); }));
                    }
                    else {
                        setLeaderList([]);
                        form.setFieldsValue({
                            name: newValue,
                        });
                    }
                }
            });
        }
        else {
        }
    }, 500);
    // 领导人下拉框选择
    var onLeaderSelect = function (v, option) {
        console.log("v,option", v, option);
        form.setFieldValue("name", v);
        setSelectedLeaderName(option.label);
        getDetailByLeaderName();
        setLeaderId(v);
    };
    // 根据领导人获取领导人详情
    var getDetailByLeaderName = function () {
        var params = {
            leaderId: form.getFieldValue("name"),
        };
        get(LEADER.getDetailByLeaderId, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, postAbbreviation = _a.postAbbreviation, headPicture = _a.headPicture, leaderAtlasDTOList = _a.leaderAtlasDTOList, briefIntroduction = _a.briefIntroduction;
                form.setFieldValue("postAbbreviation", postAbbreviation);
                form.setFieldValue("headPicture", headPicture);
                form.setFieldValue("leaderAtlasDTOList", leaderAtlasDTOList === null || leaderAtlasDTOList === void 0 ? void 0 : leaderAtlasDTOList.map(function (item) { return item.fileUrl; }));
                form.setFieldValue("briefIntroduction", briefIntroduction);
                setLeaderInfo({
                    headPicture: headPicture,
                    leaderAtlasDTOList: leaderAtlasDTOList,
                });
                setLeaderListPre(leaderAtlasDTOList === null || leaderAtlasDTOList === void 0 ? void 0 : leaderAtlasDTOList.map(function (item) { return item.fileUrl; }));
                var leaderList_1 = leaderAtlasDTOList.map(function (item) { return (__assign({ fileId: item.fileId, url: item.fileUrl }, item)); });
                setPicList(leaderList_1);
            }
        });
    };
    // 上传图片
    var uploadImg = function (index, index2, type) { return __awaiter(void 0, void 0, void 0, function () {
        var temp, mark, imagesStatusCopy_1, params, temp, pre_1, mark, imagesStatusCopy, params;
        return __generator(this, function (_a) {
            if (index === 0) {
                temp = form.getFieldValue("headPicture").trim();
                if (!temp)
                    return [2 /*return*/];
                mark = false;
                mark = type === 'reload' ? true : (temp !== leaderInfo.headPicture && temp !== headPicturePre);
                // if(type === 'reload'){
                //   mark = true
                // }else{
                //   if (temp !== leaderInfo.headPicture && temp !== headPicturePre){
                //     mark = true
                //   }
                // }
                if (mark) {
                    // if (temp !== leaderInfo.headPicture && temp !== headPicturePre) {
                    console.log("uploadddddddddd");
                    imagesStatusCopy_1 = imagesStatus.slice();
                    imagesStatusCopy_1[index] = "loading";
                    setImagesStatus(imagesStatusCopy_1);
                    params = {
                        imageUrl: temp,
                    };
                    postForm(TOOLS.uploadTempByUrl, params).then(function (res) {
                        var _a, _b;
                        if (res) {
                            form.setFieldValue("headPicture", (_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.url);
                            setHeadPicturePre((_b = res === null || res === void 0 ? void 0 : res.results) === null || _b === void 0 ? void 0 : _b.url);
                            imagesStatusCopy_1[index] = "suc";
                        }
                        else {
                            imagesStatusCopy_1[index] = "err";
                        }
                        console.log("imagesStatusCopy", imagesStatusCopy_1);
                        setImagesStatus(imagesStatusCopy_1.slice());
                    });
                }
            }
            else {
                temp = form.getFieldValue("leaderAtlasDTOList")[index2].trim();
                pre_1 = form.getFieldValue("leaderAtlasDTOList");
                if (!temp)
                    return [2 /*return*/];
                mark = false;
                mark = type === 'reload' ? true : (temp !== (leaderInfo === null || leaderInfo === void 0 ? void 0 : leaderInfo.leaderAtlasDTOList[index2]) && temp !== leaderListPre[index2]);
                // if (
                //   temp !== leaderInfo?.leaderAtlasDTOList[index2] &&
                //   temp !== leaderListPre[index2]
                // ) {
                if (mark) {
                    imagesStatusCopy = imagesStatus.slice();
                    imagesStatusCopy[index + index2] = "loading";
                    setImagesStatus(imagesStatusCopy);
                    params = {
                        imageUrl: temp,
                    };
                    postForm(TOOLS.uploadTempByUrl, params).then(function (res) {
                        var _a;
                        var imagesStatusCopy2 = imagesStatus.slice();
                        if (res) {
                            console.log("11");
                            var picListCopy = picList.slice();
                            picListCopy[index2] = res === null || res === void 0 ? void 0 : res.results;
                            setPicList(picListCopy);
                            pre_1[index2] = (_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a.url;
                            form.setFieldValue("leaderAtlasDTOList", pre_1);
                            setLeaderListPre(pre_1);
                            imagesStatusCopy2[index + index2] = "suc";
                        }
                        else {
                            console.log("22");
                            imagesStatusCopy2[index + index2] = "err";
                        }
                        setImagesStatus(imagesStatusCopy2.slice());
                        console.log("imagesStatusCopy2", imagesStatusCopy2);
                    });
                }
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        switch (modalType) {
            case "add":
                setTitle("新建角色");
                setIsAdd(true);
                break;
            case "edit":
                setTitle("编辑角色");
                setIsEdit(true);
                break;
            case "check":
                setTitle("查看角色");
                setIsDisabledForm(true);
                setIsCheck(true);
                break;
        }
    }, [modalType]);
    useEffect(function () {
        (modalType === "edit" || modalType === "check") && getDetail();
    }, [modalType]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement(Modal, { title: title, open: true, maskClosable: false, footer: false, width: 750, onCancel: handleCancel, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form },
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u5730\u57DF\uFF1A", name: "orgId", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Select, { style: { width: inputWidth }, allowClear: true, options: areaList, placeholder: "\u8BF7\u9009\u62E9", disabled: isDisabledForm, fieldNames: {
                                label: 'regionName',
                                value: 'orgId'
                            }, showSearch: true, filterOption: function (input, option) {
                                var _a;
                                return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                            } })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u9886\u5BFC\u4EBA\uFF1A", name: "name", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Select, { showSearch: true, style: { width: inputWidth }, placeholder: "\u8BF7\u8F93\u5165", filterOption: false, onSearch: handleSearch, notFoundContent: null, options: leaderList, maxLength: 20, onSelect: function (v, option) { return onLeaderSelect(v, option); }, disabled: isDisabledForm })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u804C\u52A1\uFF1A", name: "postAbbreviation", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, maxLength: 50, disabled: isDisabledForm })))),
            !isCheck && (React.createElement(Row, null,
                React.createElement(Col, { span: 16, push: 2 },
                    React.createElement(Form.Item, { label: "\u5934\u50CF\uFF1A", name: "headPicture", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, onBlur: function () { return uploadImg(0); }, disabled: isDisabledForm, maxLength: inputMaxLength }))),
                React.createElement(Col, { span: 1, push: 2 },
                    React.createElement("div", { className: "upload-icon" },
                        imagesStatus[0] && imagesStatus[0] === "loading" && (React.createElement(LoadingOutlined, { style: { marginLeft: "20px" } })),
                        imagesStatus[0] && imagesStatus[0] === "suc" && (React.createElement(CheckCircleOutlined, { style: { color: "green", marginLeft: "20px" } })),
                        imagesStatus[0] && imagesStatus[0] === "err" && (React.createElement(ReloadOutlined, { style: { color: "red", marginLeft: "20px" }, onClick: function () { return uploadImg(0, 0, 'reload'); } })))))),
            !isCheck && form.getFieldValue("headPicture") && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "", name: "" },
                        React.createElement("img", { src: form.getFieldValue("headPicture"), className: "form_ddd_preview ml175" }))))),
            isCheck && (React.createElement(Row, null,
                React.createElement(Col, { span: 16, push: 2 },
                    React.createElement(Form.Item, { label: "\u5934\u50CF\uFF1A", name: "headPicture", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement("img", { src: form.getFieldValue("headPicture"), className: "form_ddd_preview" }))))),
            !isCheck && (React.createElement(Form.List, { name: "leaderAtlasDTOList", initialValue: ["", ""], rules: [
                    {
                        validator: function (_, leaderAtlasDTOList) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                console.log("_, leaderAtlasDTOList", _, leaderAtlasDTOList);
                                leaderAtlasDTOList.map(function (item) {
                                    if (item) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("请输入");
                                    }
                                });
                                return [2 /*return*/];
                            });
                        }); },
                    },
                ] }, function (fields, _a, _b) {
                var add = _a.add, remove = _a.remove;
                var errors = _b.errors;
                console.log("fields", fields);
                return (React.createElement(React.Fragment, null,
                    fields.map(function (field, index) { return (React.createElement(Row, null,
                        React.createElement(Col, { span: 24, push: 2 },
                            React.createElement(Form.Item, __assign({}, (index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel), { label: index === 0 ? "图集" : "", required: true, key: field.key }),
                                React.createElement(Form.Item, __assign({}, field, { validateTrigger: ["onChange", "onBlur"], rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请输入",
                                        },
                                    ], noStyle: true }),
                                    React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165", style: { width: "60%" }, onBlur: function () {
                                            return uploadImg(1, index);
                                        }, maxLength: inputMaxLength, disabled: isDisabledForm })),
                                imagesStatus[1 + index] &&
                                    imagesStatus[1 + index] === "loading" && (React.createElement(LoadingOutlined, { style: { marginLeft: "20px" }, key: "loading" })),
                                imagesStatus[1 + index] &&
                                    imagesStatus[1 + index] === "suc" && (React.createElement(CheckCircleOutlined, { style: { color: "green", marginLeft: "20px" }, key: "suc" })),
                                imagesStatus[1 + index] &&
                                    imagesStatus[1 + index] === "err" && (React.createElement(ReloadOutlined, { style: { color: "red", marginLeft: "20px" }, key: "err", onClick: function () {
                                        return uploadImg(1, index, 'reload');
                                    } })),
                                !isCheck && fields.length > 2 ? (React.createElement(MinusCircleOutlined, { className: "dynamic-delete-button ml20", onClick: function () { return remove(field.name); } })) : null,
                                form.getFieldValue("leaderAtlasDTOList")[index] && (React.createElement(Row, null,
                                    React.createElement(Col, { span: 2 },
                                        React.createElement(Form.Item, { label: "", name: "" },
                                            React.createElement("img", { src: form.getFieldValue("leaderAtlasDTOList")[index], className: "form_ddd_preview2" }))))))))); }),
                    !isCheck && fields.length < 5 && (React.createElement(Row, null,
                        React.createElement(Col, { span: 20, push: 6 },
                            React.createElement(Form.Item, null,
                                React.createElement(Button, { type: "dashed", onClick: function () { return add(); }, style: { width: inputWidth }, icon: React.createElement(PlusOutlined, null) }, "\u6DFB\u52A0"),
                                React.createElement(Form.ErrorList, { errors: errors })))))));
            })),
            isCheck && (React.createElement(Row, null,
                React.createElement(Col, { span: 16, push: 2 },
                    React.createElement(Form.Item, { label: "\u56FE\u96C6\uFF1A" }, form
                        .getFieldValue("leaderAtlasDTOList")
                        .map(function (item) { return (React.createElement("img", { src: item, className: "form_ddd_preview mr10" })); }))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u987A\u5E8F\uFF1A", name: "sequence", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(InputNumber, { min: 1, precision: 0, disabled: isDisabledForm })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u5C55\u793A\u7B80\u4ECB\u6A21\u5757\uFF1A", name: "isBriefIntroduction", valuePropName: "checked" },
                        React.createElement(Checkbox, { disabled: isDisabledForm, value: isBriefIntroduction, onChange: checkedBox })))),
            isBriefIntroduction && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u7B80\u4ECB\uFF1A", name: "briefIntroduction", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Input.TextArea, { disabled: isDisabledForm, rows: 4, maxLength: 500, wrap: 'hard' }))))),
            !isAdd && (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4\uFF1A" },
                            React.createElement("div", null, operation.createTime)))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4" }, operation.updateTime))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA" }, operation.operatorName)))))),
        !isCheck && (React.createElement("div", { className: "modal-footer-btn" },
            React.createElement("div", { className: "footer-btn de-active mr10", onClick: handleCancel }, "\u53D6\u6D88"),
            React.createElement("div", { className: "footer-btn active", onClick: handleOk }, "\u786E\u5B9A")))));
}));
export default AddEditModal;
