import { message } from 'antd';
import axios from 'axios';
import {replaceUrl, formatSign,getParams} from './lib'
import moment from 'moment'

const toLogin = ()=>{
  replaceUrl('/login')
  window.location.reload()
  return {}
}

export const get = async (url,params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios.get(`/apiInterface/interface${url}`,{params,headers},);
    console.log('res', res);
    if (res.status === 200) {
      const {data} = res || {}
      const {state,msg,results} = data||{}
      if(state === 401){
        return toLogin()
      }
      if(state!==200){
        message.error(msg)
        return null
      }
      return data;
    }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};

export const postJson = async (url, params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios
      .post(`/apiInterface/interface${url}`, params, {
        headers: {
          ...headers,
          'Content-Type': 'application/json;charset=UTF-8'
        },
      });
    if (res.status === 200) {
      const {data} = res || {}
      const {state,msg,results} = data||{}
      if(state === 401){
        return toLogin()
      }
      if(state!==200){
        message.error(msg)
        return null
      }
      return data;
    }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};
export const postForm = async (url, params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios
      .post(`/apiInterface/interface${url}`, params, {
        headers: {
          ...headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    if (res.status === 200) {
      const {data} = res || {}
      const {state,msg,results} = data||{}
      if(state === 401){
        return toLogin()
      }
      if(state!==200){
        message.error(msg)
        return null
      }
      return data;
    }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};

export const post = async (url, params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios
      .post(`/apiInterface/interface${url}`, params, {
        headers: {
          ...headers,
          'Content-Type': 'application/json;charset=UTF-8'
        },
      });
    if (res.status === 200) {
      const {data} = res || {}
      const {state,msg,results} = data||{}
      if(state === 401){
        return toLogin()
      }
      if(state!==200){
        message.error(msg)
        return null
      }
      return data;
    }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};

export const getFromES = async (url,params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios.get(`${url}`,{params,headers},);
    if (res.status === 200) {
      return res?.data
    }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};
export const postJsonFromES = async (url, params) => {
  try{
    let headers = {
      'platform-token': sessionStorage.getItem('platform-token'),
    };
    const res = await axios
      .post(`${url}`, params, {
        headers: {
          ...headers,
          'Content-Type': 'application/json;charset=UTF-8'
        },
      });
      if (res.status === 200) {
        return res?.data
      }
  }catch{
    message.error('服务不稳定，请稍后再试')
  }
};

export const ajaxOpenAi = async (type,url, params)=>{
  let execute = '';
  let urlParams = '';
  switch (process.env.NODE_ENV){
    case 'development':
    case 'test':
      execute = 'test';
      break;
    case 'production':
      execute = 'prod';
      break;
          
   }
  const headers = {
    'execute-env':'',
    'Content-Type':'application/json;charset=utf-8'
  };

  let sign = formatSign('18573eca9e3346aeaed77c2e48e58369','cc68e43e227849aab5ab136ce328429f1683266395167sw9SL0Kdo8',params);
  let data = {
    appKey:'18573eca9e3346aeaed77c2e48e58369',
    signTime:moment(new Date()).format('YYYYMMDDHHmmss'),
    sign:sign,
    ...params
  };
  if(type === 'get'){
    const par = getParams('18573eca9e3346aeaed77c2e48e58369','cc68e43e227849aab5ab136ce328429f1683266395167sw9SL0Kdo8',sign,params);
    urlParams = `${url}${par}`;
  }else{
    urlParams = url;
  }
  try {
    const res = await axios({
      url: urlParams,
      data:JSON.stringify(data),
      method:type,
      headers,
      transformRequest: [function (data) {
        return data;
      } ],
    });
    let data_1 = res.data;
    if (data_1.msg === '您暂未登入') {
      pushUrl({
        pathname: '/login'
      });
    }
    if(data_1?.returnCode !== 'E00000'){
      message.error(data_1?.message)
      return {}
    }
    return data_1;
  } catch (error) {
    return error;
  }
};