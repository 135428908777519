var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import DataMenuAddModal from "./components/DataMenuAddModal";
import { Button, Pagination, Popconfirm, Table, message } from "antd";
import { get, postJson } from "../../../utils/axios";
import { DATA_MENU_MANAGER } from "../../../api/api";
var DataMenuManager = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var defaultPagination = staticStore.defaultPagination;
    var _a = useState(false), isShowModal = _a[0], setIsShowModal = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(defaultPagination), pagination = _c[0], setPagination = _c[1];
    var _d = useState(""), modalType = _d[0], setModalType = _d[1];
    var _e = useState(""), id = _e[0], setId = _e[1];
    var deleteData = function (id) {
        get("".concat(DATA_MENU_MANAGER.delete, "/").concat(id)).then(function (res) {
            if (res) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var OnOrOff = function (type, id) {
        var params = {
            id: id,
        };
        var url = type === "on" ? "upShelves" : "downShelves";
        get("".concat(DATA_MENU_MANAGER[url], "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("".concat(type === "on" ? "上架" : "下架", "\u6210\u529F"));
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var gotoAdd = function (type, id) {
        setModalType(type);
        setId(id);
        setIsShowModal(true);
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var getData = function (cur, size) {
        var params = {
            current: cur,
            pageSize: size,
        };
        postJson(DATA_MENU_MANAGER.pageSearch, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                var dataList = handleList(list);
                console.log("dataList", dataList);
                setData(dataList);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var handleList = function (list) {
        return list.map(function (item) {
            if (item.childNodeList.length === 0) {
                return __assign({}, item);
            }
            else {
                var children = handleList(item.childNodeList);
                return __assign(__assign({}, item), { children: children });
            }
        });
    };
    var columns = [
        {
            title: "目录名称",
            dataIndex: "catalogueName",
            key: "catalogueName",
            ellipsis: true,
        },
        {
            title: "排序",
            dataIndex: "sort",
            key: "sort",
            ellipsis: true,
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            render: function (v) {
                return v === "IN_USE" ? (React.createElement("span", { className: "light_status" }, "\u4F7F\u7528\u4E2D")) : (React.createElement("span", { className: "dark_status" }, "\u5DF2\u505C\u7528"));
            },
        },
        {
            title: "编辑时间",
            dataIndex: "updateTime",
            key: "updateTime",
            ellipsis: true,
            sorter: function (v) { return new Date(v).getTime() - new Date(v).getTime(); },
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            ellipsis: true,
            sorter: function (v) { return new Date(v).getTime() - new Date(v).getTime(); },
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 200,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoAdd("check", v); } }, "\u67E5\u770B"),
                r.status === "IN_USE" ? (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("off", r.id); } }, "\u4E0B\u67B6")) : (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("on", r.id); } }, "\u4E0A\u67B6")),
                r.status === "IN_USE" ? (React.createElement("span", { className: "mr14 table_dark_option" }, "\u7F16\u8F91")) : (React.createElement("a", { onClick: function () { return gotoAdd("edit", v); }, href: "javascript:;", className: "mr14" }, "\u7F16\u8F91")),
                r.status === "IN_USE" ? (React.createElement("span", { className: "mr14 table_dark_option" }, "\u5220\u9664")) : (React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u8BF7\u786E\u8BA4\u540E\u5220\u9664\uFF01", onConfirm: function () { return deleteData(r.id); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" },
                    React.createElement("a", { href: "javascript:;", className: "mr14 table_delete" }, "\u5220\u9664"))))); },
        },
    ];
    useEffect(function () {
        getData(1, 10);
    }, []);
    return (React.createElement("div", { className: "data_manager" },
        React.createElement("div", { className: "data_manager_header mb20" },
            React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return gotoAdd("add"); } }, "+\u65B0\u5EFA")),
        React.createElement("div", { className: "table" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "id" }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, current: pagination.current, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } }))),
        isShowModal && (React.createElement(DataMenuAddModal, { modalType: modalType, id: id, setIsShowModal: setIsShowModal, getData: function () { return getData(1, 10); } }))));
}));
export default DataMenuManager;
