import React, { useState } from "react";
import DataManager from "./DataManager";
import DataMenuManager from "./DataMenuManager";
import "./index.scss";
import { Tabs } from "antd";
var tabData = [
    {
        label: "数据管理",
        key: "data_manager",
    },
    {
        label: "数据目录管理",
        key: "data_menu_manager",
    },
];
export default function dataProductSub() {
    var _a = useState("data_manager"), activeKey = _a[0], setActiveKey = _a[1];
    return (React.createElement("div", { className: "data_product_sub" },
        React.createElement(Tabs, { activeKey: activeKey, items: tabData, onChange: function (key) { return setActiveKey(key); } }),
        activeKey === "data_manager" && React.createElement(DataManager, null),
        activeKey === "data_menu_manager" && React.createElement(DataMenuManager, null)));
}
