var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { postForm } from '../../../../utils/axios';
import { USER } from '../../../../api/api';
export default function UserTypeCount() {
    var _this = this;
    var _a = useState([]), roleList = _a[0], setRoleList = _a[1];
    var getCountData = function () {
        postForm(USER.userRoleAnalysis).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _a, userTotal, newTodayTotal, screenTotal, officialTotal, roleListCopy;
            return __generator(this, function (_b) {
                _a = res === null || res === void 0 ? void 0 : res.results, userTotal = _a.userTotal, newTodayTotal = _a.newTodayTotal, screenTotal = _a.screenTotal, officialTotal = _a.officialTotal;
                roleListCopy = roleList.slice();
                roleListCopy.push({ count: userTotal, label: '用户总量' });
                // await get(`${USER.list}?accountType=SCREEN&accountType=OFFICIAL_WEBSITE`).then(res2 => {
                //   if (res2.state === 200) {
                //     const { list = [], pagination = {} } = res2?.results
                //     let OFFICIAL_WEBSITE_COUNT = 0
                //     let SCREEN_COUNT = 0
                //     list.forEach((item:any)=>{
                //       if(item.accountType === 'SCREEN'){
                //         SCREEN_COUNT++
                //       }else if(item.accountType === 'OFFICIAL_WEBSITE'){
                //         OFFICIAL_WEBSITE_COUNT++
                //       }
                //     })
                //     roleListCopy.push({label:'官网用户',count:OFFICIAL_WEBSITE_COUNT})
                //     roleListCopy.push({label:'驾驶舱用户',count:SCREEN_COUNT})
                //   }
                // })
                roleListCopy.push({ label: '官网用户', count: officialTotal });
                roleListCopy.push({ label: '驾驶舱用户', count: screenTotal });
                roleListCopy.push({ count: newTodayTotal, label: '今日新增' });
                setRoleList(roleListCopy);
                return [2 /*return*/];
            });
        }); });
    };
    useEffect(function () {
        getCountData();
    }, []);
    return (React.createElement("div", { className: 'role-count-cont' }, roleList.map(function (item) {
        return React.createElement("div", { className: 'role-item', key: item.label },
            React.createElement("div", null, item.count),
            React.createElement("div", null, item.label));
    })));
}
