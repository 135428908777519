import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Input, message } from 'antd';
var md5 = require('md5');
import { postForm, postJson } from '../../utils/axios';
import loginData from '../../styles/images/login/login_data.png';
import { COMMON, LOGIN } from '../../api/api';
import { inject, observer } from 'mobx-react';
import './Login.scss';
var Login = inject("appStore")(observer(function (props) {
    var appStore = props.appStore;
    var setMenus = appStore.setMenus, setIsAdminStore = appStore.setIsAdminStore;
    var _a = useState(''), username = _a[0], setUserName = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var navigate = useNavigate();
    var handleFormChange = function (e, type) {
        console.log('handleFormChange');
        var value = e.target.value && e.target.value.replace(' ', '');
        if (type === 'userName') {
            setUserName(value);
        }
        else if (type === 'password') {
            setPassword(value);
        }
    };
    var handleLoginClick = function () {
        if (!username || !password) {
            message.warning('请输入用户名或密码');
            return;
        }
        // if(!checkPassword()) {
        //   message.warning('密码要包含：大写字母+小写字母+特殊符号+数字四种组合')
        //   return
        // }
        var params = {
            account: username,
            password: md5(password),
        };
        postForm(LOGIN.login, params).then(function (res) {
            console.log('res', res);
            if (res.state === 200) {
                var results_1 = res.results;
                if (results_1 === null || results_1 === void 0 ? void 0 : results_1.token) {
                    sessionStorage.setItem('platform-token', results_1 === null || results_1 === void 0 ? void 0 : results_1.token);
                    sessionStorage.setItem('userName', results_1 === null || results_1 === void 0 ? void 0 : results_1.userName);
                    sessionStorage.setItem('isSuperAdmin', (results_1 === null || results_1 === void 0 ? void 0 : results_1.isAdmin) ? 'yes' : 'no');
                    postJson(COMMON.getUserMenuTree).then(function (res2) {
                        var _a, _b, _c;
                        if (res2) {
                            var results2 = res2.results;
                            setIsAdminStore(results_1 === null || results_1 === void 0 ? void 0 : results_1.isAdmin);
                            if (results_1 === null || results_1 === void 0 ? void 0 : results_1.isAdmin) {
                                navigate('/system/userManager');
                            }
                            else {
                                var uri1 = (_a = results2[0]) === null || _a === void 0 ? void 0 : _a.funUri;
                                var uri2 = (_c = (_b = results2[0]) === null || _b === void 0 ? void 0 : _b.children[0]) === null || _c === void 0 ? void 0 : _c.funUri;
                                navigate("/".concat(uri1, "/").concat(uri2));
                            }
                        }
                    });
                }
            }
            else {
                message.error(res.msg);
            }
        });
    };
    var handleKeyDown = function (e) {
        if (e.keyCode === 13) {
            handleLoginClick();
        }
    };
    return (React.createElement("div", { className: "login-main" },
        React.createElement("div", { className: 'login-cont' },
            React.createElement("img", { src: loginData, className: "login-data" }),
            React.createElement("div", { className: 'cont-right' },
                React.createElement("div", { className: 'login-main-right-title' }, "\u7CFB\u7EDF\u8D26\u6237\u767B\u5F55"),
                React.createElement("div", { className: "login-main-right-block" },
                    React.createElement("div", { className: "login-main-right-label" }, "\u8D26\u6237\u540D\u79F0"),
                    React.createElement(Input, { value: username, placeholder: "\u8BF7\u8F93\u5165\u8D26\u6237\u540D\u79F0", onChange: function (e) { return handleFormChange(e, 'userName'); }, className: 'username-input mt15 mb18' }),
                    React.createElement("div", { className: "login-main-right-label" }, "\u8F93\u5165\u5BC6\u7801"),
                    React.createElement("div", { className: "login-main-right-ps " },
                        React.createElement(Input.Password, { value: password, placeholder: "\u8BF7\u8F93\u5165\u5BC6\u7801", visibilityToggle: false, onChange: function (e) { return handleFormChange(e, 'password'); }, className: 'username-password mt15' }))),
                React.createElement("div", { className: "login-main-right-submit", onClick: handleLoginClick, onKeyDown: function (e) { return handleKeyDown(e); } }, "\u767B\u5F55")))));
}));
export default Login;
