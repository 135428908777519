var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Col, Form, Input, InputNumber, Row, Select, message, } from "antd";
import { inject, observer } from "mobx-react";
import { MinusCircleOutlined, PlusOutlined, SearchOutlined, } from "@ant-design/icons";
import AreaSearch from "./components/AreaSearch";
import { useNavigate } from "react-router-dom";
import { get, postJson } from "../../../utils/axios";
import { AREA_MANGE, PRIMEINDUSTRY } from "../../../api/api";
var AddOrEditPage = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState(false), isShowModal = _b[0], setIsShowModal = _b[1];
    var _c = useState(-1), chooseIndex = _c[0], setChooseIndex = _c[1];
    var _d = useState([]), choosedItemCode = _d[0], setChoosedItemCode = _d[1]; // 选中的主导产业的code对应的list
    var _e = useState([]), choosedItemName = _e[0], setChoosedItemName = _e[1]; // 选中的主导产业的name对应的list
    var _f = useState({}), operatorObj = _f[0], setOperatorObj = _f[1]; // 编辑时回显的操作人及时间信息
    var routes = location.hash.split("/") || [];
    var isAdd = location.hash.indexOf("add") > -1;
    var isEdit = location.hash.indexOf("edit") > -1;
    var id = isAdd ? "" : routes[routes.length - 1];
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: 'SCREEN' }).then(function (res) {
            if (res) {
                setAreaList(res === null || res === void 0 ? void 0 : res.results);
            }
        });
    };
    var onSearch = function (index) {
        setChooseIndex(index);
        setIsShowModal(true);
    };
    var sendChooseItem = function (v) {
        var values = form.getFieldValue("agriculturalDetailsDtos");
        if (values[chooseIndex] === "" || values[chooseIndex] === undefined) {
            values[chooseIndex] = {
                name: v.productName,
            };
        }
        else {
            values[chooseIndex].name = v.productName;
        }
        form.setFieldValue("agriculturalDetailsDtos", values);
        form.validateFields([["agriculturalDetailsDtos", chooseIndex, "name"]]);
        var choosedItemCodeCopy = choosedItemCode.slice();
        choosedItemCodeCopy[chooseIndex] = v.agriculturalCode;
        setChoosedItemCode(choosedItemCodeCopy);
        var choosedItemNameCopy = choosedItemName.slice();
        choosedItemNameCopy[chooseIndex] = v.productName;
        setChoosedItemName(choosedItemNameCopy);
    };
    var getDetail = function () {
        var params = {
            id: id,
        };
        get("".concat(PRIMEINDUSTRY.getDetailById, "/").concat(id)).then(function (res) {
            var _a = res === null || res === void 0 ? void 0 : res.results, orgId = _a.orgId, industryName = _a.industryName, createTime = _a.createTime, updateTime = _a.updateTime, operatorName = _a.operatorName, sequence = _a.sequence, agriculturalCode = _a.agriculturalCode;
            form.setFieldsValue({
                orgId: orgId,
                industryName: industryName,
                agriculturalDetailsDtos: [{ name: industryName, sequence: sequence }],
            });
            setChoosedItemCode([agriculturalCode]);
            setChoosedItemName([industryName]);
            setOperatorObj({
                createTime: createTime,
                updateTime: updateTime,
                operatorName: operatorName,
            });
        });
        console.log("getDetail-params", params);
    };
    var onSubmit = function () {
        form
            .validateFields()
            .then(function (values) {
            var _a, _b, _c, _d;
            console.log("values", values);
            var agriculturalDetailsDtos = (_a = values === null || values === void 0 ? void 0 : values.agriculturalDetailsDtos) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
                var _a;
                return {
                    agriculturalCode: choosedItemCode[index],
                    industryName: (_a = item.name) === null || _a === void 0 ? void 0 : _a.trim(),
                    sequence: item.sequence,
                };
            });
            var params = {};
            var url = "";
            // isAdd?PRIMEINDUSTRY.industryAdd:PRIMEINDUSTRY.edit
            if (isAdd) {
                url = PRIMEINDUSTRY.industryAdd;
                params = {
                    agriculturalDetailsDtos: agriculturalDetailsDtos || [],
                    id: id || null,
                    orgId: values.orgId,
                };
            }
            else {
                url = PRIMEINDUSTRY.edit;
                params = {
                    // agriculturalDetailsDtos: agriculturalDetailsDtos || [],
                    id: id || null,
                    orgId: values.orgId,
                    agriculturalCode: (_b = agriculturalDetailsDtos[0]) === null || _b === void 0 ? void 0 : _b.agriculturalCode,
                    industryName: (_c = agriculturalDetailsDtos[0]) === null || _c === void 0 ? void 0 : _c.industryName,
                    sequence: (_d = agriculturalDetailsDtos[0]) === null || _d === void 0 ? void 0 : _d.sequence,
                };
            }
            console.log("params", params);
            postJson(url, params).then(function (res) {
                if (!res) {
                    message.error(res.msg);
                    return;
                }
                message.success("保存成功");
                setTimeout(function () {
                    navigate('/datav/primeIndustry');
                }, 1000);
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            return;
        });
    };
    // 主导产业通过输入而非选择的方式
    var onInputChange = function (index, v) {
        if (choosedItemName[index] !== v) {
            var choosedItemCodeCopy = choosedItemCode.slice();
            // choosedItemCodeCopy[chooseIndex] = "";
            choosedItemCodeCopy[index] = "";
            setChoosedItemCode(choosedItemCodeCopy);
        }
    };
    // 移除某一主导产业
    var onRemove = function (index) {
        var choosedItemCodeCopy = choosedItemCode.slice();
        choosedItemCodeCopy.splice(index, 1);
        setChoosedItemCode(choosedItemCodeCopy);
        var choosedItemNameCopy = choosedItemName.slice();
        choosedItemNameCopy.splice(index, 1);
        setChoosedItemName(choosedItemNameCopy);
    };
    useEffect(function () {
        getAreaList();
        isEdit && getDetail();
    }, []);
    return (React.createElement("div", { className: "prime_industry_add_edit" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 4 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24, pull: 2 },
                        React.createElement(Form.Item, { label: "\u5730\u57DF\uFF1A", name: "orgId", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                            React.createElement(Select, { style: { width: inputWidth }, allowClear: true, options: areaList, placeholder: "\u8BF7\u9009\u62E9", fieldNames: {
                                    label: 'regionName',
                                    value: 'orgId'
                                }, showSearch: true, filterOption: function (input, option) {
                                    var _a;
                                    return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase());
                                } })))),
                React.createElement(Form.List, { name: "agriculturalDetailsDtos", initialValue: [""], rules: [
                        {
                            validator: function (_, agriculturalDetailsDtos) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    console.log("_, agriculturalDetailsDtos", _, agriculturalDetailsDtos);
                                    agriculturalDetailsDtos.map(function (item) {
                                        if (item) {
                                            return Promise.resolve();
                                        }
                                        else {
                                            return Promise.reject("请输入");
                                        }
                                    });
                                    return [2 /*return*/];
                                });
                            }); },
                        },
                    ] }, function (fields, _a, _b) {
                    var add = _a.add, remove = _a.remove;
                    var errors = _b.errors;
                    console.log("fields", fields);
                    return (React.createElement(React.Fragment, null,
                        fields.map(function (_a, index) {
                            var key = _a.key, name = _a.name, restField = __rest(_a, ["key", "name"]);
                            return (React.createElement(Row, { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } },
                                React.createElement(Col, { span: 12 },
                                    React.createElement(Form.Item, __assign({}, restField, { validateTrigger: ["onChange", "onBlur"], label: "\u4E3B\u5BFC\u4EA7\u4E1A".concat(index + 1), name: [name, "name"], rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "请输入",
                                            },
                                        ] }),
                                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165", style: {
                                                width: inputWidth,
                                                paddingRight: "30px",
                                            }, maxLength: inputMaxLength, onChange: function (e) {
                                                onInputChange(index, e.target.value);
                                            } }))),
                                React.createElement(Col, { span: 3 },
                                    React.createElement(Button, { type: "primary", icon: React.createElement(SearchOutlined, null), className: "ml20", onClick: function () { return onSearch(index); } }, "\u67E5\u8BE2")),
                                React.createElement(Col, { span: 8 },
                                    React.createElement(Form.Item, __assign({}, restField, { name: [name, "sequence"], label: "\u987A\u5E8F", validateTrigger: ["onChange", "onBlur"], rules: [{ required: true, message: "请输入" }] }),
                                        React.createElement(InputNumber, { min: 1, precision: 0 }))),
                                React.createElement(Col, null, fields.length > 1 ? (React.createElement(MinusCircleOutlined, { className: "dynamic-delete-button", onClick: function () { return remove(name); } })) : null)));
                        }),
                        !isEdit && (React.createElement(Row, null,
                            React.createElement(Col, { span: 20, push: 2 },
                                React.createElement(Form.Item, null,
                                    React.createElement(Button, { type: "dashed", onClick: function () { return add(); }, style: { width: inputWidth }, icon: React.createElement(PlusOutlined, null) }, "\u6DFB\u52A0"),
                                    React.createElement(Form.ErrorList, { errors: errors })))))));
                }),
                isEdit && (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { span: 24, pull: 2 },
                            React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4\uFF1A", name: "createTime" }, operatorObj.createTime))),
                    React.createElement(Row, null,
                        React.createElement(Col, { span: 24, pull: 2 },
                            React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4\uFF1A", name: "updateTime" }, operatorObj.updateTime))),
                    React.createElement(Row, null,
                        React.createElement(Col, { span: 24, pull: 2 },
                            React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA\uFF1A", name: "operatorName" }, operatorObj.operatorName))))))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: function () { return navigate('/datav/primeIndustry'); } }, "\u53D6\u6D88"),
            React.createElement(Button, { type: "primary", onClick: function () { return onSubmit(); } }, "\u786E\u5B9A")),
        isShowModal && (React.createElement(AreaSearch, { isShowModal: isShowModal, setIsShowModal: setIsShowModal, sendChooseItem: sendChooseItem }))));
}));
export default AddOrEditPage;
