var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Input, Modal, Pagination, Table, TreeSelect } from "antd";
import "../index.scss";
import { ajaxOpenAi } from "../../../../utils/axios";
import { DATA_MANAGER } from "../../../../api/api";
var DataChooseModal = inject("staticStore")(observer(function (props) {
    var onClose = props.onClose, staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, defaultPagination = staticStore.defaultPagination, tableSearchWidth = staticStore.tableSearchWidth, projectCode = staticStore.projectCode;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({
        catalogId: undefined,
        name: "",
    }), search = _b[0], setSearch = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var getData = function (cur, size) {
        var catalogId = search.catalogId, name = search.name;
        var params = {
            currPage: cur,
            pageSize: size,
            projectCode: projectCode,
            catalogId: catalogId,
            name: name,
        };
        if (!params.catalogId) {
            delete params.catalogId;
        }
        ajaxOpenAi("get", DATA_MANAGER.getAssetList, params).then(function (res) {
            if (res.returnCode) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, dataList = _a.dataList, page = _a.page, totalSize = _a.totalSize;
                setData(dataList || []);
                setPagination(__assign(__assign({}, pagination), { current: page, total: totalSize }));
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var getOptions = function () {
        var params = {
            projectCode: projectCode,
        };
        ajaxOpenAi("get", DATA_MANAGER.getAssetCatalogList, params).then(function (res) {
            if (res.returnCode) {
                setOptions((res === null || res === void 0 ? void 0 : res.results) || []);
                setSearch(__assign(__assign({}, search), { catalogId: res === null || res === void 0 ? void 0 : res.results[0].id }));
            }
        });
    };
    var columns = [
        {
            title: "目录",
            dataIndex: "catalogName",
            key: "catalogName",
            ellipsis: true,
        },
        {
            title: "数据名称",
            dataIndex: "dataName",
            key: "dataName",
            ellipsis: true,
        },
        {
            title: "数据量",
            dataIndex: "itemCount",
            key: "itemCount",
            ellipsis: true,
        },
        {
            title: "编辑时间",
            dataIndex: "updateTime",
            key: "updateTime",
            ellipsis: true,
            sorter: function (v) { return new Date(v).getTime() - new Date(v).getTime(); },
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 200,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: "javascript:;", onClick: function () { return onClose(r); } }, "\u9009\u62E9"))); },
        },
    ];
    useEffect(function () {
        if (options.length > 0) {
            setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
            getData(1, 10);
        }
    }, [search.catalogId, options]);
    useEffect(function () {
        getOptions();
    }, []);
    return (React.createElement(Modal, { title: "\u9009\u62E9\u6570\u636E", open: true, maskClosable: false, footer: false, width: 1000, onCancel: function () { return onClose(); }, wrapClassName: "edit-modal" },
        React.createElement("div", { className: "data_manager_header mb20" },
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u76EE\u5F55\uFF1A"),
                React.createElement(TreeSelect, { treeLine: true, style: { width: tableSearchWidth }, treeData: options, placeholder: "\u8BF7\u9009\u62E9", showSearch: true, fieldNames: {
                        label: "name",
                        value: "id",
                        children: "assertCatalogList",
                    }, value: search.catalogId, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { catalogId: v })); });
                    }, allowClear: true })),
            React.createElement("div", null,
                React.createElement("span", null, "\u6570\u636E\u540D\u79F0\uFF1A"),
                React.createElement(Input, { value: search.name, placeholder: "\u8BF7\u8F93\u5165", style: { width: tableSearchWidth }, onChange: function (e) {
                        setSearch(function (pre) {
                            var _a, _b;
                            return (__assign(__assign({}, pre), { name: (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.trim() }));
                        });
                    }, allowClear: true, maxLength: inputMaxLength, onPressEnter: function () {
                        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
                        getData(1, 10);
                    } }))),
        React.createElement("div", { className: "table" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId" }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, current: pagination.current, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } })))));
}));
export default DataChooseModal;
