import { get } from "./axios";
import {TOOLS} from '../api/api'
import { message } from "antd";
import moment from 'moment'
import store from '../store/index'
import React from 'react'

export const validatorTel = (val) => {
  return /^(13[0-9]|14[01456879]|15[0-9]|16[2567]|17[0-8]|18[0-9]|19[0-9])\d{8}$/.test(
    val
  );
}
export const validatorEmail=(email) => {
  return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
    email
  );
}
// 校验 地区别名
export const validatorRegionalAlias=(regionalAlias) => {
  return /^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(
    regionalAlias
  );
}
// 校验 是否是url
export const validatorUrl=(regionalAlias) => {
  return /^(https?:\/\/)/.test(
    regionalAlias
  );
}

// 页面跳转,不带历史记录
export const replaceUrl = (pathname) => {
  const localPath = `${window.location.origin}/#${pathname}`
  window.location.replace(localPath)
};

// 将数字格式化为千分制
// export const formatterNumber = 
export const formatterNumber = (num)=>{
  if(isNaN(num)){
    console.error('请输入数字')
    return 
  }else{
    if(`${num}`.length<4){
      return `${num}`
    }else{
      return Number(num).toLocaleString()
    }
  }
}

// 通过url将图片上传至七牛云
export const uploadImgByUrl = async (url)=>{
  if(!url){
    return '未输入url'
  }
  const params={
    url
  }
  await get(TOOLS.uploadByUrl,params).then(res=>{
    if(res.state !== 200){
      message.error('图片上传失败')
      return null
    }
    return res
  })
}

// 通过url将图片临时上传至七牛云
export const uploadImgByUrlTemp = async (url)=>{
  if(!url){
    return '未输入url'
  }
  const params={
    url
  }
  await get(TOOLS.uploadByUrl,params).then(res=>{
    if(res.state !== 200){
      message.error('图片上传失败')
      return null
    }
    return res
  })
}

// 格式化大数据接口相关的签名
export const formatSign = (appKey, secret,params)=>{
  let data = {
    'appKey': appKey,
    'signTime':moment(new Date()).format('YYYYMMDDHHmmss'),
    ...params
  };
  // 按key升序排列 拼接字符串
  var param = Object.keys(data).sort().map(function(key){return key + '=' + data[key];}).join('&');
  // md5加密
  var signResult = md5(param + secret);
  // 小写
  signResult = signResult.toLowerCase();
  return signResult;
};
// 格式化参数
export const getParams = (appKey, secret,sign,paramsA) => {
  let params = `?appKey=${appKey}&sign=${sign}&signTime=${moment(new Date()).format('YYYYMMDDHHmmss')}`;
  if(paramsA){
    for(let key in paramsA){
      let item  = paramsA[key];
      params = `${params}&${[key]}=${item}`;
    }
  }
  return params;
};

// 全部替换
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

// 菜单处理
export const handleMenu = (list) => {
  return list && list.map((item) => {
    if (item.children && item.children.length > 0) {
      let children = handleMenu(item.children)  // 获取处理children之后的值
      children = Array.from(new Set(children)) // 去重，用来去除多个null
      let index = children.findIndex((item) => item === null)  // 找到为null的元素的下标
      if (index > -1) {
        children.splice(index, 1)  //删除为null的元素
      }
      let obj = { ...item, children: children }
      if (obj.children && obj.children.length === 0) {
        delete obj.children
      }
      return obj
    } else if (item.children && item.children.length === 0 || !item.children) {
      if (item.menutype === "OPERATION" || item.isShowInMenu === false) {
        return null
      }
      delete item.children
      return item
    }
  })
}

// 格式化菜单
export const formatterMenu = (menus)=>{
  try{
    let formatMenu = []
    let resultStr = JSON.stringify(menus)
    resultStr = replaceAll(resultStr,'funUri','key')
    resultStr = replaceAll(resultStr,'menuName','label')
    resultStr = replaceAll(resultStr,'menuId','menuid')
    resultStr = replaceAll(resultStr,'parentMenuId','parentmenuid')
    resultStr = replaceAll(resultStr,'menuType','menutype')
    resultStr = replaceAll(resultStr,'funUri','funuri')
    formatMenu = resultStr ? JSON.parse(resultStr) : []
    formatMenu = formatMenu.map((item)=>{
      if(item.children && item.children.length){
        item.children = item.children.map((item2)=>({
          ...item2,
          icon:React.createElement('img', { style:{width:'5px',height:'5px'} ,src:item2.icon})
        }))
      }
      return {
        ...item,
        icon:React.createElement('img', { style:{width:'5px',height:'5px'} ,src:item.icon})
      }
    })
    console.log('lib-formatMenu',formatMenu)
    // formatMenu = formatMenu.map((item)=>{
    //   return item.
    // })
    return formatMenu || []
  }catch{
    return []
  }
}

// 判断是否有该项功能 
export const getOperatorName= (key,type) => {
  const menus = store.appStore.menus
  const isAdmin = store.appStore.isAdmin
  const hash = location.hash
  const firstPath = hash.split('/')[1]
  const secondPath = hash.split('/')[2]

  let currentMenu = {}
  let operatorName = ''
  let isHasPower = false

  menus.forEach((item)=>{
    if(item.key === firstPath){
      item.children && item.children.forEach((item2)=>{
        if(item2.key === secondPath){
          currentMenu = Object.assign({},item2)
        }
      })
    }
  })
  currentMenu.children && currentMenu.children.forEach((item)=>{
    if(item.key === key){
      operatorName = item.label
      isHasPower = true
    }
  })

  if(sessionStorage.getItem('isSuperAdmin') === 'yes'){
    if(type === 'name'){
      return operatorName
    }else{
      return true
    }
  }

  if(type === 'name'){
    return operatorName
  }else{
    return isHasPower
  }
}