var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Form, Input, InputNumber, Modal, Row, TreeSelect, message, } from "antd";
import { get, postJson } from "../../../../utils/axios";
import { DATA_MENU_MANAGER } from "../../../../api/api";
var DataMenuAddModal = inject("staticStore")(observer(function (props) {
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, id = props.id, getData = props.getData, staticStore = props.staticStore;
    var inputWidth = staticStore.inputWidth;
    var form = Form.useForm()[0];
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    var _b = useState(false), isAdd = _b[0], setIsAdd = _b[1];
    var _c = useState(false), isCheck = _c[0], setIsCheck = _c[1];
    var _d = useState(false), isEdit = _d[0], setIsEdit = _d[1];
    var _e = useState(false), isDisabledForm = _e[0], setIsDisabledForm = _e[1];
    var _f = useState([]), options = _f[0], setOptions = _f[1];
    var _g = useState(false), isSubmit = _g[0], setIsSubmit = _g[1];
    var _h = useState({
        operatorName: "",
        createTime: "",
        updateTime: "",
    }), editInfo = _h[0], setEditInfo = _h[1];
    var getOptions = function () {
        var params = {
            status: "IN_USE",
        };
        get(DATA_MENU_MANAGER.getEnableTreeNode, params).then(function (res) {
            if (res) {
                var results = res === null || res === void 0 ? void 0 : res.results;
                var opt = [
                    {
                        catalogueName: "/",
                        childNodeList: results,
                        id: 0,
                    },
                ];
                setOptions(opt);
            }
        });
    };
    var handleOk = function () {
        form
            .validateFields()
            .then(function (values) {
            setIsSubmit(true);
            var params = __assign(__assign({}, values), { id: id });
            var url = isEdit
                ? DATA_MENU_MANAGER.update
                : DATA_MENU_MANAGER.save;
            postJson(url, params).then(function (res) {
                if (res) {
                    message.success("保存成功");
                    setTimeout(function () {
                        setIsShowModal(false);
                        getData();
                        setIsSubmit(false);
                    }, 1000);
                }
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            setIsSubmit(false);
            return;
        });
    };
    var getDetail = function () {
        get("".concat(DATA_MENU_MANAGER.getVOById, "/").concat(id)).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, catalogueName = _a.catalogueName, parentId = _a.parentId, sort = _a.sort, createTime = _a.createTime, updateTime = _a.updateTime, operatorName = _a.operatorName;
                form.setFieldsValue({
                    sort: sort,
                    catalogueName: catalogueName,
                    parentId: parentId,
                });
                setEditInfo({
                    createTime: createTime,
                    updateTime: updateTime,
                    operatorName: operatorName,
                });
            }
        });
    };
    useEffect(function () {
        getOptions();
    }, []);
    useEffect(function () {
        switch (modalType) {
            case "add":
                setTitle("新建菜单");
                setIsAdd(true);
                break;
            case "edit":
                setTitle("编辑菜单");
                setIsEdit(true);
                getDetail();
                break;
            case "check":
                setTitle("查看菜单");
                setIsDisabledForm(true);
                setIsCheck(true);
                getDetail();
                break;
        }
    }, [modalType]);
    return (React.createElement(Modal, { title: title, open: true, maskClosable: false, footer: false, width: 750, onCancel: function () { return setIsShowModal(false); }, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form },
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u83DC\u5355\u540D\u79F0\uFF1A", name: "catalogueName", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165", style: { width: inputWidth }, maxLength: 20, disabled: isDisabledForm })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u7236\u7EA7\u83DC\u5355\uFF1A", name: "parentId", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(TreeSelect, { treeLine: true, style: { width: inputWidth }, treeData: options, disabled: isDisabledForm, placeholder: "\u8BF7\u9009\u62E9", fieldNames: {
                                label: "catalogueName",
                                value: "id",
                                children: "childNodeList",
                            } })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u987A\u5E8F\uFF1A", name: "sort", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement(InputNumber, { min: 1, precision: 0, disabled: isDisabledForm, placeholder: "\u8BF7\u8F93\u5165" })))),
            !isAdd && (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4\uFF1A" },
                            React.createElement("div", null, editInfo.createTime)))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4" }, editInfo.updateTime))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA" }, editInfo.operatorName)))))),
        !isCheck && (React.createElement("div", { className: "modal-footer-btn" },
            React.createElement("div", { className: "footer-btn de-active mr10", onClick: function () { return setIsShowModal(false); } }, "\u53D6\u6D88"),
            React.createElement("div", { className: "footer-btn active", onClick: function () { return !isSubmit && handleOk(); } }, "\u786E\u5B9A")))));
}));
export default DataMenuAddModal;
