import { observable, action } from 'mobx';

class StaticStore{
  /* 一些观察的状态 */
  @observable inputMaxLength = 200
  @observable inputWidth = 350
  @observable tableSearchWidth = 200
  @observable defaultPagination = {
    total: 0,
    current: 1,
    pageSize: 10,
  };
  
  @observable menuTypes = {
    'CATALOGUE':{label:'目录',key:0},
    'MENU':{label:'菜单',key:1},
    'OPERATION':{label:'操作',key:2}
  }
  @observable tabData = [
    {
      label: '官 网',
      key: 'OFFICIAL_WEBSITE'
    },
    // {
    //   label: '移动端',
    //   key: 'MOBILE_TERMINAL'
    // },
    {
      label: '驾驶舱',
      key: 'SCREEN'
    }
  ]
  @observable roleType = [
    {
      label: '官 网',
      key: 1
    },
    {
      label: '驾驶舱',
      key: 2
    }
    ,
    {
      label: '后台',
      key: 3
    }
  ]
  @observable sourceList = [
    {
      label: '后台添加',
      value: 'ADMIN_WEBSITE'
    },
    {
      label: '官网注册',
      value: 'OFFICIAL_WEBSITE'
    },
    // {
    //   label: '移动端注册',
    //   key: 'MOBILE_TERMINAL'
    // },
    // {
    //   label: '大屏',
    //   key: 'SCREEN'
    // }
  ]
  @observable colLayout = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 12 },
    xxl: { span: 8 }
  }

  // @observable projectCode = window.location.href.indexOf('3ndata') >-1?9948596695872:8798680031808
  @observable projectCode = 9948596695872

  // 根据value获取sourceList的label
  @action getSourceItem = (value) => {
    let label = ''
    this.sourceList.forEach((item)=>{
      if(value === item.value){
        label = item.label
      }
    })
    return label
  }


}

export default StaticStore