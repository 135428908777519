var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ajaxOpenAi } from "../../../../utils/axios";
import { DATA_MANAGER } from "../../../../api/api";
var columnsDef = [
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
        width: 200
    },
    {
        title: "详情",
        dataIndex: "detail",
        key: "detail",
        render: function (text) { return (React.createElement("div", { style: { wordWrap: 'break-word', wordBreak: 'break-word', minWidth: 100 } }, text)); },
    },
];
export default function DataDemo(props) {
    var _this = this;
    var id = props.id;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(columnsDef), columns = _b[0], setColumns = _b[1];
    var getData = function () { return __awaiter(_this, void 0, void 0, function () {
        var data1, results1, data2, params, p2, params1, p1, data_1, data2Item_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (id !== 0 && !id)
                        return [2 /*return*/];
                    data1 = [];
                    results1 = [];
                    data2 = [];
                    params = {
                        id: id,
                    };
                    return [4 /*yield*/, ajaxOpenAi("get", DATA_MANAGER.getDataSimpleList, params).then(function (res) {
                            if (res.returnCode) {
                                if (((res === null || res === void 0 ? void 0 : res.results) || []).length === 1) {
                                    results1 = data2 = (res === null || res === void 0 ? void 0 : res.results) || [];
                                }
                                else {
                                    setData((res === null || res === void 0 ? void 0 : res.results) || []);
                                }
                            }
                            else {
                                setData([]);
                            }
                        })];
                case 1:
                    p2 = _a.sent();
                    params1 = {
                        id: id,
                    };
                    return [4 /*yield*/, ajaxOpenAi("get", DATA_MANAGER.getDataItemList, params1).then(function (res) {
                            if (res.returnCode) {
                                if (results1.length === 1) {
                                    data1 = (res === null || res === void 0 ? void 0 : res.results) || [];
                                }
                                else {
                                    var columns_1 = ((res === null || res === void 0 ? void 0 : res.results) || []).map(function (item) {
                                        var name = item.name, fieldId = item.fieldId;
                                        return {
                                            title: name,
                                            dataIndex: fieldId,
                                            key: fieldId,
                                            // width:100,
                                            render: function (text) { return (React.createElement("div", { title: text, style: { wordWrap: 'break-word', wordBreak: 'break-word', minWidth: 100 } }, text)); },
                                        };
                                    });
                                    setColumns(columns_1 || []);
                                }
                            }
                            else {
                                setColumns([]);
                            }
                        })];
                case 2:
                    p1 = _a.sent();
                    console.log('data1', data1);
                    console.log('data2', data2);
                    if (data1.length > 0) {
                        data_1 = [];
                        data2Item_1 = data2[0] || {};
                        data1.forEach(function (item, index) {
                            data_1[index] = {
                                name: item.name,
                                detail: data2Item_1[item.fieldId]
                            };
                        });
                        console.log('data', data_1);
                        setData(data_1);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getData();
    }, [id]);
    return (React.createElement("div", { className: "" },
        React.createElement(Table, { columns: columns, dataSource: data, pagination: false, rowKey: "reportId", scroll: { x: 1200 } })));
}
