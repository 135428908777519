var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Form, Input, message, } from "antd";
import { PlusOutlined, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postJson } from "../../../utils/axios";
import { AREA_MANGE } from "../../../api/api";
import DragDrop from "../../../components/dragDropComp";
var AreaMangeConfig = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var routes = location.hash.split('/') || [];
    var orgId = routes[routes.length - 1];
    var _a = useState([]), menuList = _a[0], setMenuList = _a[1];
    var _b = useState({}), detailData = _b[0], setDetailData = _b[1];
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var onSubmit = function () {
        var selectMenuList = menuList.filter(function (item) { return item.selected; });
        selectMenuList = selectMenuList.map(function (item) {
            if (item.list && item.list.length !== 0) {
                item.list = item.list.filter(function (item2) { return item2.selected; });
            }
            return item;
        });
        selectMenuList = selectMenuList.map(function (item, index) { return (__assign(__assign({}, item), { sort: index + 1 })); });
        var hasEmptyMenuName = false;
        selectMenuList.forEach(function (item) {
            if (item.menuName === '') {
                hasEmptyMenuName = true;
            }
        });
        if (hasEmptyMenuName) {
            message.warning('菜单名不能为空');
            return;
        }
        var params = __assign({ list: selectMenuList }, detailData);
        postJson(AREA_MANGE.save, params).then(function (res) {
            if (res) {
                message.success('保存成功');
                navigateBack();
            }
        });
    };
    var getDetails = function () {
        var params = {
            orgId: orgId
        };
        get(AREA_MANGE.get, params).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || {};
                setDetailData(results);
                form.setFieldsValue(results);
            }
        });
    };
    var getConfigMenus = function () {
        var params = {
            orgId: orgId
        };
        get(AREA_MANGE.getOrgMenu, params).then(function (res) {
            if (res) {
                var results = (res === null || res === void 0 ? void 0 : res.results) || [];
                var resultsFilter = results.filter(function (item) { return item.status === 1; });
                resultsFilter = resultsFilter.map(function (item) {
                    if (item.list && item.list.length > 0) {
                        item.list = item.list.filter(function (item) { return item.status === 1; });
                    }
                    console;
                    return item;
                });
                console.log('resultsFilter', resultsFilter);
                setMenuList(resultsFilter);
            }
        });
    };
    var addMenu = function () {
        var _a;
        var menuListCopy = menuList.slice();
        var orgId = (menuList.find(function (item) { return item.orgId; }) || {}).orgId;
        menuListCopy.push({
            "orgId": orgId,
            "menuId": "",
            "menuName": "",
            "parentMenuId": "",
            "menuType": 'EMBEDDISPLAY',
            "funUri": "",
            "status": 1,
            "deleted": true,
            "list": [],
            selected: true,
            type: 1,
            sort: ((_a = menuList[menuList.length - 1]) === null || _a === void 0 ? void 0 : _a.sort) + 1
        });
        setMenuList(menuListCopy);
    };
    var navigateBack = function () {
        navigate('/regionConfig/areaMange');
    };
    useEffect(function () {
        getDetails();
        getConfigMenus();
    }, []);
    return (React.createElement("div", { className: "addOrEditPage" },
        React.createElement("div", { className: "add_edit_cont" },
            React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 2 }, wrapperCol: { span: 16 }, form: form },
                React.createElement(Form.Item, { label: "\u5730\u57DF\uFF1A", name: "regionName" }, detailData.regionName),
                React.createElement(Form.Item, { label: "\u9A7E\u9A76\u8231\u540D\u79F0\uFF1A", name: "orgName" },
                    React.createElement(Input, { value: detailData.orgName, onChange: function (e) { setDetailData(function (pre) { return (__assign(__assign({}, pre), { orgName: e.target.value })); }); }, style: { width: inputWidth }, maxLength: 14, placeholder: "\u8BF7\u8F93\u5165" })),
                React.createElement(Form.Item, { label: "\u83DC\u5355\uFF1A", name: "bbb" },
                    React.createElement(React.Fragment, null,
                        React.createElement(DragDrop, { dataList: menuList, listStyles: { background: 'transform', width: 400 }, itemStyles: { background: 'transform' }, setMenuList: setMenuList }),
                        React.createElement(Button, { type: "dashed", onClick: function () { return addMenu(); }, style: { width: inputWidth }, icon: React.createElement(PlusOutlined, null) }, "\u65B0\u589E\u83DC\u5355"))))),
        React.createElement("div", { className: "add_edit_btn" },
            React.createElement(Button, { className: "mr24", onClick: navigateBack }, "\u53D6\u6D88"),
            React.createElement(Button, { type: "primary", onClick: function () { return onSubmit(); } }, "\u786E\u5B9A"))));
}));
export default AreaMangeConfig;
