import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ajaxOpenAi } from "../../../../utils/axios";
import { DATA_MANAGER } from "../../../../api/api";
var columns = [
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
    },
    {
        title: "详情",
        dataIndex: "detail",
        key: "detail",
        ellipsis: true,
    },
];
var updateFrequencyEnum = {
    '': '',
    1: '按版本更新',
    2: '每年',
    3: '每季度',
    4: '每月',
    5: '每周',
    6: '每天',
    7: '实时',
};
var dataTypeEnum = {
    1: 'API',
    2: '数据集',
};
export default function Illustrate(props) {
    var id = props.id;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var getData = function () {
        if (!id)
            return;
        var params = {
            id: id,
        };
        ajaxOpenAi("get", DATA_MANAGER.getDataDesc, params).then(function (res) {
            if (res.returnCode) {
                var _a = ((res === null || res === void 0 ? void 0 : res.results) || [])[0] || {}, dataTotal = _a.dataTotal, dataType = _a.dataType, majorVersion = _a.majorVersion, minerVersion = _a.minerVersion, updateFrequency = _a.updateFrequency, updateTime = _a.updateTime;
                var resData = [
                    {
                        name: "数据总量",
                        detail: dataTotal,
                    },
                    {
                        name: "更新频率",
                        detail: updateFrequencyEnum[updateFrequency],
                    },
                    {
                        name: "数据版本",
                        detail: "V".concat(majorVersion, ".").concat(minerVersion),
                    },
                    {
                        name: "最近更新时间",
                        detail: updateTime,
                    },
                    {
                        name: "数据类型",
                        detail: dataTypeEnum[dataType],
                    },
                ];
                setData(resData);
            }
            else {
                setData([]);
            }
        });
    };
    useEffect(function () {
        getData();
    }, [id]);
    return (React.createElement("div", { className: "" },
        React.createElement(Table, { columns: columns, dataSource: data, pagination: false, rowKey: "reportId" })));
}
