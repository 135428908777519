import React, { useEffect, useState } from 'react';
import { Layout, Menu,Popover,ConfigProvider } from 'antd';
import BreadcrumbAntd from './components/breadcrumbAntd/BreadcrumbAntd';
import CompanyMark from './components/companyMark/CompanyMark';
import {LOGIN, COMMON} from './api/api';
import { useNavigate } from "react-router-dom";
import { AppRouter, LoginRouter } from './routers/router'
import './App.scss'
import { postForm,postJson } from './utils/axios';
import logo from './styles/images/login/logo2.png'
import { inject, observer } from 'mobx-react';
import { replaceAll,handleMenu, formatterMenu } from './utils/lib'
import { adminMenus,configMenu } from './utils/menu'

const { Header, Content, Sider } = Layout;

const App =inject("appStore")(observer( (props) => {
  let hash = location.hash
  const {appStore} = props
  const {menus,setMenus,isAdminStore,setIsAdminStore,setCurrentPath} = appStore

  const [menusItems, setMenusItems] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [isShowLogout, setIsShowLogout] = useState(false)
  const [isShowApp, setIsShowApp] = useState(false)

  const navigate = useNavigate();
  const onMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(`/${keyPath.reverse().join('/')}`);
  }

  const setTip = (e,type)=>{
    e.preventDefault()
    console.log('type',type)
    setIsShowLogout(!!type)
  }

  const toLogout = ()=>{
    setMenus([])
    postForm(LOGIN.logout).then(res=>{
      if(res.state === 200) {
        navigate('/login')
      }
    })
  }

  const onOpenChange = (openKeys)=>{
    setOpenKeys(openKeys[1])
  }

  useEffect(() => {
    sessionStorage.setItem('hash',hash)
    setOpenKeys(hash?.split('/')[1])
    setSelectedKeys(hash?.split('/')[2])
    if(hash === '#/login'){
      setMenusItems([])
      sessionStorage.removeItem('allMenus')
      sessionStorage.setItem('hash','')
    }
  }, [hash])

  useEffect(()=>{
    if(hash !== '#/login'){
      let allMenus = []
      if(menus && menus.length === 0 || !menus){
        postJson(COMMON.getUserMenuTree).then((res)=>{
          if(res){
            sessionStorage.setItem('originMenus',JSON.stringify(res.results))
            allMenus = formatterMenu(res.results)
            if(sessionStorage.getItem('isSuperAdmin') === 'yes'){
              allMenus = [].concat(adminMenus).concat(allMenus || [])
            }
            setMenus(allMenus)
            let allMenusFormatted = handleMenu(allMenus)
            navigate(sessionStorage.getItem('hash').substring(1))
            setMenusItems(allMenusFormatted)
          }
        })
      }
    }
  },[hash,menus])

  useEffect(()=>{
    if(sessionStorage.getItem('platform-token')){
      setIsShowApp(true)
    }else{
      navigate('/login')
    }
  },[sessionStorage.getItem('platform-token')])

  useEffect(()=>{
    setCurrentPath(location.hash.replace('#',''))
  },[location.hash])

  return (
    <div>
      {
        // isLogin ? 
        // style={{'box-shadow':'rgba(78, 89, 105, 0.06) 0px 4px 10px 1px'}}
        isShowApp&&
        <Layout >
          <Header className="header" theme="light" >
            <img className="logo" src={logo}/>
            <div className='user-info' onMouseEnter={(e)=>setTip(e,1)}  onMouseLeave={(e)=>setTip(e,0)}>
              <Popover content={<div  className='logout' onClick={toLogout}>退出登录</div>} >
                {sessionStorage.getItem('userName') || ''}
              </Popover>
            </div>
          </Header>
          <Layout>
            <Sider width={200} className="site-layout-background">
              <Menu
                mode="inline"
                // defaultOpenKeys={['system','userManager']}
                defaultOpenKeys={[openKeys,selectedKeys]}
                openKeys={[openKeys]}
                selectedKeys={[selectedKeys]}
                style={{ height: '100%', borderRight: 0 }}
                items={menusItems}
                theme="light"
                onClick={(itemObj) => { onMenuClick(itemObj) }}
                onOpenChange={onOpenChange}
              />
            </Sider>
            <Layout>
              <BreadcrumbAntd menus={menus}/>
              <div className='business-cont'>
                <AppRouter />
                <CompanyMark />
              </div>
              <div>
              </div>
            </Layout>
          </Layout>
        </Layout>
          // : <LoginRouter />
      }
    </div>

  )
}))
export default App