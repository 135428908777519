var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Input, Pagination, Popconfirm, Select, Table, message, } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { get, postForm, postJson } from "../../../utils/axios";
import { PRIMEINDUSTRY, AREA_MANGE } from "../../../api/api";
import { getOperatorName } from "../../../utils/lib";
var PrimeIndustry = inject("staticStore", "appStore")(observer(function (props) {
    var staticStore = props.staticStore, appStore = props.appStore;
    var tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination, inputMaxLength = staticStore.inputMaxLength;
    var currentPath = appStore.currentPath;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(""), id = _c[0], setId = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var _e = useState({
        orgId: null,
        industryName: "",
    }), search = _e[0], setSearch = _e[1];
    var navigate = useNavigate();
    var columns = [
        {
            title: "地域",
            dataIndex: "orgRegion",
            key: "orgRegion",
            ellipsis: true,
        },
        {
            title: "主导产业",
            dataIndex: "industryName",
            key: "industryName",
            ellipsis: true,
        },
        {
            title: "顺序",
            dataIndex: "sequence",
            key: "sequence",
            ellipsis: true,
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            render: function (v, r) {
                return v === "IN_USE" ? (React.createElement("span", { className: "light_status" }, "\u4F7F\u7528\u4E2D")) : (React.createElement("span", null, "\u5DF2\u505C\u7528"));
            },
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 180,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                r.status === "IN_USE" ? (
                // <a href="javascript:;" className="mr14" onClick={() => OnOrOff("off",r.id)}>
                //   下架
                // </a>
                getOperatorName('down') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("off", r.id); } }, getOperatorName('down', 'name'))) : (
                // <a
                //   href="javascript:;"
                //   className="mr14"
                //   onClick={() => OnOrOff("on",r.id)}
                // >
                //   上架
                // </a>
                getOperatorName('up') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("on", r.id); } }, getOperatorName('up', 'name'))),
                r.status === "IN_USE" ? (
                // <span className="mr14 table_dark_option">编辑</span>
                getOperatorName('edit') && React.createElement("span", { className: "mr14 table_dark_option" }, getOperatorName('edit', 'name'))) : (
                // <a
                //   onClick={() => gotoAddPage("edit", r.id)}
                //   href="javascript:;"
                //   className="mr14"
                // >
                //   编辑
                // </a>
                getOperatorName('edit') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoAddPage("edit", r.id); } }, getOperatorName('edit', 'name'))),
                r.status === "IN_USE" ? (
                // <span className="mr14 table_dark_option">删除</span>
                getOperatorName('delete') && React.createElement("span", { className: "mr14 table_dark_option" }, getOperatorName('delete', 'name'))) : (React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u8BF7\u786E\u8BA4\u540E\u5220\u9664\uFF01", onConfirm: function () { return deleteData(r.id); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" }, getOperatorName('delete') && React.createElement("a", { href: "javascript:;", className: "mr14 table_delete" }, getOperatorName('delete', 'name')))))); },
        },
    ];
    var deleteData = function (id) {
        var params = {
            id: id,
        };
        postForm("".concat(PRIMEINDUSTRY.deleteById, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var getData = function (cur, size) {
        var params = __assign(__assign({ current: cur, pageSize: size }, search), { flag: 1, search: "" });
        console.log("params", params);
        postJson(PRIMEINDUSTRY.list, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var gotoAddPage = function (type, id) {
        switch (type) {
            case "add":
                navigate("".concat(currentPath, "/add"));
                break;
            case "edit":
                navigate("".concat(currentPath, "/edit/").concat(id));
                break;
        }
    };
    var getAreaList = function () {
        get(AREA_MANGE.getOrgList, { moduleEnum: 'SCREEN' }).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    var OnOrOff = function (type, id) {
        console.log("type", type);
        var params = {
            id: id,
        };
        get("".concat(PRIMEINDUSTRY.enableAndDisables, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("".concat(type === "on" ? "上架" : "下架", "\u6210\u529F"));
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.orgId]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement("div", { className: "table_list" },
        React.createElement("div", { className: "table_list_header" },
            getOperatorName('add') && React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return gotoAddPage("add"); } }, getOperatorName('add', 'name')),
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u5730\u57DF\uFF1A"),
                React.createElement(Select, { value: search.orgId, style: { width: tableSearchWidth }, allowClear: true, showSearch: true, options: areaList, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { orgId: v })); });
                    }, placeholder: "\u8BF7\u9009\u62E9", filterOption: function (input, option) {
                        var _a;
                        return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }, fieldNames: {
                        label: 'regionName',
                        value: 'orgId',
                    } })),
            React.createElement("div", null,
                React.createElement("span", null, "\u4E3B\u5BFC\u4EA7\u4E1A\uFF1A"),
                React.createElement(Input, { value: search.industryName, placeholder: "\u8BF7\u8F93\u5165", style: { width: tableSearchWidth }, onChange: function (e) {
                        setSearch(function (pre) { var _a, _b; return (__assign(__assign({}, pre), { industryName: (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.trim() })); });
                    }, allowClear: true, maxLength: inputMaxLength, onPressEnter: function () {
                        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
                        getData(1, 10);
                    } }))),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, current: pagination.current, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } })))));
}));
export default PrimeIndustry;
