import React from 'react';
import './index.scss';
export default function CompanyMark() {
    return (React.createElement("div", { className: 'company-mark' },
        React.createElement("div", null,
            React.createElement("a", null, "\u5E2E\u52A9"),
            React.createElement("a", null, "\u9690\u79C1"),
            React.createElement("a", null, "\u6761\u6B3E")),
        React.createElement("div", null, "copyright@\u8D85\u7EA7\u7801\u79D1\u6280")));
}
