var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Row, Col, message, Select, Radio, Space, InputNumber, Popconfirm, } from "antd";
import { get, postJson } from "../../../../utils/axios";
import { REPORT } from "../../../../api/api";
import "../index.scss";
import MyTag from "../../../../components/myTag";
import UploadFile from "../../../../components/uploadFile/UploadFile";
import UploadImg from "../../../../components/uploadFile/UploadImg";
import _ from "lodash";
var inputWidth = 350;
export default function EditModal(props) {
    var _a;
    var modalType = props.modalType, setIsShowModal = props.setIsShowModal, reportId = props.reportId, getData = props.getData;
    var _b = useState(""), title = _b[0], setTitle = _b[1];
    var _c = useState(false), isDisabledForm = _c[0], setIsDisabledForm = _c[1];
    var form = Form.useForm()[0];
    var _d = useState(false), isAdd = _d[0], setIsAdd = _d[1];
    var _e = useState(false), isCheck = _e[0], setIsCheck = _e[1];
    var _f = useState({}), searchParams = _f[0], setSearchParams = _f[1];
    var _g = useState(false), isEdit = _g[0], setIsEdit = _g[1];
    var _h = useState([]), childTags = _h[0], setChildTags = _h[1];
    var _j = useState(true), isFirstShow = _j[0], setIsFirstShow = _j[1];
    var _k = useState({
        updateTime: "",
        createTime: "",
        type: "PRODUCTION_REPORT",
        title: "",
        status: "",
        tagList: [],
        authority: "",
        priceSimpleDTO: [],
        fileSimpleDTOList: [],
        operatorName: "",
        discountPrice: null,
        originalPrice: null,
        coverImage: '',
    }), formObj = _k[0], setFormObj = _k[1];
    var loginName = "admin";
    var handleOk = _.debounce(function () {
        form
            .validateFields()
            .then(function (values) {
            var _a, _b;
            var authority = values.authority, coverImage = values.coverImage, fileSimpleDTOList = values.fileSimpleDTOList, tagList = values.tagList, title = values.title, discountPrice = values.discountPrice, originalPrice = values.originalPrice, type = values.type;
            var priceSaveRequest = discountPrice && originalPrice
                ? {
                    discountPrice: discountPrice,
                    originalPrice: originalPrice,
                }
                : null;
            var params = {
                authority: authority,
                coverImage: coverImage,
                fileSaveRequest: {
                    resourceId: ((_a = fileSimpleDTOList[0]) === null || _a === void 0 ? void 0 : _a.fileId) ||
                        ((_b = fileSimpleDTOList[0]) === null || _b === void 0 ? void 0 : _b.uid) ||
                        null,
                },
                priceSaveRequest: priceSaveRequest,
                tags: tagList.join(","),
                title: title,
                type: type,
            };
            console.log("params", params);
            var url = "";
            if (isAdd) {
                url = REPORT.saveReport;
            }
            else {
                url = REPORT.updateReport;
                params = __assign(__assign({}, params), { id: reportId });
            }
            postJson(url, params).then(function (res) {
                if (res) {
                    message.success("保存成功");
                    setTimeout(function () {
                        setIsShowModal(false);
                        getData();
                    }, 1000);
                }
                else {
                    message.error('保存失败');
                }
            });
        })
            .catch(function (info) {
            console.log("表单校验失败", info);
            return;
        });
    }, 250, { maxWait: 2000 });
    var handleCancel = function () {
        setIsShowModal(false);
    };
    var typeChange = function (value) {
        if (value === "INTELLIGENT_REPORT") {
            setFormObj(function (pre) { return (__assign(__assign({}, pre), { type: "INTELLIGENT_REPORT" })); });
        }
        else if (value === "PRODUCTION_REPORT") {
            setFormObj(function (pre) { return (__assign(__assign({}, pre), { type: "PRODUCTION_REPORT" })); });
        }
    };
    var getDetail = function () {
        get("".concat(REPORT.checkReport, "/").concat(reportId)).then(function (res) {
            var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, _b = _a.updateTime, updateTime = _b === void 0 ? "" : _b, _c = _a.createTime, createTime = _c === void 0 ? "" : _c, _d = _a.type, type = _d === void 0 ? "" : _d, _e = _a.title, title = _e === void 0 ? "" : _e, _f = _a.tagList, tagList = _f === void 0 ? [] : _f, _g = _a.status, status = _g === void 0 ? "" : _g, _h = _a.authority, authority = _h === void 0 ? "" : _h, _j = _a.priceSimpleDTO, priceSimpleDTO = _j === void 0 ? {} : _j, _k = _a.fileSimpleDTOList, fileSimpleDTOList = _k === void 0 ? [] : _k, _l = _a.operatorName, operatorName = _l === void 0 ? "" : _l, _m = _a.coverImage, coverImage = _m === void 0 ? '' : _m;
            fileSimpleDTOList = (fileSimpleDTOList || []).map(function (item) { return ({
                uid: "".concat(item.resourceId),
                name: item.originalFileName,
                status: "done",
                url: item.resourceUrl,
            }); });
            var _o = priceSimpleDTO || {}, _p = _o.discountPrice, discountPrice = _p === void 0 ? '' : _p, _q = _o.originalPrice, originalPrice = _q === void 0 ? '' : _q;
            console.log('coverImage', coverImage);
            setFormObj({
                updateTime: updateTime,
                createTime: createTime,
                status: status,
                type: type,
                title: title,
                tagList: tagList,
                authority: authority,
                priceSimpleDTO: priceSimpleDTO,
                fileSimpleDTOList: fileSimpleDTOList,
                operatorName: operatorName,
                discountPrice: discountPrice,
                originalPrice: originalPrice,
                coverImage: coverImage,
            });
            form.setFieldsValue({
                updateTime: updateTime,
                createTime: createTime,
                status: status,
                type: type,
                title: title,
                tagList: tagList,
                authority: authority,
                priceSimpleDTO: priceSimpleDTO,
                fileSimpleDTOList: fileSimpleDTOList,
                operatorName: operatorName,
                discountPrice: discountPrice,
                originalPrice: originalPrice,
                coverImage: coverImage
                // [{url:coverImage}],
            });
        });
    };
    var validateInput = function (value) {
        return /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value);
    };
    var validatorTags = function () {
        if ((formObj === null || formObj === void 0 ? void 0 : formObj.tagList.length) > 5) {
            return Promise.reject("标签不能超过5个！");
        }
        else if ((formObj === null || formObj === void 0 ? void 0 : formObj.tagList.length) === 0) {
            return Promise.reject("请填写标签");
        }
        else {
            return Promise.resolve();
        }
    };
    var validatorFiles = function () {
        if ((formObj === null || formObj === void 0 ? void 0 : formObj.fileSimpleDTOList.length) === 0) {
            return Promise.reject();
        }
        else {
            return Promise.resolve();
        }
    };
    var validatorImages = function () {
        if ((formObj === null || formObj === void 0 ? void 0 : formObj.coverImage.length) === 0) {
            return Promise.reject();
        }
        else {
            return Promise.resolve();
        }
    };
    var validatorOriginalPrice = function () {
        if (formObj.originalPrice <= 0) {
            return Promise.reject("请检查输入是否正确！");
        }
        if (formObj.originalPrice && formObj.discountPrice) {
            if (formObj.originalPrice < formObj.discountPrice) {
                return Promise.reject("原价需大于等于折后价");
            }
            else {
                return Promise.resolve();
            }
        }
        else {
            if (formObj.originalPrice) {
                return Promise.resolve();
            }
            else {
                return Promise.reject();
            }
        }
    };
    var validatorDiscountPrice = function () {
        if (formObj.discountPrice <= 0) {
            return Promise.reject("请检查输入是否正确！");
        }
        else {
            return Promise.resolve();
        }
    };
    var discountPriceBlur = function () {
        form.validateFields(["originalPrice"]);
    };
    var getTags = function (tags) {
        setChildTags(tags);
        setFormObj(function (pre) {
            return __assign(__assign({}, pre), { tagList: tags });
        });
        form.setFieldsValue({
            tagList: tags,
        });
    };
    var getFileList = function (data) {
        setFormObj(function (pre) {
            return __assign(__assign({}, pre), { fileSimpleDTOList: data });
        });
        form.setFieldsValue({
            fileSimpleDTOList: data,
        });
    };
    var getUrl = function (data) {
        console.log('data', data);
        setFormObj(function (pre) {
            return __assign(__assign({}, pre), { coverImage: data });
        });
        form.setFieldsValue({
            coverImage: data,
        });
    };
    useEffect(function () {
        switch (modalType) {
            case "add":
                setTitle("新建报告");
                setIsAdd(true);
                // getMenuList([]);
                break;
            case "edit":
                setTitle("编辑报告");
                setIsEdit(true);
                break;
            case "check":
                setTitle("查看报告");
                setIsDisabledForm(true);
                setIsCheck(true);
                break;
        }
    }, [modalType]);
    useEffect(function () {
        (modalType === "edit" || modalType === "check") && getDetail();
    }, [modalType]);
    useEffect(function () {
        if (!isFirstShow) {
            form.validateFields(["tagList"]);
        }
        else {
            setIsFirstShow(false);
        }
    }, [childTags]);
    useEffect(function () {
        form.setFieldsValue({
            type: "PRODUCTION_REPORT",
        });
    }, []);
    return (React.createElement(Modal, { title: title, open: true, maskClosable: false, footer: false, width: 750, onCancel: handleCancel, wrapClassName: "edit-modal" },
        React.createElement(Form, { name: "basic", autoComplete: "off", validateTrigger: "onBlur", labelCol: { span: 6 }, wrapperCol: { span: 16 }, form: form, onFinish: handleOk },
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { label: "\u62A5\u544A\u7C7B\u578B\uFF1A", name: "type", rules: [{ required: true, message: "请选择正确的一项！" }] },
                        React.createElement(Select, { style: { width: 350 }, onChange: function (value) { return typeChange(value); }, value: formObj.type || "PRODUCTION_REPORT", options: [
                                { value: "PRODUCTION_REPORT", label: "产研报告" },
                                { value: "INTELLIGENT_REPORT", label: "智能报告" },
                            ], allowClear: true, disabled: isDisabledForm })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 20, push: 1 },
                    React.createElement(Form.Item, { label: "\u62A5\u544A\u540D\u79F0\uFF1A", name: "title", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement("div", { className: "role-name" },
                            React.createElement(Input, { value: formObj.title, maxLength: 200, style: { width: inputWidth, marginRight: "20px" }, disabled: isDisabledForm, onChange: function (e) {
                                    setFormObj(function (pre) { return (__assign(__assign({}, pre), { title: e.target.value })); });
                                }, placeholder: "\u8BF7\u8F93\u5165" }))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 6, push: 4 },
                    React.createElement(Form.Item, { label: "\u6807\u7B7E\uFF1A", name: "tagList", rules: [
                            { required: true, message: "" },
                            { validator: validatorTags },
                        ] })),
                React.createElement(Col, null,
                    React.createElement("div", { className: "role-name tag-item" },
                        React.createElement(MyTag, { sendTags: getTags, validateInput: validateInput, value: formObj.tagList, isCheck: isCheck, disabled: isDisabledForm })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 20, push: 1 },
                    React.createElement(Form.Item, { label: "\u6743\u9650\uFF1A", name: "authority", rules: [{ required: true, message: "请检查输入是否正确！" }] },
                        React.createElement("div", { className: "role-name" },
                            React.createElement(Radio.Group, { disabled: isDisabledForm, onChange: function (e) {
                                    if (e.target.value === "PROBATION") {
                                        setFormObj(function (pre) { return (__assign(__assign({}, pre), { authority: "PROBATION" })); });
                                    }
                                    else if (e.target.value === "PURCHASE") {
                                        setFormObj(function (pre) { return (__assign(__assign({}, pre), { authority: "PURCHASE" })); });
                                    }
                                }, value: formObj.authority || "" },
                                React.createElement(Radio, { value: "PROBATION" }, "\u8BD5\u8BFB"),
                                React.createElement(Radio, { value: "PURCHASE" }, "\u8D2D\u4E70")))))),
            formObj.type === "INTELLIGENT_REPORT" && (React.createElement("div", null,
                React.createElement(Row, null,
                    React.createElement(Col, { span: 20, push: 1 },
                        React.createElement(Form.Item, { label: "\u539F\u4EF7\uFF1A", name: "originalPrice", rules: [
                                { required: true, message: '' },
                                { validator: validatorOriginalPrice },
                            ] },
                            React.createElement("div", { className: "role-name" },
                                React.createElement(InputNumber, { addonBefore: "\uFFE5", 
                                    // min={1}
                                    max: 1000000, disabled: isDisabledForm, onChange: function (value) {
                                        setFormObj(__assign(__assign({}, formObj), { originalPrice: value }));
                                    }, value: formObj === null || formObj === void 0 ? void 0 : formObj.originalPrice }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 20, push: 1 },
                        React.createElement(Form.Item, { label: "\u6298\u540E\u4EF7\uFF1A", name: "discountPrice", rules: [
                                { required: true, message: "" },
                                { validator: validatorDiscountPrice },
                            ] },
                            React.createElement("div", { className: "role-name" },
                                React.createElement(Space, { direction: "vertical" },
                                    React.createElement(InputNumber, { addonBefore: "\uFFE5", 
                                        // min={1}
                                        max: 1000000, disabled: isDisabledForm, onChange: function (value) {
                                            setFormObj(__assign(__assign({}, formObj), { discountPrice: value }));
                                        }, value: formObj === null || formObj === void 0 ? void 0 : formObj.discountPrice, onBlur: discountPriceBlur })))))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 20, push: 1 },
                    React.createElement(Form.Item, { label: "\u4E0A\u4F20\u9644\u4EF6\uFF1A", name: "fileSimpleDTOList", rules: [
                            { required: true, message: "请上传附件！" },
                            { validator: validatorFiles },
                        ] }, isDisabledForm ? ((_a = formObj === null || formObj === void 0 ? void 0 : formObj.fileSimpleDTOList) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (React.createElement("div", { className: "role-name" }, item.name)); })) : (React.createElement(UploadFile, { config: { maxCount: 1 }, fileList: formObj === null || formObj === void 0 ? void 0 : formObj.fileSimpleDTOList, sendData: getFileList, action: "/apiInterface/interface/hydra-opinion-platform/api/v1/manage/analyseReport/uploadFile", data: { fileType: "ATTACHMENT" }, fileType: 'pdf' }))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 20, push: 1 },
                    React.createElement(Form.Item, { label: "\u5C01\u9762\uFF1A", name: "coverImage", rules: [
                            { required: true, message: "请上传封面！" },
                            { validator: validatorImages },
                        ] }, isDisabledForm ? (React.createElement("img", { className: "preview-coverImage", src: (formObj === null || formObj === void 0 ? void 0 : formObj.coverImage) || '' })) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "role-name" },
                            React.createElement(UploadImg, { sendUrl: getUrl, url: (formObj === null || formObj === void 0 ? void 0 : formObj.coverImage) ? formObj === null || formObj === void 0 ? void 0 : formObj.coverImage : '' })),
                        React.createElement("div", { className: "point-out" }, "\u652F\u6301\u6269\u5C55\u540D\uFF1A.png .jpg .jpeg .bmp .svg .ico...")))))),
            !isAdd && (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u521B\u5EFA\u65F6\u95F4\uFF1A", name: "createTime" }, formObj.createTime))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u4FEE\u6539\u65F6\u95F4\uFF1A", name: "updateTime" }, formObj.updateTime))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Form.Item, { label: "\u64CD\u4F5C\u4EBA\uFF1A", name: "updateName" }, formObj.operatorName)))))),
        !isCheck && (React.createElement("div", { className: "modal-footer-btn" },
            React.createElement("div", { className: "footer-btn de-active mr10", onClick: handleCancel }, "\u53D6\u6D88"),
            !isEdit ? (React.createElement("div", { className: "footer-btn active", onClick: handleOk }, "\u786E\u5B9A")) : (React.createElement(Popconfirm, { title: "\u786E\u8BA4\u4FEE\u6539\u5C06\u8986\u76D6\u8BE5\u6761\u6570\u636E\u8BB0\u5F55\uFF0C\u786E\u8BA4\u4FEE\u6539", onConfirm: function () { return handleOk(); }, okText: "\u662F", cancelText: "\u5426" },
                React.createElement("div", { className: "footer-btn active" }, "\u786E\u5B9A")))))));
}
