export const COMMON={
  'ProvinceCity':'/hydra-opinion-platform/api/v1/policyInfo/administrative/getCodeNameNodeTree',
  'getList':'/hydra-opinion-platform/api/v1/common/dropDown/getList',
  'getUserMenuTree':'/hydra-opinion-platform/api/v1/manage/menu/getUserMenuTree',
  'generateDataScreenFreeLoginUrl':'/hydra-opinion-platform/api/v1/manage/sys/generateDataScreenFreeLoginUrl',//生成免登地址
  'getFreeAddressByOrgId':'/hydra-opinion-platform/api/v1/manage/sys/getFreeAddressByOrgId',//根据大屏区域ID获取免登地址
}
export const LOGIN={
  'login':'/hydra-opinion-platform/api/v1/manage/login/login',
  'logout':'/hydra-opinion-platform/api/v1/manage/login/logout',
}
export const MENU={
  'list':'/hydra-opinion-platform/api/v1/manage/menu/list',
  'getUserMenuTree':'/hydra-opinion-platform/api/v1/manage/menu/getUserMenuTree',
  'saveMenu':'/hydra-opinion-platform/api/v1/manage/menu/saveMenu',
  'operate':'/hydra-opinion-platform/api/v1/manage/menu/operate',
  'del':'/hydra-opinion-platform/api/v1/manage/menu/del',
  'selectByMenuId':'/hydra-opinion-platform/api/v1/manage/menu/selectByMenuId',
  'getMenuTreeByModule':'/hydra-opinion-platform/api/v1/manage/menu/getMenuTreeByModule',
  'listRecord':'/hydra-opinion-platform/api/v1/manage/menu/listRecord',
}
export const ROLE={
  'list':'/hydra-opinion-platform/api/v1/manage/role/list',
  'optionList':'/hydra-opinion-platform/api/v1/manage/role/optionList',
  'del':'/hydra-opinion-platform/api/v1/manage/role/del',
  'saveRoleMenu':'/hydra-opinion-platform/api/v1/manage/role/saveRoleMenu',
  'getDetail':'/hydra-opinion-platform/api/v1/manage/role/getDetail',
  'checkDefaultRole':'/hydra-opinion-platform/api/v1/manage/role/checkDefaultRole',
}
export const USER={
  'userRoleAnalysis':'/hydra-opinion-platform/api/v1/manage/user/userRoleAnalysis',
  'save':'/hydra-opinion-platform/api/v1/manage/user/save',
  'list':'/hydra-opinion-platform/api/v1/manage/user/list',
  'operate':'/hydra-opinion-platform/api/v1/manage/user/operate',
  'getUser':'/hydra-opinion-platform/api/v1/manage/user/getUser',
  'del':'/hydra-opinion-platform/api/v1/manage/user/del',
  'phone':'/hydra-opinion-platform/api/v1/manage/user/check/phone',
  'email':'/hydra-opinion-platform/api/v1/manage/user/check/email',
  'getUserInfoByOrgId':'/hydra-opinion-platform/api/v1/manage/user/getUserInfoByOrgId', //获取当前区域用户授权信息
}
export const MESSAGE={
  'list':'/hydra-opinion-platform/api/v1/manage/smsMessage/list',
}
export const UTILS={
  'uploadImageUrl':'/hydra-opinion-platform/api/v1/common/hydraFile/uploadImageUrl',
}
export const REPORT={
  'deleteReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/deleteById',
  'offlineReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/downWayById',
  'onlineReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/putAwayById',
  'checkReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/getDetailById',
  'pagenationReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/pageSearch',
  'previewReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/previewByReportId',
  'saveReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/save',
  'updateReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/update',
  'uploadReport':'/hydra-opinion-platform/api/v1/manage/analyseReport/uploadFile'
}

// 站点管理
export const SITEMANAGER={
  'blacklist':'/opinion-shiwei/v1/black-list/page-list',    // 分页列表查询
  'siteNumber':'/opinion-shiwei/v1/black-list/site_number',    // 站点数据查询
  'exist':'/opinion-shiwei/v1/black-list/exist',    // 判断是否存在
  'add':'/opinion-shiwei/v1/black-list/add',    // 新增黑名单
  'delete':'/opinion-shiwei/v1/black-list/delete',    // 移除黑名单
  'update':'/opinion-shiwei/v1/black-list/update',    // 更新黑名单
}

// 工具类url
export const TOOLS = {
  'uploadByUrl':'/hydra-opinion-platform/api/v1/common/hydraFile/uploadByUrl',    // 通过url上传图片
  'uploadTempByUrl':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/uploadTempByUrl',    // 上传临时图片url接口
}

// 主导产业配置
export const PRIMEINDUSTRY = {
  'getProvinceCity':'/hydra-opinion-platform/api/v1/manage/opinion/agriculturalGeographicalIndications/getProvinceCity',    // 模糊省市级联信息
  'getList':'/hydra-opinion-platform/api/v1/manage/opinion/agriculturalGeographicalIndications/getList',    // 分页查询农业地理标识接口
  'getEnableAll':'/hydra-opinion-platform/api/v1/manage/region-open/getEnableAll',    // 查询全部组织区域接口
  'save':'/hydra-opinion-platform/api/v1/manage/opinion/industry/save',    // 保存组织主导产业关联
  'list':'/hydra-opinion-platform/api/v1/manage/opinion/industry/list',    // 分页查询主导产业
  'deleteById':'/hydra-opinion-platform/api/v1/manage/opinion/industry/deleteById',    // 删除
  'enableAndDisables':'/hydra-opinion-platform/api/v1/manage/opinion/industry/enableAndDisables',    // 上架/下架
  'industryAdd':'/hydra-opinion-platform/api/v1/manage/opinion/industry/add',    // 上架/下架
  'getDetailById':'/hydra-opinion-platform/api/v1/manage/opinion/industry/getDetailById',    // 查询组织关联主导产业明细
  'edit':'/hydra-opinion-platform/api/v1/manage/opinion/industry/edit',    // 修改组织主导产业关联

}
// 领导人配置
export const LEADER = {
  'getSimpleInfoByLikeName':'/hydra-opinion-platform/api/v1/manage/opinion/leader/getSimpleInfoByLikeName',    // 模糊查询领导人接口
  'getDetailByLeaderId':'/hydra-opinion-platform/api/v1/manage/opinion/leader/getDetailByLeaderId',    // 查询领导人详情接口
  'pageSearch':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/pageSearch',    // 分页查询组织领导人接口
  'deleteById':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/deleteById',    // 删除组织领导人接口
  'downShelvesById':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/downShelvesById',    // 下架组织领导人接口
  'upShelvesById':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/upShelvesById',    // 上架组织领导人接口
  'save':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/save',    // 添加组织领导人数据接口
  'update':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/update',    // 编辑组织领导人数据接口
  'getDetailById':'/hydra-opinion-platform/api/v1/manage/opinion/orgLeader/getDetailById',    // 查询组织领导人详情接口
}
// 数据产品订阅-数据管理
export const DATA_MANAGER = {
  'save':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/save',    // 添加目录数据接口
  'update':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/update',    // 编辑目录数据接口
  'delete':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/delete',    //删除目录数据接口 
  'upShelves':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/upShelves',    //上架目录数据接口 
  'downShelves':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/downShelves',    // 下架目录数据接口
  'pageSearch':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/pageSearch',    //目录数据分页查询接口 
  'getById':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogueData/getById',    //查看目录数据接口
  'getAssetCatalogList':'/open-api/dol/getAssetCatalogList',    //获取三农在线下的目录列表 
  'getAssetList':'/open-api/dol/getAssetList',    //获取当前目录下全部数据资产
  'getDataSimpleList':'/open-api/dol/getDataSimpleList',    //获取数据管理的示例数据
  'getDataItemList':'/open-api/dol/getDataItemList',    //获取数据管理的数据项
  'getDataDesc':'/open-api/dol/getDataDesc',    //获取数据管理的数据说明
}
// 数据产品订阅-目录管理
export const DATA_MENU_MANAGER = {
  'getEnableTreeNode':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/getEnableTreeNode',    // 目录树接口
  'save':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/save',    // 添加目录接口
  'pageSearch':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/pageSearch',    // 目录分页查询接口
  'downShelves':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/downShelves',    // 下架目录接口
  'upShelves':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/upShelves',    // 上架目录接口
  'delete':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/delete',    // 删除目录接口
  'getVOById':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/getVOById',    // 查看目录接口
  'update':'/hydra-opinion-platform/api/v1/manage/catalogue/catalogue/update',    // 编辑目录接口
}
// 驾驶舱配置-地域管理
export const AREA_MANGE = {
  'pageSearch':'/hydra-opinion-platform/api/v1/manage/region-open/getList', // 列表
  'getOrgList':'/hydra-opinion-platform/api/v1/manage/region-open/getOrgList', // 获取已开通组织
  'add':'/hydra-opinion-platform/api/v1/manage/region-open/add', // 新增区域开通
  'get':'/hydra-opinion-platform/api/v1/manage/region-open/get', // 获取配置详情
  'getOrgMenu':'/hydra-opinion-platform/api/v1/system/org/menu/getOrgMenu', // 获取配置菜单
  'save':'/hydra-opinion-platform/api/v1/system/org/menu/save', // 新增/编辑配置菜单
  'update':'/hydra-opinion-platform/api/v1/manage/region-open/update', // 编辑区域开通
  'enableAndDisable':'/hydra-opinion-platform/api/v1/manage/sys/enableAndDisable', // 启用/禁用
  'deleted':'/hydra-opinion-platform/api/v1/manage/region-open/deleted', // 删除
}
// 驾驶舱配置-首页
export const FIRST = {
  'save':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/save', // 新增/编辑简介配置
  'getDetailByOrgId':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/getDetailByOrgId', // 获取配置详情
  'addIndustry':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/addIndustry', // 新增/编辑产业数据配置
  'deleted':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/deleted', // 删除
  'getIndustryDetail':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/getIndustryDetail', // 获取产业数据配置
  'getDetail':'/hydra-opinion-platform/api/v1/manage/opinion/opinionRegionData/getDetail', // 获取产业数据配置详情
}
// 驾驶舱配置-智能报告
export const SMARTREPORT = {
  'save':'/hydra-opinion-platform/api/v1/manage/intelligentReport/save', // 保存分析报告接口
  'pageSearch':'/hydra-opinion-platform/api/v1/manage/intelligentReport/pageSearch', // 分页查询分析报告接口
  'getDetailById':'/hydra-opinion-platform/api/v1/manage/intelligentReport/getDetailById', // 查询分析报告详情接口
  'deleteById':'/hydra-opinion-platform/api/v1/manage/intelligentReport/deleteById', // 删除分析报告接口
  
  'downWayById':'/hydra-opinion-platform/api/v1/manage/analyseReport/downWayById', // 下架分析报告接口
  'putAwayById':'/hydra-opinion-platform/api/v1/manage/analyseReport/putAwayById', // 上架分析报告接口
  // 'getDetailById':'/hydra-opinion-platform/api/v1/manage/analyseReport/getDetailById', // 查询分析报告详情接口
  
}