/**
 * 使用 antD 4.x 的Upload组件进行文件流上传
 *
 *
 */
/**
 * 使用扩展名白名单统一限制，image/jpeg,image/gif,image/png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,video/avi,video/mp4,application/pdf,application/json,image/bmp,image/tiff,image/webp,image/apng,application/octet-stream,application/wps-office.xls，
 * 另根据业务场景补充格式：doc，docx，ppt，pptx，jpg等也都处理成白名单
 */
import { Button, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import "./index.scss";
export default function UploadFile(props) {
    var config = props.config, sendData = props.sendData, action = props.action, data = props.data, fileType = props.fileType, disabled = props.disabled;
    var _a = (config || {}).maxCount, maxCount = _a === void 0 ? 3 : _a;
    var _b = useState(props.fileList), fileList = _b[0], setFileList = _b[1];
    var beforeUpload = function (file) {
        console.log('file', file.type);
        var mark = true;
        if (typeof fileType === 'string') {
            switch (fileType) {
                case 'pdf':
                    var isPdf = file.type === 'application/pdf';
                    if (!(isPdf)) {
                        message.error("请确认上传的文件格式");
                        mark = false;
                    }
            }
        }
        var fileSize = file.size / 1024 / 1024 < 100;
        if (!(fileSize)) {
            message.error("请确认上传的文件大小");
        }
        return mark && fileSize || Upload.LIST_IGNORE;
    };
    var onChange = function (info) {
        var _a, _b;
        if (info.file.status === "done") {
            if (((_b = (_a = info === null || info === void 0 ? void 0 : info.file) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.state) === 200) {
                message.success("".concat(info.file.name, " \u4E0A\u4F20\u6210\u529F"));
            }
            else {
                info === null || info === void 0 ? void 0 : info.fileList.forEach(function (item, index) {
                    if (item.response.state !== 200) {
                        info === null || info === void 0 ? void 0 : info.fileList.splice(index, 1);
                    }
                });
                message.error("".concat(info.file.name, " \u4E0A\u4F20\u5931\u8D25"));
            }
        }
        else if (info.file.status === "error") {
            message.error("".concat(info.file.name, " \u4E0A\u4F20\u5931\u8D25"));
        }
        else if (info.file.status === "removed") {
            message.success("".concat(info.file.name, " \u79FB\u9664\u6210\u529F"));
        }
        setFileList(info === null || info === void 0 ? void 0 : info.fileList);
    };
    useEffect(function () {
        if (fileList.length === 0) {
            sendData && sendData(fileList);
        }
        else {
            fileList.forEach(function (item, index) {
                var _a;
                // if(item?.response){
                //   const {fileName,done,fileId,url} = item?.response?.results || {}
                //   fileList[index] = {
                //     name:fileName,
                //     status:done,
                //     uid:fileId,
                //     url:url,
                //   }
                // }
                if (item.url) {
                    var _b = item || {}, fileName = _b.fileName, done = _b.done, fileId = _b.fileId, url = _b.url;
                    fileList[index] = {
                        name: fileName,
                        status: done,
                        uid: fileId,
                        url: url,
                    };
                }
                else if (item === null || item === void 0 ? void 0 : item.response) {
                    var _c = ((_a = item === null || item === void 0 ? void 0 : item.response) === null || _a === void 0 ? void 0 : _a.results) || {}, fileName = _c.fileName, done = _c.done, fileId = _c.fileId, url = _c.url;
                    fileList[index] = {
                        name: fileName,
                        status: done,
                        uid: fileId,
                        url: url,
                    };
                }
            });
            sendData && sendData(fileList);
        }
    }, [fileList]);
    useEffect(function () {
        setFileList(props.fileList);
    }, [props.fileList]);
    return (React.createElement("div", { className: "upload-file" },
        React.createElement(Upload, { name: "file", action: action || "/apiInterface/interface/hydra-opinion-platform/api/v1/common/hydraFile/uploadFile", headers: {
                "platform-token": sessionStorage.getItem("platform-token") || "",
            }, accept: ".pdf", onChange: onChange, beforeUpload: beforeUpload, maxCount: maxCount, fileList: fileList, data: data, disabled: disabled }, fileList.length < maxCount && (React.createElement(Button, { icon: React.createElement(UploadOutlined, null) }, "\u4E0A\u4F20\u6587\u4EF6")))));
}
