import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ajaxOpenAi } from "../../../../utils/axios";
import { DATA_MANAGER } from "../../../../api/api";
var columns = [
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
    },
    {
        title: "数据类型",
        dataIndex: "dataType",
        key: "dataType",
        ellipsis: true,
    },
    {
        title: "数据长度",
        dataIndex: "dataLength",
        key: "dataLength",
        ellipsis: true,
    },
];
export default function DataType(props) {
    var id = props.id;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var getData = function () {
        if (!id)
            return;
        var params = {
            id: id,
        };
        ajaxOpenAi("get", DATA_MANAGER.getDataItemList, params).then(function (res) {
            if (res.returnCode) {
                setData((res === null || res === void 0 ? void 0 : res.results) || []);
            }
            else {
                setData([]);
            }
        });
    };
    useEffect(function () {
        getData();
    }, [id]);
    return (React.createElement("div", { className: "" },
        React.createElement(Table, { columns: columns, dataSource: data, pagination: false, rowKey: "reportId" })));
}
