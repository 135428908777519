import React from 'react';
import { UserOutlined,BarChartOutlined } from '@ant-design/icons';

const adminMenus = [
  {
    key: 'system',
    icon: React.createElement(UserOutlined),
    label: '用户体系',
    children: [
      {
        key: 'userManager',
        label: `用户管理`,

        children:[
          {key:'add',label:'新增',isShowInMenu:false,children:[]},
          {key:'edit',label:'编辑',isShowInMenu:false,children:[]},
          {key:'check',label:'查看',isShowInMenu:false,children:[]},
        ]
      },
      {
        key: 'roleManager',
        label: `角色管理`,
        children:[]
      },
      {
        key: 'menuManager',
        label: `菜单管理`,
        children:[]
      },
      {
        key: 'messageList',
        label: `短信列表`,
        children:[]
      }
    ]
  },
  {
    key: 'dataManage',
    icon: React.createElement(BarChartOutlined),
    label: '数据管理',
    children: [
      {
        key: 'reportManager',
        label: `报告管理`,

        children:[
        ]
      },
      {
        key:'siteManager',
        label:`站点管理`,
        children:[]
      },
      {
        key: 'dataProductSub',
        label: `数据产品订阅`,
        children:[
          {key:'add',label:'新增',isShowInMenu:false,children:[]},
          {key:'edit',label:'编辑',isShowInMenu:false,children:[]},
          {key:'check',label:'查看',isShowInMenu:false,children:[]},
        ]
      },
    ]
  },

]

const configMenu = [
  // {
  //   key: 'datav',
  //   icon: React.createElement(UserOutlined),
  //   label: '驾驶舱配置',
  //   children: [
  //     {
  //       key: 'leaderConfig',
  //       label: `领导人配置`,
  //       children:[]
  //     },
  //     {
  //       key: 'primeIndustry',
  //       label: `主导产业配置`,
  //       children:[
  //         {key:'add',label:'新增',isShowInMenu:false},
  //         {key:'edit',label:'编辑',isShowInMenu:false},
  //         {key:'check',label:'查看',isShowInMenu:false},
  //       ]
  //     },
  //     {
  //       key: 'areaMange',
  //       label: `地域管理`,
  //       children:[
  //         {key:'add',label:'新增',isShowInMenu:false},
  //         {key:'config',label:'配置',isShowInMenu:false},
  //         {key:'edit',label:'编辑',isShowInMenu:false},
  //         {key:'freeLogin',label:'免密登录',isShowInMenu:false},
  //       ]
  //     },
  //     {
  //       key: 'first',
  //       label: `首页`,
  //       children:[
  //         {key:'introConfig',label:'简介配置',isShowInMenu:false},
  //         {key:'industryDataConfig',label:'产业数据配置',isShowInMenu:false},
  //       ]
  //     },
  //     {
  //       key: 'smartReport',
  //       label: `智能报告`,
  //       children:[
  //         {key:'add',label:'新增',isShowInMenu:false},
  //         {key:'edit',label:'编辑',isShowInMenu:false},
  //       ]
  //     },
  //   ]
  // },  
]

export { adminMenus,configMenu }