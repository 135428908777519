import React from 'react';
import { Route, Routes } from "react-router-dom"

import Login from '../pages/login/Login'
import MenuManager from '../pages/system/menuManager'
import RoleManager from '../pages/system/roleManager'
import UserManager from '../pages/system/useManager'
import AddUser from '../pages/system/useManager/AddUser'
import MessageList from '../pages/system/messageList'
import ReportManager from '../pages/dataManage/reportManager';
import LeaderConfig from '../pages/datav/leaderConfig';
import PrimeIndustry from '../pages/datav/primeIndustry';
import PrimeIndustryAddOrEditPage from '../pages/datav/primeIndustry/AddOrEditPage';
// import SiteManager from '../pages/siteManager';
import SiteManager from '../pages/dataManage/siteManager';
import DataProductSub from '../pages/dataManage/dataProductSub';
import AddDataProductSub from '../pages/dataManage/dataProductSub/components/AddDataProductSub';
import AreaMange from '../pages/datav/areaMange';
import AreaMangeAdd from '../pages/datav/areaMange/AddOrEditPage';
import AreaMangeConfig from '../pages/datav/areaMange/AreaMangeConfig';
import AreaMangeFreeLogin from '../pages/datav/areaMange/AreaMangeFreeLogin';
import First from '../pages/datav/first';
import IntroConfig from '../pages/datav/first/IntroConfig';
import IndustryDataConfig from '../pages/datav/first/IndustryDataConfig';
import SmartReport from '../pages/datav/smartReport';
import SmartReportAdd from '../pages/datav/smartReport/AddOrEditPage';

export class AppRouter extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <Routes>
        {/* <Route path='/system/userManager' element={<UserManager />} />
        <Route path='/system/userManager/add' element={<AddUser />} />
        <Route path='/system/userManager/edit/:id' element={<AddUser />} />
        <Route path='/system/userManager/check/:id' element={<AddUser />} />
        <Route path='/system/menuManager' element={<MenuManager />} />
        <Route path='/system/roleManager' element={<RoleManager />} />
        <Route path='/system/messageList' element={<MessageList />} />
        <Route path='/dataManage/reportManager' element={<ReportManager/>}></Route>
        <Route path='/datav/leaderConfig' element={<LeaderConfig/>}></Route>
        <Route path='/datav/primeIndustry' element={<PrimeIndustry/>}></Route>
        <Route path='/datav/primeIndustry/add' element={<PrimeIndustryAddOrEditPage/>}></Route>
        <Route path='/datav/primeIndustry/edit/:id' element={<PrimeIndustryAddOrEditPage/>}></Route>
        <Route path='/datav/areaMange' element={<AreaMange/>}></Route>
        <Route path='/datav/areaMange/add' element={<AreaMangeAdd/>}></Route>
        <Route path='/datav/areaMange/edit/:id' element={<AreaMangeAdd/>}></Route>
        <Route path='/datav/areaMange/config/:id' element={<AreaMangeConfig/>}></Route>
        <Route path='/datav/areaMange/freeLogin/:id' element={<AreaMangeFreeLogin/>}></Route>
        <Route path='/datav/first' element={<First/>}></Route>
        <Route path='/datav/first/introConfig/:id' element={<IntroConfig/>}></Route>
        <Route path='/datav/first/industryDataConfig/:id' element={<IndustryDataConfig/>}></Route>
        <Route path='/datav/smartReport' element={<SmartReport/>}></Route>
        <Route path='/datav/smartReport/add' element={<SmartReportAdd/>}></Route>
        <Route path='/datav/smartReport/edit/:id' element={<SmartReportAdd/>}></Route>
        <Route path='/dataManage/siteManager' element={<SiteManager/>}></Route>
        <Route path='/dataManage/dataProductSub' element={<DataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/add' element={<AddDataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/edit/:id' element={<AddDataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/check/:id' element={<AddDataProductSub/>}></Route> */}
        <Route path='/system/userManager' element={<UserManager />} />
        <Route path='/system/userManager/add' element={<AddUser />} />
        <Route path='/system/userManager/edit/:id' element={<AddUser />} />
        <Route path='/system/userManager/check/:id' element={<AddUser />} />
        <Route path='/system/menuManager' element={<MenuManager />} />
        <Route path='/system/roleManager' element={<RoleManager />} />
        <Route path='/system/messageList' element={<MessageList />} />
        <Route path='/dataManage/reportManager' element={<ReportManager/>}></Route>
        <Route path='/dataManage/siteManager' element={<SiteManager/>}></Route>
        <Route path='/dataManage/dataProductSub' element={<DataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/add' element={<AddDataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/edit/:id' element={<AddDataProductSub/>}></Route>
        <Route path='/dataManage/dataProductSub/check/:id' element={<AddDataProductSub/>}></Route>
        <Route path='/:path/leaderConfig' element={<LeaderConfig/>}></Route>
        <Route path='/:path/primeIndustry' element={<PrimeIndustry/>}></Route>
        <Route path='/:path/primeIndustry/add' element={<PrimeIndustryAddOrEditPage/>}></Route>
        <Route path='/:path/primeIndustry/edit/:id' element={<PrimeIndustryAddOrEditPage/>}></Route>
        <Route path='/:path/areaMange' element={<AreaMange/>}></Route>
        <Route path='/:path/areaMange/add' element={<AreaMangeAdd/>}></Route>
        <Route path='/:path/areaMange/edit/:id' element={<AreaMangeAdd/>}></Route>
        <Route path='/:path/areaMange/config/:id' element={<AreaMangeConfig/>}></Route>
        <Route path='/:path/areaMange/freeLogin/:id' element={<AreaMangeFreeLogin/>}></Route>
        <Route path='/:path/first' element={<First/>}></Route>
        <Route path='/:path/first/introConfig/:id' element={<IntroConfig/>}></Route>
        <Route path='/:path/first/industryDataConfig/:id' element={<IndustryDataConfig/>}></Route>
        <Route path='/:path/smartReport' element={<SmartReport/>}></Route>
        <Route path='/:path/smartReport/add' element={<SmartReportAdd/>}></Route>
        <Route path='/:path/smartReport/edit/:id' element={<SmartReportAdd/>}></Route>
      </Routes>
  }
}
export class LoginRouter extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <Routes>
        <Route path='/'  element={<Login />} />
        <Route path='/login' element={<Login />} />
      </Routes>
  }
}