var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Input, Checkbox, Radio, } from "antd";
import { MinusCircleOutlined, } from '@ant-design/icons';
import { inject, observer } from "mobx-react";
import './index.scss';
var DragDrop = inject('staticStore')(observer(function (props) {
    var _a = props.grid, grid = _a === void 0 ? 8 : _a, _b = props.dataList, dataList = _b === void 0 ? [] : _b, _c = props.listStyles, listStyles = _c === void 0 ? {} : _c, _d = props.itemStyles, itemStyles = _d === void 0 ? {} : _d, onDragEnd = props.onDragEnd, setMenuList = props.setMenuList, staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, inputWidth = staticStore.inputWidth;
    var _e = useState([]), items = _e[0], setItems = _e[1];
    useEffect(function () {
        console.log('dataList', dataList);
        if (dataList && dataList.length > 0) {
            setItems(dataList);
        }
        else {
            setItems([]);
        }
    }, [dataList]);
    var getListStyle = function (isDraggingOver) { return (__assign({ background: isDraggingOver ? "lightblue" : "lightgrey", padding: 8 }, listStyles)); };
    var getItemStyle = function (isDragging, draggableStyle) { return (__assign(__assign({ 
        // some basic styles to make the items look a bit nicer
        userSelect: "none", padding: grid * 2, margin: "0 0 ".concat(grid, "px 0"), 
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey" }, draggableStyle), itemStyles)); };
    var reorder = function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    };
    var onDragEndFunc = function (result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        var itemsCopy = reorder(items, result.source.index, result.destination.index);
        setMenuList(itemsCopy);
    };
    var onCheckChange = function (e, index) {
        var menuListCopy = items.slice();
        menuListCopy[index].selected = e.target.checked;
        setMenuList(menuListCopy);
    };
    var onInputChange = function (e, index) {
        var menuListCopy = items.slice();
        menuListCopy[index].menuName = e.target.value;
        setMenuList(menuListCopy);
    };
    var onDelete = function (index) {
        var menuListCopy = items.slice();
        menuListCopy.splice(index, 1);
        setMenuList(menuListCopy);
    };
    var onRadioChange = function (e, index) {
        var menuListCopy = items.slice();
        menuListCopy[index].menuType = e.target.value;
        setMenuList(menuListCopy);
    };
    var onLinkChange = function (e, index) {
        var menuListCopy = items.slice();
        menuListCopy[index].funUri = e.target.value;
        setMenuList(menuListCopy);
    };
    var onChildCheckChange = function (e, index, index2) {
        console.log('e.target.checked', e.target.checked);
        var menuListCopy = items.slice();
        console.log('menuListCopy', menuListCopy);
        menuListCopy[index].list[index2].selected = e.target.checked;
        setMenuList(menuListCopy);
    };
    var onChildInputChange = function (e, index, index2) {
        var menuListCopy = items.slice();
        menuListCopy[index].list[index2].menuName = e.target.value;
        setMenuList(menuListCopy);
    };
    return (React.createElement(DragDropContext, { onDragEnd: onDragEndFunc },
        React.createElement(Droppable, { droppableId: "droppable" }, function (provided, snapshot) { return (React.createElement("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, style: getListStyle(snapshot.isDraggingOver) }),
            items.map(function (item, index) { return (React.createElement(Draggable, { key: item.id || index, draggableId: String(item.id || index), index: index }, function (provided, snapshot) { return (React.createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }),
                React.createElement("div", { className: "config_menu" },
                    React.createElement(Checkbox, { key: item.menuId, checked: item.selected, onChange: function (e) { onCheckChange(e, index); }, className: "config_menu_checkbox" }),
                    (!item.deleted) ? React.createElement("div", null,
                        React.createElement(Input, { value: item.menuName, onChange: function (e) { onInputChange(e, index); }, width: 180, maxLength: 5 }),
                        (item.list || []).map(function (item2, index2) { return React.createElement("div", { className: "config_menu_child" },
                            React.createElement(Checkbox, { key: item2.menuId, checked: item2.selected, onChange: function (e) { onChildCheckChange(e, index, index2); }, className: "config_menu_checkbox" }),
                            React.createElement(Input, { value: item2.menuName, onChange: function (e) { onChildInputChange(e, index, index2); }, maxLength: 5 })); })) :
                        React.createElement("div", { className: "config_menu_new" },
                            React.createElement("div", { className: "config_menu_new_input" },
                                React.createElement(Input, { value: item.menuName, style: { width: '183px', marginRight: '20px' }, maxLength: 5, onChange: function (e) { onInputChange(e, index); } }),
                                React.createElement(Input, { value: item.funUri, style: { width: inputWidth, marginRight: '20px' }, maxLength: 500, onChange: function (e) { onLinkChange(e, index); } }),
                                React.createElement(MinusCircleOutlined, { onClick: function () { return onDelete(index); } })),
                            React.createElement("div", { className: "config_menu_new_show" },
                                "\u5C55\u793A\u65B9\u5F0F\uFF1A",
                                React.createElement(Radio.Group, { onChange: function (e) { return onRadioChange(e, index); }, value: item.menuType },
                                    React.createElement(Radio, { value: 'EMBEDDISPLAY' }, "\u5D4C\u5165\u5C55\u793A"),
                                    React.createElement(Radio, { value: 'JUMP' }, "\u70B9\u51FB\u8DF3\u8F6C"))),
                            (item.list || []).map(function (item2, index2) { return React.createElement("div", { className: "config_menu_child" },
                                React.createElement(Checkbox, { key: item2.menuId, checked: item2.selected, onChange: function (e) { onChildCheckChange(e, index, index2); }, className: "config_menu_checkbox" }),
                                React.createElement(Input, { value: item2.menuName, onChange: function (e) { onChildInputChange(e, index, index2); }, maxLength: 5 })); }))))); })); }),
            provided.placeholder)); })));
}));
export default DragDrop;
