var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Pagination, Popconfirm, Select, Table, message } from "antd";
// import AddEditModal from "./components/AddEditModal";
import { get, postForm } from "../../../utils/axios";
import { AREA_MANGE } from "../../../api/api";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getOperatorName } from '../../../utils/lib';
var LeaderConfig = inject("staticStore", "appStore")(observer(function (props) {
    var staticStore = props.staticStore, appStore = props.appStore;
    var tableSearchWidth = staticStore.tableSearchWidth, defaultPagination = staticStore.defaultPagination, inputMaxLength = staticStore.inputMaxLength;
    var currentPath = appStore.currentPath;
    var _a = useState([]), areaList = _a[0], setAreaList = _a[1];
    var _b = useState([]), leaderList = _b[0], setLeaderList = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var _e = useState(false), isShowModal = _e[0], setIsShowModal = _e[1];
    var _f = useState(""), modalType = _f[0], setModalType = _f[1];
    var _g = useState(""), id = _g[0], setId = _g[1];
    var navigate = useNavigate();
    var _h = useState({
        orgId: null,
    }), search = _h[0], setSearch = _h[1];
    var columns = [
        {
            title: "地域",
            dataIndex: "regionName",
            key: "regionName",
            ellipsis: true,
        },
        {
            title: "行政编码",
            dataIndex: "regionCode",
            key: "regionCode",
            ellipsis: true,
        },
        {
            title: "驾驶舱名称",
            dataIndex: "orgName",
            key: "orgName",
            ellipsis: true,
        },
        {
            title: "菜单数量",
            dataIndex: "menuNumber",
            key: "menuNumber",
            ellipsis: true,
        },
        {
            title: "域名",
            dataIndex: "url",
            key: "url",
            ellipsis: true,
        },
        {
            title: "操作",
            dataIndex: "orgId",
            key: "orgId",
            fixed: "right",
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                getOperatorName('config') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoConfig(v, r); } }, getOperatorName('config', 'name')),
                getOperatorName('edit') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoEdit(v, r); } }, getOperatorName('edit', 'name')),
                getOperatorName('freeLogin') && React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoFreeLogin(v, r); } }, getOperatorName('freeLogin', 'name')),
                React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u6570\u636E\u4E0D\u53EF\u6062\u590D\uFF0C\u786E\u8BA4\u5220\u9664\uFF1F", onConfirm: function () { return onDelete(v, r); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" }, getOperatorName('delete') && React.createElement("a", { href: "javascript:;", className: "mr14" }, getOperatorName('delete', 'name'))))); },
        },
    ];
    var gotoConfig = function (v, r) {
        navigate("".concat(currentPath, "/config/").concat(v));
    };
    var onDelete = function (v, r) {
        var params = {
            orgId: r.orgId
        };
        console.log("params", params);
        postForm(AREA_MANGE.deleted, params).then(function (res) {
            if (res) {
                message.success('删除成功');
                getData(1, pagination.pageSize);
            }
        });
    };
    var gotoEdit = function (v, r) {
        navigate("".concat(currentPath, "/edit/").concat(v));
    };
    var gotoFreeLogin = function (v, r) {
        navigate("".concat(currentPath, "/freeLogin/").concat(v));
    };
    var getData = function (cur, size) {
        var params = __assign({ current: cur, pageSize: size }, search);
        console.log("params", params);
        get(AREA_MANGE.pageSearch, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var onAddOrEdit = function (type, id) {
        // setIsShowModal(true);
        // setModalType(type);
        // setId(id)
        navigate("".concat(currentPath, "/add"));
    };
    var getAreaList = function () {
        var params = {
            moduleEnum: 'SCREEN'
        };
        get(AREA_MANGE.getOrgList, params).then(function (res) {
            if (res) {
                setAreaList((res === null || res === void 0 ? void 0 : res.results) || []);
            }
        });
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.orgId]);
    useEffect(function () {
        getAreaList();
    }, []);
    return (React.createElement("div", { className: "leader_config" },
        React.createElement("div", { className: "leader_config_header" },
            getOperatorName('add') && React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return onAddOrEdit("add"); } }, getOperatorName('add', 'name')),
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u5730\u57DF\uFF1A"),
                React.createElement(Select, { value: search.orgId, placeholder: "\u8BF7\u9009\u62E9", style: { width: tableSearchWidth }, allowClear: true, showSearch: true, options: areaList, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { orgId: v })); });
                    }, fieldNames: {
                        label: 'regionName',
                        value: 'orgId',
                    }, 
                    // onSearch={onSearch}
                    filterOption: function (input, option) {
                        var _a;
                        return ((_a = option === null || option === void 0 ? void 0 : option.regionName) !== null && _a !== void 0 ? _a : "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    } }))),
        React.createElement("div", { className: "cont-table mt20" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content", }, pagination: false, rowKey: "reportId", rowClassName: function (record, index) { return index % 2 ? 'old_line' : 'even_line'; } }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, current: pagination.current, total: pagination.total, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } })))));
}));
export default LeaderConfig;
