import { observable, action } from 'mobx';

class AppStore{
  /* 一些观察的状态 */
  @observable isLogin = false
  @observable loginObj = {}
  @observable menus =[]
  @observable isAdminStore = false
  @observable currentPath = ''

   /* 推导值 */
  @action setLoginObj = (item)=>{
    this.loginObj = item
  }

  @action setMenus = (arr)=>{
    this.menus = arr
  }
  @action setIsAdminStore = (isAdmin)=>{
    this.isAdminStore = isAdmin
  }
  @action setCurrentPath = (path)=>{
    this.currentPath = path
  }
}

export default AppStore