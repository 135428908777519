var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { get, postJson } from "../../../utils/axios";
import { DATA_MANAGER, DATA_MENU_MANAGER } from "../../../api/api";
import { Button, Input, Pagination, Popconfirm, Table, TreeSelect, message, } from "antd";
var DataManager = inject("staticStore")(observer(function (props) {
    var staticStore = props.staticStore;
    var inputMaxLength = staticStore.inputMaxLength, defaultPagination = staticStore.defaultPagination, tableSearchWidth = staticStore.tableSearchWidth;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({
        catalogueId: 0,
        dataName: "",
    }), search = _b[0], setSearch = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useState(defaultPagination), pagination = _d[0], setPagination = _d[1];
    var navigate = useNavigate();
    var deleteData = function (id) {
        var params = {
            id: id,
        };
        get("".concat(DATA_MANAGER.delete, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("删除成功");
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var OnOrOff = function (type, id) {
        var params = {
            id: id,
        };
        var url = type === "on" ? DATA_MANAGER.upShelves : DATA_MANAGER.downShelves;
        get("".concat(url, "/").concat(id), params).then(function (res) {
            if (res) {
                message.success("".concat(type === "on" ? "上架" : "下架", "\u6210\u529F"));
                var current = pagination.current, pageSize = pagination.pageSize;
                getData(current, pageSize);
            }
        });
    };
    var gotoAddPage = function (type, id) {
        switch (type) {
            case "add":
                navigate("/dataManage/dataProductSub/add");
                break;
            case "edit":
                navigate("/dataManage/dataProductSub/edit/".concat(id));
                break;
            case "check":
                navigate("/dataManage/dataProductSub/check/".concat(id));
                break;
        }
    };
    var getData = function (cur, size) {
        var catalogueId = search.catalogueId, dataName = search.dataName;
        var params = {
            current: cur,
            pageSize: size,
            dataName: dataName,
            catalogueId: catalogueId === 0 ? "" : catalogueId,
        };
        postJson(DATA_MANAGER.pageSearch, params).then(function (res) {
            if (res) {
                var _a = (res === null || res === void 0 ? void 0 : res.results) || {}, list = _a.list, pagination_1 = _a.pagination;
                setData(list);
                setPagination(pagination_1);
            }
            else {
                setData([]);
                setPagination(defaultPagination);
            }
        });
    };
    var onChange = function (pageNumber) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: pageNumber })); });
        getData(pageNumber, pagination.pageSize);
    };
    var onShowSizeChange = function (current, size) {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: current, pageSize: size })); });
        getData(current, size);
    };
    var getOptions = function () {
        var params = {
            status: "IN_USE",
        };
        get(DATA_MENU_MANAGER.getEnableTreeNode, params).then(function (res) {
            if (res) {
                var results = res === null || res === void 0 ? void 0 : res.results;
                var opt = [
                    {
                        catalogueName: "全部",
                        childNodeList: results,
                        catalogueId: 0,
                    },
                ];
                setOptions(opt);
            }
        });
    };
    useEffect(function () {
        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
        getData(1, 10);
    }, [search.catalogueId]);
    useEffect(function () {
        getOptions();
    }, []);
    var columns = [
        {
            title: "数据名称",
            dataIndex: "dataName",
            key: "dataName",
            ellipsis: true,
        },
        {
            title: "目录",
            dataIndex: "catalogueName",
            key: "catalogueName",
            ellipsis: true,
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            render: function (v) {
                return v === "IN_USE" ? (React.createElement("span", { className: "light_status" }, "\u4F7F\u7528\u4E2D")) : (React.createElement("span", { className: "dark_status" }, "\u5DF2\u505C\u7528"));
            },
        },
        {
            title: "编辑时间",
            dataIndex: "updateTime",
            key: "updateTime",
            ellipsis: true,
            sorter: function (v, r) { return new Date(v).getTime() - new Date(v).getTime(); },
        },
        {
            title: "操作",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            width: 200,
            render: function (v, r) { return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return gotoAddPage("check", r.id); } }, "\u67E5\u770B"),
                r.status === "IN_USE" ? (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("off", r.id); } }, "\u4E0B\u67B6")) : (React.createElement("a", { href: "javascript:;", className: "mr14", onClick: function () { return OnOrOff("on", r.id); } }, "\u4E0A\u67B6")),
                r.status === "IN_USE" ? (React.createElement("span", { className: "mr14 table_dark_option" }, "\u7F16\u8F91")) : (React.createElement("a", { onClick: function () { return gotoAddPage("edit", r.id); }, href: "javascript:;", className: "mr14" }, "\u7F16\u8F91")),
                r.status === "IN_USE" ? (React.createElement("span", { className: "mr14 table_dark_option" }, "\u5220\u9664")) : (React.createElement(Popconfirm, { title: "\u786E\u8BA4\u8981\u6267\u884C\u5220\u9664\u64CD\u4F5C\u5417\uFF1F", onConfirm: function () { return deleteData(r.id); }, okText: "\u662F", cancelText: "\u5426", placement: "topRight" },
                    React.createElement("a", { href: "javascript:;", className: "mr14 table_delete" }, "\u5220\u9664"))))); },
        },
    ];
    return (React.createElement("div", { className: "data_manager" },
        React.createElement("div", { className: "data_manager_header mb20" },
            React.createElement(Button, { className: "new_btn_simple mr24", type: "primary", onClick: function () { return gotoAddPage("add"); } }, "+\u65B0\u5EFA"),
            React.createElement("div", { className: "mr24" },
                React.createElement("span", null, "\u76EE\u5F55\uFF1A"),
                options.length > 0 && React.createElement(TreeSelect, { treeLine: true, style: { width: tableSearchWidth }, treeData: options, placeholder: "\u8BF7\u9009\u62E9", treeDefaultExpandedKeys: [0], showSearch: true, fieldNames: {
                        label: "catalogueName",
                        value: "catalogueId",
                        children: "childNodeList",
                    }, value: search.catalogueId, onChange: function (v) {
                        setSearch(function (pre) { return (__assign(__assign({}, pre), { catalogueId: v })); });
                    }, allowClear: true })),
            React.createElement("div", null,
                React.createElement("span", null, "\u6570\u636E\u540D\u79F0\uFF1A"),
                React.createElement(Input, { value: search.dataName, placeholder: "\u8BF7\u8F93\u5165", style: { width: tableSearchWidth }, onChange: function (e) {
                        setSearch(function (pre) {
                            var _a, _b;
                            return (__assign(__assign({}, pre), { dataName: (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.trim() }));
                        });
                    }, allowClear: true, maxLength: inputMaxLength, onPressEnter: function () {
                        setPagination(function (pre) { return (__assign(__assign({}, pre), { current: 1, pageSize: 10 })); });
                        getData(1, 10);
                    } }))),
        React.createElement("div", { className: "table" },
            React.createElement(Table, { columns: columns, dataSource: data, scroll: { x: "max-content" }, pagination: false, rowKey: "reportId" }),
            React.createElement("div", { className: "cont-pagination mt20" },
                React.createElement(Pagination, { showQuickJumper: true, pageSize: pagination.pageSize, total: pagination.total, current: pagination.current, onChange: onChange, showSizeChanger: true, onShowSizeChange: onShowSizeChange, showTotal: function (total) { return "\u603B\u5171 ".concat(total, " \u6761\u6570\u636E"); } })))));
}));
export default DataManager;
